import {i18n} from "@/main";

export const Utils = {

    localizeText(textKey) {
        return i18n.t(textKey)
    },

    convertDate(inputDate) {
        return inputDate.substring(8, 10) + "." + inputDate.substring(5, 7) + "." + inputDate.substring(0, 4)
    },

    getFullName(inputUser) {
        let fullName = inputUser.name + " " + inputUser.surname
        if (inputUser.companyName !== null && inputUser.companyName !== '') {
            fullName += " - " + inputUser.companyName
        }
        return fullName
    },

    getFullNameWithPosition(inputUser) {
        return Utils.getFullName(inputUser) + " (" + inputUser.position + ")"
    },

    getFilterUrl(filter_data) {
        let filter = "?page=" + encodeURIComponent(filter_data.page)
        filter += "&limit=" + encodeURIComponent(filter_data.limit)
        for (const filter_item in filter_data.toolbar_filter) {
            filter += "&" + filter_item + "=" + encodeURIComponent(filter_data.toolbar_filter[filter_item])
        }

        return filter
    },

    getWeekForDate(currentDate) {
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        return Math.ceil(days / 7);
    },

    parsePrice(price) {
        let currency = "€";
        /*let sum = price.replace(/[.]/g, function(rx) {
            rx = ',';
            return rx;
        })*/
        let sum = parseFloat(price).toFixed(2);
        sum = sum.replace(/[.]/g, ',');

        return sum + " " + currency;
    },

    parseValue(price) {
        return parseFloat(price).toFixed(2)
    },

    parseFloatFromValue(price) {
        return parseFloat(price.toString().replace(',', '.')).toFixed(2)
    },

    parsePriceValue(price) {
        return parseFloat(price).toFixed(2).replace(/[.]/g, ',')
    },

    getCurrentMonthRange() {
        let date = new Date();
        let month = date.getMonth() + 1;
        let date_format = date.getFullYear() + '-' + month.toString().padStart(2, "0");
        let today = date_format + '-' + date.getDate().toString().padStart(2, "0");

        return {
            firstDay: date_format + '-01',
            today: today
        };
    },

    is18YearsOld(value) {
        let currentDate = new Date()
        let inputDate = new Date(value)
        if (currentDate.getFullYear() - inputDate.getFullYear() < 18) {
            return false
        } else if (currentDate.getFullYear() - inputDate.getFullYear() > 18) {
            return true
        } else {
            if (inputDate.getMonth() < currentDate.getMonth()) {
                return true
            } else if (inputDate.getMonth() > currentDate.getMonth()) {
                return false
            } else {
                return inputDate.getDate() <= currentDate.getDate()
            }
        }
    }
}