<template>
    <div>
        <Navigation :links="getNavigation"/>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import views from "@/views/views";

export default {
    name: "Nav",
    components: {
        Navigation
    },
    data: () => ({}),
    computed: {
        getNavigation() {
            return views.getDataView(this.$route.path.split("/")[1]).nav;
        }
    }
}
</script>