import {login, refreshToken} from "@/api/auth";
import ApiService from "@/services/api_service";
import {Base64} from 'js-base64';

const state = {
    jwt: ''
};

const actions = {

    login(context, userData) {

        return login(userData)
            .then(response => {
                context.commit('setJwtToken', response.data);
                context.dispatch('setUser', context.getters.getUserData);
            })
            .catch(() => {
                context.commit('clearJwtToken')
            })
    },

    refresh(context) {

        return refreshToken()
            .then(response => {
                context.commit('setJwtToken', response.data);
                context.dispatch('setUser', context.getters.getUserData)
            })
            .catch(() => {
                context.commit('clearJwtToken')
            })
    },

    setToken(context, access_token) {

        context.commit('setJwtToken', access_token);
        context.dispatch('setUser', context.getters.getUserData);
    }
};

const mutations = {

    setJwtToken(state, access_token) {
        localStorage.ACCESS_TOKEN = access_token;
        ApiService.setHeader();
        state.jwt = access_token;
    },
    clearJwtToken(state) {
        localStorage.ACCESS_TOKEN = '';
        state.jwt = ''
    }
};

const getters = {

    getUserData() {
        let user_data = JSON.parse(Base64.decode(state.jwt.split('.')[1]));
        user_data.permissions = []
        user_data.profileImage = null
        user_data.bossProfileImage = null
        let acls_split = user_data.acls.split(",")
        for (let i = 0; i < acls_split.length; i++) {
            if (acls_split[i].length > 0) {
                user_data.permissions.push(acls_split[i])
            }
        }

        return user_data
    },

    getJWT() {
        return state.jwt
    }
};

export const internal_authenticator = {
    state,
    actions,
    mutations,
    getters
};
