import Permissions from "@/services/auth/permissions";
import {
    exportAgreements,
    exportCustomers,
    exportGoldDeposit,
    exportPackages,
    exportPersonalWebs,
    exportSellers
} from "@/api/export";
import {allocateCoins} from "@/api/gold_deposit";
import {Utils} from "@/services/utils";


const data = {
    users: {
        card: "UserCard",
        card_target: {
            Customer: 'UserDialog',
            Seller: 'SellerDialog',
            Supporter: 'UserDialog',
            Administrator: 'UserDialog'
        },
        dialog: "UserDialog",
        card_headers: [
            {value: 'registration'},
            {value: 'id'},
            {value: 'fullname'},
            {value: 'email'},
            {value: 'phone'},
            {value: 'role'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Customer'}, {id: 'Seller'}, {id: 'Supporter'}, {id: 'Administrator'}]
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Deactivated'}]
            }
        ],
        action_buttons: [
            {
                label: 'export_sellers',
                icon: 'mdi-file-export',
                method: "exportSellers",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            },
            {
                label: 'export_customers',
                icon: 'mdi-file-export',
                method: "exportCustomers",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getUsers', clear: 'clearUsers'},
        localize: 'users',
        preselected: [],
        permissions: []
    },
    users_list_select: {
        card: "UserCard",
        card_target: {
            Customer: 'UserDialog',
            Seller: 'SellerDialog',
            Supporter: 'UserDialog',
            Administrator: 'UserDialog'
        },
        dialog: "UserDialog",
        card_headers: [
            {value: 'id'},
            {value: 'name'},
            {value: 'role'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            }
        ],
        action_buttons: [],
        store: {get: 'getUsers', clear: 'clearUsers'},
        localize: 'users',
        preselected: [],
        selector_emiter: 'item-user',
        permissions: []
    },
    users_email_list_select: {
        card: "SelectorEmailCard",
        card_target: {
            Customer: 'UserDialog',
            Seller: 'SellerDialog',
            Supporter: 'UserDialog',
            Administrator: 'UserDialog'
        },
        dialog: "UserDialog",
        card_headers: [
            {value: 'fullname'},
            {value: 'email'},
            {value: 'role'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Customer'}, {id: 'Seller'}, {id: 'Supporter'}, {id: 'Administrator'}]
            }
        ],
        action_buttons: [],
        store: {get: 'getUsers', clear: 'clearUsers'},
        localize: 'users',
        item_per_page: 10,
        preselected: [],
        selector_emiter: 'item-email',
        permissions: []
    },

    customers: {
        card: "UserCard",
        dialog: "UserDialog",
        card_headers: [
            {value: 'id'},
            {value: 'fullname'},
            {value: 'company'},
            {value: 'registration'}
        ],
        filter_groups: [],
        action_buttons: [],
        store: 'getUsers',
        localize: "users",
        permissions: []
    },
    customers_list_select: {
        card: "UserCard",
        dialog: "CustomerDialog",
        card_headers: [
            {value: 'id'},
            {value: 'name'},
            {value: 'role'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            }
        ],
        action_buttons: [],
        store: {get: 'getCustomers', clear: 'clearUsers'},
        localize: 'customers',
        preselected: [],
        selector_emiter: 'item-user',
        permissions: []
    },

    sellers_list: {
        card: "SellerCard",
        dialog: "SellerDialog",
        card_headers: [
            {value: 'active_days'},
            {value: 'certificates'},
            {value: 'quick_start'},
            {value: 'phone'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Active'}, {id: 'Inactive'}]
            },
            {
                id: 'position',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'P1'}, {id: 'P2'}, {id: 'P3'}, {id: 'P4'}, {id: 'P5'}, {id: 'P6'}, {id: 'P7'}]
            }
        ],
        action_buttons: [{
            label: 'add_seller',
            icon: 'mdi-plus-circle',
            dialog: 'SellerDialog',
            permissions: [],
            conditions: []
        }],
        store: {get: 'getSellers', clear: 'clearSellers'},
        localize: "sellers_list",
        preselected: [],
        permissions: []
    },
    sellers_list_select: {
        card: "SelectorCard",
        //dialog: "SellerDialog",
        card_headers: [
            {value: 'registration'},
            {value: 'id'},
            {value: 'name'},
            {value: 'active'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            }
        ],
        action_buttons: [],
        store: {get: 'getSellers', clear: 'clearSellers'},
        localize: "sellers_list",
        preselected: [],
        selector_emiter: 'item-seller',
        permissions: []
    },

    provisions: {
        card: "ProvisionCard",
        dialog: "SalaryDialog",
        card_headers: [
            {value: 'id'},
            {value: 'name'},
            {value: 'active'},
            {value: 'current_salary'},
            {value: 'total_salaries'},
            {value: 'total_rent'},
            {value: 'total_paid_rent'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                values: [{id: 'Active'}, {id: 'Inactive'}]
            },
            {
                id: 'salary',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'HasSalary'}]
            },
        ],
        action_buttons: [],
        store: {get: 'getSellers', clear: 'clearSellers'},
        /*info_text: {
            icon: 'mdi-slot-machine',
            props: []
        },*/
        localize: "provisions",
        preselected: [undefined, undefined, 0],
        permissions: []
    },

    products_agreements: {
        submenu: true,
        card: 'AgreementCard',
        dialog: 'AgreementDialog',
        card_headers: [
            {value: 'target_price'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
            {value: 'points'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'user',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Customer'}, {id: 'Seller'}]
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Bonus'}, {id: 'Converted'}, {id: 'FromPackage'}, {id: 'Standard'}]
            },
            {
                id: 'product',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'PRODUCT_GOLD_T1'}, {id: 'PRODUCT_GOLD_T2'}, {id: 'PRODUCT_GOLD_T3'}, {id: 'PRODUCT_GOLD_T4'}, {id: 'PRODUCT_GOLD_T5'}]
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Paid'}, {id: 'NotPaid'}, {id: 'Cancelled'}, {id: 'PaymentFree'}]
            }
        ],
        action_buttons: [
            {
                label: 'productgoldt1',
                icon: 'mdi-plus-circle',
                dialog: 'AgreementDialog',
                state: 'productgoldt1',
                permissions: [Permissions.SELLER],
                conditions: []
            },
            {
                label: 'productgoldt2',
                icon: 'mdi-plus-circle',
                dialog: 'AgreementDialog',
                state: 'productgoldt2',
                permissions: [Permissions.SELLER],
                conditions: []
            },
            {
                label: 'productgoldt3',
                icon: 'mdi-plus-circle',
                dialog: 'AgreementDialog',
                state: 'productgoldt3',
                permissions: [Permissions.SELLER],
                conditions: []
            },
            {
                label: 'productgoldt5',
                icon: 'mdi-plus-circle',
                dialog: 'AgreementDialog',
                state: 'productgoldt5',
                permissions: [Permissions.SELLER],
                conditions: []
            },
            {
                label: 'export_agreements',
                icon: 'mdi-file-export',
                method: "exportAgreements",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getAgreements', clear: 'clearAgreements'},
        info_text: {
            icon: 'mdi-cash-100',
            condition: "hasActivePackage",
            props: ["packageAgreementLimit", "packagePurchaseBonus"]
        },
        localize: "products_agreements",
        preselected: [undefined, [], undefined, 0, undefined, undefined, undefined],
        permissions: []
    },

    agreements_list_select: {
        card: 'SelectorDepositCard',
        dialog: 'AgreementDialog',
        card_headers: [
            {value: 'date'},
            {value: 'id'},
            {value: 'product'},
            {value: 'target_price'},
            {value: 'points'},
            {value: 'customer'},
            {value: 'seller'},
            {value: 'status'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            }
        ],
        action_buttons: [],
        //store: 'getAgreements',
        store: {get: 'getAgreements', clear: 'clearAgreements'},
        localize: "products_agreements",
        preselected: [],
        selector_emiter: 'item-gold-transfer',
        permissions: []
    },

    agreements_provisions_list_select: {
        card: "AgreementProvisionCard",
        dialog: "AgreementDialog",
        card_headers: [
            {value: 'target_price'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
            {value: 'points'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
        ],
        action_buttons: [],
        store: {get: 'getCommissionAgreementsForSalary', clear: 'clearAgreements'},
        localize: "provisions.count",
        preselected: [],
        selector_emiter: 'item-provision',
        permissions: []
    },

    agreements_rents_list_select: {
        card: "AgreementRentCard",
        dialog: "AgreementDialog",
        card_headers: [
            {value: 'target_price'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
            {value: 'points'}
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
        ],
        action_buttons: [],
        store: {get: 'getRentAgreementsForSalary', clear: 'clearAgreements'},
        localize: "provisions.count",
        preselected: [],
        selector_emiter: 'item-provision',
        permissions: []
    },

    my_agreements: {
        card: 'MyAgreementCard',
        dialog: 'MyAgreementDialog',
        card_headers: [
            {value: 'target_price'},
            {value: 'monthly_payment'},
            {value: 'payments_total'},
            {value: 'payments_bonus'},
            {value: 'grams_total'},
            {value: 'coins'}
        ],
        filter_groups: [],
        action_buttons: [
            {
                label: 'vop',
                icon: 'mdi-file-document',
                method: "getVOP",
                permissions: [],
                conditions: []
            }
        ],
        store: {get: 'getAgreements', clear: 'clearAgreements'},
        localize: "products_agreements",
        permissions: []
    },

    products_packages: {
        submenu: true,
        card: 'PackageCard',
        dialog: 'PackageDialog',
        card_headers: [
            {value: 'active'},
            {value: 'purchase_limit'},
            {value: 'agreement_limit'},
            {value: 'agreement_count'},
            {value: 'points'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Silver'}, {id: 'Gold'}, {id: 'Business'}, {id: 'Christmas'}]
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Paid'}, {id: 'NotPaid'}, {id: 'Cancelled'}]
            },
        ],
        action_buttons: [
            {
                label: 'packages',
                icon: 'mdi-plus-circle',
                dialog: 'PackagePurchaseDialog',
                permissions: [Permissions.SELLER],
                conditions: []
            },
            {
                label: 'export',
                icon: 'mdi-file-export',
                method: "exportPackages",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getPackages', clear: 'clearPackages'},
        info_text: {
            icon: 'mdi-webpack',
            props: []
        },
        localize: "products_packages",
        preselected: [undefined, [], undefined, undefined, undefined],
        permissions: []

    },
    packages_provisions_list_select: {
        card: "PackageProvisionCard",
        dialog: "PackageDialog",
        card_headers: [
            {value: 'target_price'},
            {value: 'purchase_limit'},
            {value: 'agreement_count'},
            {value: 'points'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
        ],
        action_buttons: [],
        store: {get: 'getCommissionPackagesForSalary', clear: 'clearPackages'},
        localize: "provisions.count",
        preselected: [],
        selector_emiter: 'item-provision',
        permissions: []
    },

    products_personal_webs: {
        submenu: true,
        card: 'PersonalWebCard',
        dialog: 'PersonalWebDialog',
        card_headers: [
            {value: 'date'},
            {value: 'id'},
            {value: 'active'},
            {value: 'paid'},
            {value: 'seller'},
            {value: 'duration'},
            {value: 'validity'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'package',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Silver'}, {id: 'Gold'}, {id: 'Business'}, {id: 'Christmas'}]
            },
            {
                id: 'status',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Paid'}, {id: 'NotPaid'}, {id: 'Cancelled'}]
            },
        ],
        action_buttons: [
            {
                label: 'export',
                icon: 'mdi-file-export',
                method: "exportPersonalWebs",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        advanced_menu: {
            emiter: 'products-personal-web',
            buttons: [
                {label: 'placeholder', method: 'callMail'},
                {label: 'placeholder', method: 'callStore'},
            ]
        },
        store: {get: 'getPersonalWebs', clear: 'clearPersonalWebs'},
        info_text: {
            icon: 'mdi-information',
            props: []
        },
        localize: "products_personal_webs",
        preselected: [],
        permissions: [Permissions.ADMINISTRATOR]
    },

    products_report: {
        submenu: true,
        card: 'ProductionReportCard',
        dialog: 'ProductionReportDialog',
        card_headers: [
            {value: 'agreement_count'},
            {value: 'package_count'},
            {value: 'career_points_total'},
            {value: 'career_points_agreements'},
            {value: 'target_price_agreements'},
            {value: 'career_points_packages'},
            {value: 'target_price_packages'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'from', value: ''}, {id: 'to', value: ''}]
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Team'}, {id: 'Own'}]
            },
            {
                id: 'agreement',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'All'}, {id: 'NotPaid'}, {id: 'Paid'}, {id: 'Cancelled'}, {id: 'Bonus'}, {id: 'Converted'}, {id: 'FromPackage'}]
            },
            {
                id: 'pack',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'All'}, {id: 'NotPaid'}, {id: 'Paid'}, {id: 'Cancelled'}]
            },
            {
                id: 'agreementTypes',
                type: 'buttons',
                multiselect: true,
                mandatory: false,
                values: [{id: 'ProductGoldT1'}, {id: 'ProductGoldT2'}, {id: 'ProductGoldT3'}, {id: 'ProductGoldT4'}, {id: 'ProductGoldT5'}]
            },
            {
                id: 'packTypes',
                type: 'buttons',
                multiselect: true,
                mandatory: false,
                values: [{id: 'Silver'}, {id: 'Gold'}, {id: 'Business'}, {id: 'Christmas'}]
            },
        ],
        action_buttons: [],
        store: {get: 'getReportRecords', clear: 'clearReportRecords'},
        localize: "products_report",
        preselected: [undefined, [Utils.getCurrentMonthRange().firstDay, Utils.getCurrentMonthRange().today], 0, 2, 2, [0, 1, 2, 3, 4], [0, 1, 2, 3]],
        permissions: []
    },
    products_report_provisions_list_select: {
        card: "AgreementReportCard",
        dialog: "AgreementDialog",
        card_headers: [
            {value: 'target_price'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
            {value: 'points'}
        ],
        filter_groups: [],
        action_buttons: [],
        store: {get: 'getAgreementsForReportRecord', clear: 'clearAgreements'},
        localize: "products_report.count",
        preselected: [],
        selector_emiter: 'item-report',
        permissions: []
    },
    packages_report_list_select: {
        card: "PackageReportCard",
        dialog: "PackageDialog",
        card_headers: [
            {value: 'target_price'},
            {value: 'purchase_limit'},
            {value: 'agreement_count'},
            {value: 'points'},
        ],
        filter_groups: [],
        action_buttons: [],
        store: {get: 'getPackagesForReportRecord', clear: 'clearPackages'},
        localize: "products_packages.count",
        preselected: [],
        selector_emiter: 'item-report',
        permissions: []
    },

    gold_deposit: {
        card: 'GoldDepositCard',
        dialog: 'GoldDepositDialog',
        dialog_settings: {
            fullscreen: false,
            maxWidth: 420
        },
        card_headers: [
            {value: 'serial_number'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'date',
                type: 'date_input',
                multiselect: true,
                values: [{id: 'date_from', value: ''}, {id: 'date_to', value: ''}]
            },
            {
                id: 'range',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'Day'}, {id: 'Week'}, {id: 'Month'}]
            },
            {
                id: 'deposit_type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'ORDERED'}, {id: 'FREE'}, {id: 'IN_DEPOSIT'}, {id: 'COMPANY'}, {id: 'DepositAll'}, {id: 'AT_CUSTOMER'}]
            },
            {
                id: 'type',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'OneTimePurchase'}, {id: 'BAR_1G'}, {id: 'BAR_2G'}, {id: 'BAR_5G'}, {id: 'BAR_10G'}, {id: 'BAR_20G'},
                    {id: 'BAR_50G'}, {id: 'BAR_100G'}, {id: 'BAR_250G'}, {id: 'BAR_500G'}, {id: 'BAR_1000G'}, {id: 'COIN_ONE_OUNCE'}, {id: 'SAVING_COIN_ONE_OUNCE'}]
            }
        ],
        action_buttons: [
            {
                label: 'add_gold',
                icon: 'mdi-plus-circle',
                dialog: 'GoldDepositDialog',
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            },
            {
                label: 'assign_gold',
                icon: 'mdi-arrow-right-bold-circle',
                method: "allocateCoins",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            },
            {
                label: 'export',
                icon: 'mdi-file-export',
                method: "exportGoldDeposit",
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getGoldDeposit', clear: 'clearGoldDeposit'},
        info_text: {
            icon: 'mdi-gold',
            props: ['companyGoldSavingCoins', 'companyGoldSavingRemainder']
        },
        localize: "gold_deposit",
        preselected: [],
        permissions: []
    },
    gold_deposit_agreements_list_select: {
        card: 'SelectorDepositCard',
        //dialog: 'AgreementDialog',
        card_headers: [
            //{value: 'date'},
            {value: 'id'},
            {value: 'product'},
            //{value: 'target_price'},
            //{value: 'points'},
            {value: 'customer'},
            {value: 'seller'},
            {value: 'status'},
            {value: 'payments'},
            {value: 'grams'},
            {value: 'coins'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            }
        ],
        action_buttons: [],
        //store: 'getAgreements',
        store: {get: 'getAgreements', clear: 'clearAgreements'},
        localize: "gold_deposit",
        preselected: [],
        selector_emiter: 'item-deposit',
        permissions: []
    },

    price_lists: {
        card: 'PriceListCard',
        dialog: 'PriceListsDialog',
        dialog_settings: {
            fullscreen: false,
            maxWidth: 700
        },
        card_headers: [
            {value: 'saving1g'},
            {value: 'bar1g'},
            {value: 'bar2g'},
            {value: 'bar5g'},
            {value: 'bar10g'},
            {value: 'bar20g'},
            {value: 'bar50g'},
            {value: 'bar100g'},
            {value: 'bar250g'},
            {value: 'bar500g'},
            {value: 'bar1000g'},
            {value: 'coinOneOunce'},
        ],
        filter_groups: [],
        action_buttons: [
            {
                label: 'add_price_list',
                icon: 'mdi-plus-circle',
                dialog: 'PriceListsDialog',
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getPriceLists', clear: 'clearPriceLists'},
        localize: "price_lists",
        permissions: []
    },

    transactions: {
        card: 'BankTransactionCard',
        card_headers: [
            {value: 'date'},
            {value: 'amount'},
            {value: 'symbol'},
            {value: 'account'},
            {value: 'details'},
            {value: 'status'},
        ],
        filter_groups: [],
        action_buttons: [
            {
                label: 'load_statement',
                icon: 'mdi-plus-circle',
                dialog: 'BankTransactionDialog',
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }
        ],
        store: {get: 'getBankTransactions', clear: 'clearBankTransactions'},
        localize: "transactions",
        permissions: [Permissions.NOBODY]
    },

    purchase_reports: {
        card: 'GoldPurchaseReportCard',
        dialog: 'GoldPurchaseReportsDialog',
        dialog_settings: {
            fullscreen: false,
            maxWidth: 800
        },
        card_headers: [
            {value: 'date'},
            {value: 'savings'},
            {value: 'payments'},
            {value: 'purchase_price'},
            {value: 'advance_payments'},
            {value: 'remaining'},
            {value: 'total_remaining'},
            {value: 'historical_remaining'},
        ],
        filter_groups: [],
        action_buttons: [],
        store: {get: 'getGoldPurchaseReports', clear: 'clearGoldPurchaseReports'},
        info_text: {
            icon: 'mdi-gold',
            props: ['totalGoldAmount', 'totalOunces']
        },
        localize: "purchase_reports",
        permissions: []
    },

    salary_reports: {
        card: 'SalaryReportCard',
        dialog: 'SalaryReportDialog',
        dialog_settings: {
            fullscreen: false,
            maxWidth: 640
        },
        card_headers: [
            {value: 'date'},
            {value: 'type'},
            {value: 'total_salaries'},
            {value: 'total_amount'},
        ],
        filter_groups: [],
        action_buttons: [
            {
                label: 'generate_rent_salaries',
                icon: 'mdi-currency-eur',
                dialog: 'RentPaymentDialog',
                options: {
                    action: 'rent',
                    localize: 'salary_reports.action_buttons.generate_rent_salaries'
                },
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            },
            {
                label: 'generate_partner_salaries',
                icon: 'mdi-account-multiple-check',
                dialog: 'PartnersPaymentDialog',
                options: {
                    action: 'partner',
                    localize: 'salary_reports.action_buttons.generate_partner_salaries'
                },
                permissions: [Permissions.ADMINISTRATOR],
                conditions: []
            }

        ],
        store: {get: 'getSalaryReports', clear: 'clearSalaryReports'},
        localize: "salary_reports",
        permissions: []
    },
    salary_reports_list_select: {
        card: "SalaryReportDetailCard",
        dialog: "SalaryReportDialog",
        dialog_settings: {
            fullscreen: false,
            maxWidth: 640
        },
        card_headers: [
            {value: 'amount'},
            {value: 'vs'},
            {value: 'bank_account'}
        ],
        filter_groups: [],
        action_buttons: [],
        store: {get: 'getSalariesForReport', clear: 'clearSalariesForReport'},
        localize: "salary_reports.count",
        preselected: [],
        selector_emiter: 'item-salary',
        permissions: []
    },

    gold_price_lists: {
        card: 'PriceListCard',
        card_headers: [
            {value: 'saving1g'},
            {value: 'bar1g'},
            {value: 'bar2g'},
            {value: 'bar5g'},
            {value: 'bar10g'},
            {value: 'bar20g'},
            {value: 'bar50g'},
            {value: 'bar100g'},
            {value: 'bar250g'},
            {value: 'bar500g'},
            {value: 'bar1000g'},
            {value: 'coinOneOunce'},
        ],
        filter_groups: [],
        action_buttons: [],
        store: {get: 'getPriceLists', clear: 'clearPriceLists'},
        localize: "gold_price_lists",
        permissions: [Permissions.ADMINISTRATOR]
    },

    content_management: {
        card: 'ContentCard',
        card_target: {
            File: "ContentFileDialog",
            Image: "ContentImageDialog",
            Video: "ContentVideoDialog",
            Article: "ContentArticleDialog"
        },
        dialog: 'ContentManagementDialog',
        dialog_settings: {
            fullscreen: false,
            maxWidth: 800,
            title: 'skuska title cez v-bind'
        },
        card_headers: [
            {value: 'category'},
            {value: 'type'},
            {value: 'title'},
        ],
        filter_groups: [
            {
                id: 'search',
                type: 'search'
            },
            {
                id: 'category',
                type: 'buttons',
                multiselect: false,
                mandatory: false,
                values: [{id: 'HomeSeller'}, {id: 'HomeCustomer'}, {id: 'Documents'}, {id: 'Certificates'}, {id: 'Videos'}, {id: 'Contest'}, {id: 'Career'}]
            },
        ],
        action_buttons: [
            {
                label: 'content_add_file',
                icon: 'mdi-plus-circle',
                dialog: "ContentFileDialog",
                permissions: [Permissions.ADMINISTRATOR, Permissions.SUPPORTER],
                conditions: []
            },
            {
                label: 'content_add_image',
                icon: 'mdi-plus-circle',
                dialog: "ContentImageDialog",
                permissions: [Permissions.ADMINISTRATOR, Permissions.SUPPORTER],
                conditions: []
            },
            {
                label: 'content_add_video',
                icon: 'mdi-plus-circle',
                dialog: "ContentVideoDialog",
                permissions: [Permissions.ADMINISTRATOR, Permissions.SUPPORTER],
                conditions: []
            },
            {
                label: 'content_add_article',
                icon: 'mdi-plus-circle',
                dialog: "ContentArticleDialog",
                permissions: [Permissions.ADMINISTRATOR, Permissions.SUPPORTER],
                conditions: []
            }
        ],
        store: {get: 'getAllContent', clear: 'clearAllContent'},
        localize: "content_management",
        preselected: [undefined, 0],
        permissions: []
    },


    // REDIRECT AND NAVDRAWER DATA
    products: {
        default_redirect: "/products/agreements",
        nav: [
            {icon: 'mdi-file-multiple-outline', title: 'title.products_agreements', route: '/products/agreements'},
            {icon: 'mdi-webpack', title: 'title.products_packages', route: '/products/packages'},
            {icon: 'mdi-web', title: 'title.products_personal_webs', route: '/products/personal-webs'},
            {icon: 'mdi-chart-areaspline', title: 'title.products_report', route: '/products/report'}
        ]
    },

    sellers: {
        default_redirect: "/sellers/list",
        nav: [
            {icon: 'mdi-view-list-outline', title: 'navigation.sellers_list', route: '/sellers/list'},
            {icon: 'mdi-family-tree', title: 'navigation.sellers_hierarchy', route: '/sellers/hierarchy'},
        ]
    },

    education: {
        default_redirect: "/education/videos",
        nav: [
            {icon: 'mdi-youtube', title: 'title.education_videos', route: '/education/videos'},
            {icon: 'mdi-certificate-outline', title: 'title.education_certificates', route: '/education/certificates'}
        ]
    },

    documents: {
        default_redirect: "/documents/files",
        nav: [
            {icon: 'mdi-file-document-multiple', title: 'navigation.documents', route: '/documents/files'},
            {icon: 'mdi-certificate-outline', title: 'navigation.certificates', route: '/documents/certificates'},

        ]
    },

    career: {
        default_redirect: "/career/career",
        nav: [
            {icon: 'mdi-finance', title: 'navigation.career', route: '/career/career'},
            {icon: 'mdi-trophy', title: 'navigation.contest', route: '/career/contest'}
        ]
    }
};

export default Object.freeze({

    getDataView: function (val) {
        return data[val];
    },

    actions: function (val, _this) {
        _this.$root.$emit('overlay', true);

        switch (val) {
            case 'exportAgreements':
                exportAgreements().then(() => {
                    overlay_off(_this)
                })
                break;

            case 'exportCustomers':
                exportCustomers().then(() => {
                    overlay_off(_this);
                }).catch(() => {
                    overlay_off(_this);
                    _this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'server_error',
                        msg: 'notification.server_error'
                    });
                })
                break;

            case 'exportSellers':
                exportSellers().then(() => {
                    overlay_off(_this)
                })
                break;

            case 'exportPackages':
                exportPackages().then(() => {
                    overlay_off(_this)
                })
                break;

            case 'exportPersonalWebs':
                exportPersonalWebs().then(() => {
                    overlay_off(_this)
                })
                break;

            case 'exportGoldDeposit':
                exportGoldDeposit().then(() => {
                    overlay_off(_this)
                })
                break;

            case 'getVOP': {
                overlay_off(_this);
                let fileLink = document.createElement('a');
                fileLink.href = process.env.VUE_APP_RGG_PORTAL_UX_PUBLIC_DATA_URL + "/documents/vop.pdf";
                fileLink.setAttribute('target', "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                break;
            }

            case 'allocateCoins': {
                allocateCoins().then(() => {
                    _this.$root.$emit('reload');
                    overlay_off(_this);
                })
            }

        }

        function overlay_off(_this) {
            _this.$root.$emit('overlay', false);
        }
    }
});