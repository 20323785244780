import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '@/store/store'
import AuthService from "@/services/auth/auth_service";

import LoginView from "./views/LoginView";
import DataView from "./views/DataView";
import MasterView from "@/views/MasterView";
import ContentView from "@/views/ContentView";
import ContactsView from "@/views/ContactsView";
import Navigation from "@/views/Nav";
import HierarchyView from "@/views/HierarchyView";
import MailView from "@/views/MailView";
import Permissions from "@/services/auth/permissions";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        meta: {requiresAuth: true, requiresPerm: []},
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/home',
        name: 'home',
        component: ContentView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.CUSTOMER], category: 'Home'}
    },
    {
        path: '/users',
        name: 'users',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },

    {
        path: '/sellers',
        name: 'sellers',
        component: MasterView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },
    {
        path: '/sellers/list',
        name: 'sellers_list',
        components: {
            default: DataView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },
    {
        path: '/sellers/hierarchy',
        name: 'sellers_hierarchy',
        components: {
            default: HierarchyView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },

    {
        path: '/products',
        name: 'products',
        component: MasterView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },
    {
        path: '/products/agreements',
        name: 'products_agreements',
        components: {
            default: DataView,
            nav: Navigation,
            dialog: DataView
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },
    {
        path: '/products/packages',
        name: 'products_packages',
        components: {
            default: DataView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },
    {
        path: '/products/personal-webs',
        name: 'products_personal_webs',
        components: {
            default: DataView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },
    {
        path: '/products/report',
        name: 'products_report',
        components: {
            default: DataView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },

    {
        path: '/gold-deposit',
        name: 'gold_deposit',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.ADMINISTRATOR,  Permissions.SUPPORTER]}
    },

    {
        path: '/price-lists',
        name: 'price_lists',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.ADMINISTRATOR]}
    },

    {
        path: '/transactions',
        name: 'transactions',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.ADMINISTRATOR]}
    },

    {
        path: '/purchase-reports',
        name: 'purchase_reports',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.ADMINISTRATOR]}
    },

    {
        path: '/salary-reports',
        name: 'salary_reports',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.ADMINISTRATOR]}
    },

    {
        path: '/education',
        name: 'education',
        component: MasterView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },
    {
        path: '/education/videos',
        name: 'education_videos',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Videos'}
    },
    {
        path: '/education/certificates',
        name: 'education_certificates',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Certificates'}
    },

    {
        path: '/career/contest',
        name: 'career_contest',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Contest'}
    },
    {
        path: '/career/career',
        name: 'career_career',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Career'}
    },

    {
        path: '/documents',
        name: 'documents',
        component: MasterView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },
    {
        path: '/documents/files',
        name: 'documents_files',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Documents'}
    },
    {
        path: '/documents/certificates',
        name: 'documents_certificates',
        components: {
            default: ContentView,
            nav: Navigation
        },
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER], category: 'Certificates'}
    },

    {
        path: '/emails',
        name: 'emails',
        component: MailView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },

    {
        path: '/content-management',
        name: 'content_management',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]}
    },

    {
        path: '/provisions',
        name: 'provisions',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },

    {
        path: '/career',
        name: 'career',
        component: MasterView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER]}
    },

    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.SELLER, Permissions.CUSTOMER]}
    },

    {
        path: '/gold-price-lists',
        name: 'gold_price_lists',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.CUSTOMER]}
    },

    {
        path: '/my-agreements',
        name: 'my_agreements',
        component: DataView,
        meta: {requiresAuth: true, requiresPerm: [Permissions.CUSTOMER]}
    },

    /* REDIRECT SECTION */
    {
        path: '/eshop',
        beforeEnter() {
            window.location = 'http://eshop.royalgoldengroup.sk';
        }
    },
    {
        path: '/email',
        beforeEnter() {
            window.location = 'https://mail.platon.sk/roundcube/';
        }
    },
    {
        path: '/web',
        beforeEnter() {
            window.location = 'https://www.royalgoldengroup.sk/rgg/' + store.getters.getPersonalWeb;
        }
    }
]

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (to.path === '/eshop' || to.path === '/email' || to.path === '/web') {
        next()
    }

    if (to.path === '/login' && AuthService.isAuthenticated()) {
        next(from)
        return
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!AuthService.isAuthenticated()) {
            next({
                path: "/login"
            })
        } else if (to.path === "/") {
            if (AuthService.hasPermission(Permissions.ADMINISTRATOR) || AuthService.hasPermission(Permissions.SUPPORTER)) {
                next({path: "/content-management"})
            } else {
                next({path: "/home"})
            }
        } else {
            if (to.meta.requiresPerm.length > 0) {
                if (AuthService.hasAnyPermission(to.meta.requiresPerm)) {
                    next()
                } else {
                    next({path: "/"})
                }
            } else {
                next()
            }
        }
    } else {
        next()
    }
});