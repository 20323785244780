<template>
    <div>
        <v-list class="navigation-list pa-0 gold--text" :class="{ 'd-inline-flex' : !vertical}">
            <v-list-item v-for="(button, i) in responseMenuItems.menu" :key="i"
                         dense router :to="button.route" class="px-1">
                <v-list-item-content>
                    <v-icon>{{ button.icon }}</v-icon>
                    <v-list-item-title class="caption" style="font-size: 10px !important; text-align: center;">
                        <span v-if="vertical">{{ $t('main_menu.' + button.label + '.title') }}</span>
                        <span v-else>{{ $t('main_menu.' + button.label) }}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="responseMenuItems.pop_up.length" link>

                    <v-menu
                        v-model="response_menu"
                        :close-on-content-click="true"
                        :nudge-width="200"
                        transition="slide-y-transition"
                        offset-y :close-on-click="true"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content v-bind="attrs" v-on="on">
                                <v-icon v-if="breakPoint > 450">mdi-dots-horizontal</v-icon>
                                <v-icon v-else color="white" size="28">mdi-dots-grid</v-icon>
                            </v-list-item-content>
                        </template>
                        <v-card class="gold">
                            <v-card-text>
                                <v-list dark v-for="(button, i) in responseMenuItems.pop_up" :key="i" class="navigation-list pa-0" :class="{ 'd-inline-flex' : vertical}">
                                    <v-list-item dense router :to="button.route" class="pa-1">
                                        <v-row align="center" class="body-2" no-gutters>
                                            <v-icon left>{{ button.icon }}</v-icon>
                                            {{ $t('main_menu.' + button.label) }}
                                        </v-row>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>

                        </v-card>
                    </v-menu>

            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";

export default {
    name: "Buttons",
    props: {
        buttons: Array,
        vertical: Boolean
    },
    data: () => ({
        response_menu: false
    }),
    mixins: [AuthMixin],
    watch: {

    },
    computed: {
        filteredButtons() {
            let filteredButtons = []
            for (let i = 0; i < this.buttons.length; i++) {
                if (this.buttons[i].permissions.length === 0 || this.checkPermissions(this.buttons[i].permissions)) {
                    filteredButtons.push(this.buttons[i]);
                }
            }

            return filteredButtons;
        },
        breakPoint() {
            return this.$vuetify.breakpoint.width;
        },
        responseMenuItems() {
            let items = [];
            items['menu'] = [];
            items['pop_up'] = [];
            let length = this.filteredButtons.length;
            let limits = { max: 900, first: 750, second: 666, min: 451 };

            if( this.breakPoint < limits.max && this.breakPoint > limits.first ) {
                length = 6;
            } else if( this.breakPoint < limits.first && this.breakPoint > limits.second ) {
                length = 5;
            } else if( this.breakPoint < limits.second && this.breakPoint > limits.min ) {
                length = 4;
            } else if( this.breakPoint < limits.min ) {
                length = 0;
            }

            length = Math.min(length, this.filteredButtons.length)

            for( let i=0; i < length; i++) {
                items.menu.push( this.filteredButtons[i] );
            }

            for( let i=length; i < this.filteredButtons.length; i++ ) {
                items.pop_up.push( this.filteredButtons[i] );
            }

            return items;
        }
    }
}
</script>

<style>
.v-list-item--active {
    pointer-events: none;
}
</style>