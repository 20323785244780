import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getSalariesForReport, getSalaryReports, getSellerSalaries} from "@/api/salaries";

const state = {
    salary_reports_cancel_request: new CancelRequest(),
    salary_reports: {total_count: 0, items: []},
    salaries_cancel_request: new CancelRequest(),
    salaries: {total_count: 0, items: []},
    salaries_for_report_cancel_request: new CancelRequest(),
    salaries_for_report: {total_count: 0, items: []},
};

const actions = {
    getSalaryReports(context, data) {
        context.state.salary_reports_cancel_request.cancelAndCreateToken();
        return getSalaryReports(data, context.state.salary_reports_cancel_request)
            .then(response => {
                context.state.salary_reports_cancel_request.resetCancelToken();
                context.commit('setSalaryReports', response.data);
            })
    },
    getSalaries(context, data) {
        context.state.salaries_cancel_request.cancelAndCreateToken();
        return getSellerSalaries(data.seller_id, data.filter_data, context.state.salaries_cancel_request)
            .then(response => {
                context.state.salaries_cancel_request.resetCancelToken();
                context.commit('setSalaries', response.data);
            })
    },
    getSalariesForReport(context, data) {
        context.state.salaries_for_report_cancel_request.cancelAndCreateToken();
        return getSalariesForReport(data.report_id, data.filter_data, context.state.salaries_for_report_cancel_request)
            .then(response => {
                context.state.salaries_for_report_cancel_request.resetCancelToken();
                context.commit('setSalariesForReport', response.data);
            })
    },

    clearSalaryReports(context) {
        context.commit('clearSalaryReports');
    },
    clearSalaries(context) {
        context.commit('clearSalaries');
    },
    clearSalariesForReport(context) {
        context.commit('clearSalariesForReport');
    }
};

const mutations = {
    setSalaryReports(state, response) {
        state.salary_reports.total_count = response.totalCount
        state.salary_reports.items = []
        response.items.forEach(function (report) {
            state.salary_reports.items.push({
                id: report.id,
                date: Utils.convertDate(report.reportDate),
                type: Utils.localizeText("salary_reports.type." + report.salariesType),
                total_salaries: report.totalSalaries,
                total_amount: Utils.parsePrice(report.totalAmount),
                raw_data: report
            });
        });
    },
    setSalaries(state, response) {
        state.salaries.total_count = response.totalCount
        state.salaries.items = []
        response.items.forEach(function (salary) {
            let card_state = ""
            let amount = salary.amount !== undefined ? Utils.parsePrice(salary.amount) : '- - -'
            if (salary.paid === false && amount !== "- - -" && amount !== '0,00 €') {
                card_state = "state-red";
            }

            state.salaries.items.push({
                id: salary.id,
                state: card_state,
                date: Utils.convertDate(salary.statementDate),
                type: Utils.localizeText("salary_reports.type." + salary.type),
                amount: amount,
                rent_amount: salary.rentAmount !== undefined ? Utils.parsePrice(salary.rentAmount) : '- - -',
                paid: salary.paid ? Utils.localizeText("provisions.table_keys.paid_yes") : Utils.localizeText("provisions.table_keys.paid_no"),
                raw_data: {
                    paid: salary.paid
                }
            });
        });
    },
    setSalariesForReport(state, response) {
        state.salaries_for_report.total_count = response.totalCount
        state.salaries_for_report.items = []
        response.items.forEach(function (salary) {
            state.salaries_for_report.items.push({
                id: salary.id,
                seller: Utils.getFullNameWithPosition(salary.owner),
                amount: salary.amount !== undefined ? Utils.parsePrice(salary.amount) : '- - -',
                vs: salary.owner.id,
                bank_account: salary.bankAccount
            });
        });
    },

    clearSalaryReports(state) {
        state.salary_reports = {total_count: 0, items: []};
    },
    clearSalaries(state) {
        state.salaries = {total_count: 0, items: []};
    },
    clearSalariesForReport(state) {
        state.salaries_for_report = {total_count: 0, items: []};
    }
};

const getters = {
    getSalaryReports(state) {
        return state.salary_reports;
    },

    getSalaries(state) {
        return state.salaries;
    },

    getSalariesForReport(state) {
        return state.salaries_for_report;
    }
};

export const salaries = {
    state,
    actions,
    mutations,
    getters
};