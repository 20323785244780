<template>
    <v-app>

        <MainMenu v-if="isAuthenticated()"/>

        <v-navigation-drawer width="96px" app clipped permanent color="cx-drawer-bg" id="nav_menu"
                             v-if="isAuthenticated() && $route.matched[0].components.hasOwnProperty('nav')">
            <router-view name="nav"></router-view>
        </v-navigation-drawer>

        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>

        <Notification/>
        <Overlay/>

    </v-app>
</template>

<script>
import MainMenu from "./components/MainMenu";
import Notification from "./components/Notification";
import AuthMixin from "@/services/auth/auth_mixin";
import Overlay from "@/components/Overlay";
import Permissions from "@/services/auth/permissions";

export default {
    name: 'App',
    mixins: [AuthMixin],
    components: {
        MainMenu,
        Notification,
        Overlay
    },

    created() {
        let theme = localStorage.getItem("dark");
        if (theme) {
            this.$vuetify.theme.dark = theme === "true";
        } else {
            localStorage.setItem("dark", true);
        }
    },

    mounted() {
        if (localStorage.ACCESS_TOKEN) {
            if (!this.isAuthenticated()) {
                this.$store.dispatch('logout').then(() => {
                    window.location.reload()
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
            } else {
                if (this.checkPermission(Permissions.SELLER)) {
                    this.$store.dispatch('getSellerInfo')
                }
                this.$store.dispatch('loadProfileImage')
            }
        }

        setInterval(function () {
            if (this.isAuthenticated()) {
                if (this.needTokenRefresh() === true) {
                    this.$store.dispatch("refresh")
                }
            } else {
                if (this.$store.getters.getJWT) {
                    this.$store.dispatch('logout').then(() => {
                        window.location.reload()
                    }).catch(()=>{
                        this.$root.$emit('overlay', false);
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'info',
                            msg: 'notification.server_error'
                        });
                    })
                }
            }
        }.bind(this), 5000);
    }
};
</script>

<style src="./assets/global.css"></style>
<style src="./assets/response.css"></style>