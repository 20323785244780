import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getPriceLists(filter_data, cancel_request) {
    return ApiService.cancellableGet("/price-lists" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getCurrentPriceList() {
    return ApiService.get("/price-lists/current")
}

export function getPriceList(id) {
    return ApiService.get("/price-lists/" + id)
}

export function createPriceList(data) {
    return ApiService.post("/price-lists", data)
}

export function updatePriceList(id, data) {
    return ApiService.put("/price-lists/" + id, data)
}

export function getPriceMarginList() {
    return ApiService.get("/price-lists/margin")
}

export function updatePriceMarginList(data) {
    return ApiService.put("/price-lists/margin", data)
}