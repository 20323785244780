<template>
    <v-snackbar dark v-model="notification" :color="notify.type" :timeout="timeout" :centered="!bottom" :bottom="bottom">
        <v-icon left>{{ icon }}</v-icon>
        <span>{{ $t(notify.msg) }}</span>
    </v-snackbar>
</template>

<script>
export default {
    name: "Notification",
    data: () => ({
        notification: false,
        notify: Object,
        timeout: 2000,
        icons: {
            add: 'mdi-plus-circle',
            update: 'mdi-check-circle',
            remove: 'mdi-trash-can-outline',
            info: 'mdi-information-outline',
            error: 'mdi-alert-decagram',
            show: 'mdi-eye',
            hide: 'mdi-eye-off-outline',
            export: 'mdi-export',
            role: 'mdi-account',
            email: 'mdi-email-multiple',
            password: 'mdi-lock',
            password_alert: 'mdi-lock-alert',
            server_error: 'mdi-cloud-alert'
        }
    }),

    computed: {
        icon() {
            return this.icons[this.notify.icon];
        },

        bottom() {
            return this.notify.icon !== 'error' && this.notify.icon !== 'server_error'
        }
    },

    mounted() {
        this.$root.$on('notification', (message) => {
            this.notification = true;
            this.notify = message;
        })
    }
}
</script>

<style>
    .v-snack div.v-snack__wrapper {
        border-left: 6px solid white !important;
        opacity: 0.95;
    }
</style>