<template>
    <v-container :id="'rgg_data_view_container_' + $route.name" v-if="isAuthenticated()" class="pa-0" fluid>
        <v-row class="cl-title pa-2" no-gutters :class="theme">
            <v-col>
                <span :id="'rgg_data_view_title_' + $route.name" class="headline gold--text text-uppercase font-weight-light pa-2">
                    {{ $t('title.' + $route.name) }}
                </span>
            </v-col>
        </v-row>

        <v-row class="" no-gutters>
            <DataTable
                :toolbar_filter="computeFilter"
                :toolbar_filter_groups="data_view.filter_groups"
                :toolbar_filter_preselected="data_view.preselected"
                :data="tableData"
                :header="data_view.card_headers"
                :total_count="totalCount"
                :progress="progress"
                :action_menu="data_view.action_buttons"
                :card="data_view.card"
                :dialog="data_view.dialog"
                :dialog_settings="data_view.dialog_settings"
                :localize="data_view.localize"
                :advanced_menu="data_view.advanced_menu"
                :card_target="data_view.card_target"
                :emiter="data_view.selector_emiter"
                :permission="data_view.permissions"
                :message_box="getBoxMessage()"
                :show_report_filter="g_getRouteName() === 'products_report'"
            />
        </v-row>
    </v-container>
</template>

<script>
import views from './views';
import DataTable from "@/components/DataTable";
import AuthMixin from "@/services/auth/auth_mixin";
import {getGlobalData} from "@/api/global";
import Permissions from "@/services/auth/permissions";
import {Utils} from "@/services/utils";

export default {
    name: "DataView",
    components: {DataTable},
    mixins: [AuthMixin],
    data: () => ({
        data_view: undefined,
        filter: {
            page: 1,
            limit: 20,
            toolbar_filter: {}
        },
        progress: false,
        menu: [],
        report_show: false,
        global_props: {}
    }),
    created() {
        this.data_view = views.getDataView(this.$route.name);

        if (!this.data_view.filter_groups.length) {
            if (this.$route.name === 'products_report') {
                return;
            } else {
                this.filterRefresh();
            }
        }
    },
    watch: {
        'filter': {
            handler: function () {
                this.filterRefresh();
            },
            deep: true
        }
    },
    computed: {
        computeFilter() {
            return this.filter;
        },
        tableData() {
            if (this.$route.name === 'products_report' && !this.report_show) {
                return [];
            } else {
                return this.$store.getters[this.data_view.store.get].items;
            }

        },
        totalCount() {
            if (this.$route.name === 'products_report' && !this.report_show) {
                return 0;
            } else {
                return this.$store.getters[this.data_view.store.get].total_count;
            }

        },

        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        }
    },
    methods: {
        filterRefresh() {
            this.progress = true;
            this.$store.dispatch(this.data_view.store.get, this.filter)
                .then(() => {
                    this.progress = false;
                }).catch(() => {
                this.progress = false;
            });
        },

        getBoxMessage() {

            const info_data = this.data_view.info_text;

            if (info_data && info_data.props.length !== 0) {

                if (info_data.condition !== undefined) {
                    if (this.$store.getters[info_data.condition] === false) {
                        return null
                    }
                }

                let message_data = {}
                let params = {};
                let index = 0;

                for (const prop of Object.values(info_data.props)) {
                    let value = this.global_props[prop]
                    if (value === null) {
                        value = '###'
                    }
                    params[index++] = value;
                }

                message_data.icon = info_data.icon;
                let msgSplit = this.$t('message_box.' + this.$route.name, params).toString().split("<br/>")

                message_data.msg = ""
                for (let i = 0; i < msgSplit.length; i++) {
                    if (!msgSplit[i].includes("###")) {
                        if (i > 0) {
                            message_data.msg += "<br/>"
                        }
                        message_data.msg += msgSplit[i]
                    }
                }

                return message_data;

            } else {
                return null;
            }
        },
    },

    mounted() {
        if (Object.prototype.hasOwnProperty.call(this.data_view, 'info_text')) {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                getGlobalData().then((response) => {
                    response.data.companyGoldSaving = Utils.parseValue(response.data.companyGoldSaving)
                    response.data.companyGoldSavingRemainder = Utils.parseValue(response.data.companyGoldSavingRemainder)
                    response.data.totalGoldAmount = Utils.parseValue(response.data.totalGoldAmount)
                    response.data.totalOunces = Utils.parseValue(response.data.totalOunces)
                    this.global_props = response.data
                }).catch(() => {
                });
            } else if (this.checkPermission(Permissions.SELLER)) {
                this.$store.dispatch("getSellerInfo").then(() => {
                    this.global_props = this.$store.getters.getSellerInfo
                });
            }
        }

        this.$root.$on('reload', () => {
            this.filterRefresh();
        });
        this.$root.$on('reload-report', () => {
            this.report_show = true;
            this.filterRefresh();
        })
    },
    destroyed() {
        if (Object.prototype.hasOwnProperty.call(this.data_view.store, 'clear')) {
            this.$store.dispatch(this.data_view.store.clear);
        }
        this.$root.$off('reload');
        this.$root.$off('reload-report');
    }
}
</script>

<style>
.cl-title {
    position: sticky;
    top: 58px;
    z-index: 1;
}

.v-btn-toggle--group > .v-btn.v-btn {
    margin: 0;
}
</style>