import axios from 'axios'

export class CancelRequest {
    constructor() {
        this.cancel_request = null;
    }

    cancelAndCreateToken() {
        if (this.cancel_request) {
            this.cancel_request.cancel();
        }
        this.cancel_request = axios.CancelToken.source();
    }

    resetCancelToken() {
        this.cancel_request = null;
    }
}

export function isCancel(error) {
    return axios.isCancel(error)
}

const ApiService = {

    apiURL: "",

    init(apiURL) {
        ApiService.apiURL = apiURL;
        ApiService.setBaseURL();
        ApiService.setHeader();
    },

    setHeader() {
        if (localStorage.ACCESS_TOKEN) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.ACCESS_TOKEN}`
        } else {
            axios.defaults.headers.common = {}
        }
    },

    setBaseURL() {
        axios.defaults.baseURL = ApiService.apiURL
    },

    get(resource) {
        return axios.get(resource)
    },

    cancellableGet(resource, cancel_request) {
        return axios.get(resource, {cancelToken: cancel_request.cancel_request.token})
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    getData(resource) {
        return axios({
            url: resource,
            method: 'GET',
            responseType: 'blob',
        })
    },

    download(resource, file_name) {
        return axios({
            url: resource,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]))
            let fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', file_name)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)

        });
    },

    upload(resource, form_data) {
        return axios.post(resource, form_data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
};

export default ApiService