const messages_en = {

    common: {
        yes: "Yes",
        no: "No"
    },

    login: {
        username: "Username",
        password: "Password",
        submit: "Submit",
        error: "Nesprávne meno alebo heslo",
        gdpr: "Ochrana osobných údajov"
    },

    sections: {
        home: 'Home',
        users: 'Users',
        products: 'Products',
        gold_storage: 'Gold storage',
        price_list: 'Price List',
        transactions: 'Transactions',
        shopping_report: 'Shopping report',
        pay_off_report: 'Pay Off report',
        education: 'Education',
        documents: 'Documents',
        web_articles: 'Web articles',
        configuration: 'Configuration'
    },

    tooltip: {}
};

export default messages_en