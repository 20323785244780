import {getSeller, getSellers, getSellerUpLine} from "@/api/sellers";
import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";

const state = {
    sellers_cancel_request: new CancelRequest(),
    sellers: {total_count: 0, items: []},
    selected_seller: {},
    seller_upline: [],
    seller_upline_widget: []
};

const actions = {
    getSellers(context, data) {
        context.state.sellers_cancel_request.cancelAndCreateToken();
        return getSellers(data, context.state.sellers_cancel_request)
            .then(response => {
                context.state.sellers_cancel_request.resetCancelToken();
                context.commit('setSellers', response.data);
            })
    },
    getSeller(context, id) {
        return getSeller(id)
            .then(response => {
                context.commit('setSelectedSeller', response.data);
            })
    },
    getSellerUpLine(context, id) {
        return getSellerUpLine(id)
            .then(response => {
                context.commit('setSellerUpLine', response.data);
            })
    },
    getSellerUpLineWidget(context, id) {
        return getSellerUpLine(id)
            .then(response => {
                context.commit('setSellerUpLineWidget', response.data);
            })
    },

    clearSellers(context) {
        context.commit('clearSellers');
    },
    clearSeller(context) {
        context.commit('clearSeller');
    },
    clearSellerUpLine(context) {
        context.commit('clearSellerUpLine');
    },
    clearSellerUpLineWidget(context) {
        context.commit('clearSellerUpLineWidget');
    }
};

const mutations = {
    setSellers(state, sellers_response) {
        state.sellers.total_count = sellers_response.totalCount;
        state.sellers.items = [];
        sellers_response.items.forEach(function (seller) {
            let quick_start
            if (seller.sellerPackage != null) {
                quick_start = Utils.localizeText("packages." + seller.sellerPackage.type) + " - " + seller.sellerPackage.id
            } else {
                let dayKey
                if (seller.quickStartDays === 1) {
                    dayKey = "day";
                } else if (seller.quickStartDays > 1 && seller.quickStartDays < 5) {
                    dayKey = "days_" + seller.quickStartDays;
                } else {
                    dayKey = "days";
                }
                if (seller.quickStartDays > 0) {
                    quick_start = seller.quickStartDays + " " + Utils.localizeText("seller.days." + dayKey)
                } else {
                    quick_start = ''
                }
            }
            let active_days = ''
            if (seller.status.active === true) {
                if (seller.status.activeDays !== -1) {
                    active_days = seller.status.activeDaysCurrent
                } else {
                    active_days = Utils.localizeText("seller.status.unlimited")
                }
            } else {
                active_days = Utils.localizeText("seller.status.inactive")
            }
            state.sellers.items.push({
                id: seller.id,
                registration: Utils.convertDate(seller.registrationDate),
                name: Utils.getFullName(seller),
                phone: seller.phone,
                position: Utils.localizeText("seller.position." + seller.position),
                certificates: seller.certificates,
                active: seller.status.active,
                disabled: seller.disabled,
                active_days: active_days,
                quick_start: quick_start,
                current_salary: Utils.parsePrice(seller.status.currentSalary),
                total_salaries: Utils.parsePrice(seller.status.totalSalaries),
                total_rent: Utils.parsePrice(seller.status.totalRent),
                total_paid_rent: Utils.parsePrice(seller.status.totalPaidRent)
            });
        });
    },
    setSellerUpLine(state, sellers_response) {
        state.seller_upline = [];
        sellers_response.items.forEach(function (seller) {
            state.seller_upline.push({
                id: seller.id,
                name: Utils.getFullName(seller),
                position: Utils.localizeText("seller.position." + seller.position),
            });
        });
    },
    setSellerUpLineWidget(state, sellers_response) {
        state.seller_upline_widget = [];
        sellers_response.items.forEach(function (seller) {
            state.seller_upline_widget.push({
                id: seller.id,
                name: Utils.getFullName(seller),
                disabled: seller.disabled,
                position: Utils.localizeText("seller.position." + seller.position),
            });
        });
    },
    setSelectedSeller(state, response) {
        state.selected_seller = {};
        state.selected_seller = response;
    },

    clearSellers(state) {
        state.sellers = {total_count: 0, items: []};
    },
    clearSeller(state) {
        state.selected_seller = {};
    },
    clearSellerUpLine(state) {
        state.seller_upline = [];
    },
    clearSellerUpLineWidget(state) {
        state.seller_upline_widget = [];
    }
};

const getters = {
    getSellers(state) {
        return state.sellers;
    },
    getSellerUpLine(state) {
        return state.seller_upline;
    },
    getSellerUpLineWidget(state) {
        return state.seller_upline_widget;
    },
    getSelectedSeller(state) {
        return state.selected_seller
    }
};

export const sellers = {
    state,
    actions,
    mutations,
    getters
};