<template>
    <v-container class="data-select pa-0" fluid>
            <DataTable
                :toolbar_filter="computeFilter"
                :toolbar_filter_groups="data_view.filter_groups"
                :toolbar_filter_preselected="data_view.preselected"
                :data="tableData"
                :header="data_view.card_headers"
                :total_count="totalCount"
                :progress="progress"
                :action_menu="data_view.action_buttons"
                :card="data_view.card"
                :dialog="data_view.dialog"
                :localize="data_view.localize"
                :emiter="data_view.selector_emiter"
                :permission="data_view.permissions"
                select
            />
    </v-container>
</template>

<script>
import views from '@/views/views';
import DataTable from "@/components/DataTable";

export default {
    name: "DataView",
    components: {DataTable},
    props: {
        data_view_name: String,
        filter_push: Object
    },
    data: () => ({
        data_view: undefined,
        filter: {
            page: 1,
            limit: 20,
            toolbar_filter: {}
        },
        progress: false,
        menu: []
    }),
    created() {

        this.data_view = views.getDataView(this.data_view_name);

        if( Object.prototype.hasOwnProperty.call(this.data_view, 'item_per_page') ) {
            this.filter.limit = this.data_view.item_per_page;
        }
        if(!this.data_view.filter_groups.length) {
            this.filterRefresh();
        }
    },
    watch: {
        'filter': {
            handler: function () {
                this.filterRefresh();
            },
            deep: true
        }
    },
    computed: {
        computeFilter() {
            return this.filter;
        },
        tableData() {
            return this.$store.getters[this.data_view.store.get].items;
        },
        totalCount() {
            return this.$store.getters[this.data_view.store.get].total_count;
        },
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        }
    },
    methods: {
        filterRefresh() {
            this.progress = true;

            let result = this.filter;
            if(typeof this.filter_push !== "undefined") {
                result.filter_data = this.filter;
                Object.assign(result, this.filter_push);
            }

            this.$store.dispatch(this.data_view.store.get, result).then(() => {
                this.progress = false;
            }).catch( ()=> {
                this.$root.$emit('overlay', false);
                /*this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });*/
            })
        },
    },

    mounted() {},
    destroyed() {
        if( Object.prototype.hasOwnProperty.call(this.data_view.store, 'clear') ) {
            this.$store.dispatch(this.data_view.store.clear)
                .then(() => {
                    this.$root.$emit('dataselect-done');
                }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        } else {
            this.$root.$emit('dataselect-done');
        }
    }
}
</script>

<style>
    .cl-title {
        position: sticky;
        top: 58px;
        z-index: 1;
    }

    .data-select .data-table-toolbar {
        top: 0;
    }

    .v-btn-toggle--group > .v-btn.v-btn {
        margin: 0;
    }
</style>