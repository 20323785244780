import {getAllContent, getContent} from "@/api/content";
import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";

const state = {
    content_cancel_request: new CancelRequest(),
    content: {},
    all_content: {total_count: 0, items: []},
    update_time_stamp: Date.now()
};

const actions = {
    getAllContent(context, data) {
        context.state.content_cancel_request.cancelAndCreateToken();
        return getAllContent(data, context.state.content_cancel_request)
            .then(response => {
                context.state.content_cancel_request.resetCancelToken();
                context.commit('setAllContent', response.data);
            })
    },
    getContent(context, group) {
        return getContent(group)
            .then(response => {
                context.commit('setContent', response.data);
            })
    },

    clearAllContent(context) {
        context.commit('clearAllContent');
    },

    updateTimeStamp(context) {
        context.commit('setUpdateTimeStamp', Date.now())
    }
};

const mutations = {
    setContent(state, content) {
        state.content = content
    },

    setAllContent(state, response) {
        state.all_content.total_count = response.totalCount
        state.all_content.items = []
        response.items.forEach(function (content) {
            state.all_content.items.push({
                id: content.id,
                title: content.title,
                category: Utils.localizeText("content_management.category." + content.category),
                type: Utils.localizeText("content_management.type." + content.type),
                sort: content.sort,
                file: content.file,
                video_id: content.video_id,
                raw_data: content
            });
        });
    },

    clearAllContent(state) {
        state.all_content = {total_count: 0, items: []};
    },

    setUpdateTimeStamp(state, new_time_stamp) {
        state.update_time_stamp = new_time_stamp
    }
};

const getters = {
    getContent(state) {
        return state.content;
    },

    getAllContent(state) {
        return state.all_content;
    },

    getUpdateTimeStamp(state) {
        return state.update_time_stamp
    }
};

export const content = {
    state,
    actions,
    mutations,
    getters
}