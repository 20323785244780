import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getSellers(filter_data, cancel_request) {
    return ApiService.cancellableGet("/sellers" + Utils.getFilterUrl(filter_data) + "&with_status=true", cancel_request)
}

export function createSeller(data) {
    return ApiService.post("/sellers/", data)
}

export function updateSeller(seller_id, data) {
    return ApiService.put("/sellers/" + seller_id, data)
}

export function getChildSellers(seller_id) {
    return ApiService.get("/sellers/" + seller_id + "/children")
}

export function getSellersCount(seller_id) {
    return ApiService.get("/sellers/" + seller_id + "/count")
}

export function getSellerUpLine(seller_id) {
    return ApiService.get("/sellers/" + seller_id + "/upline")
}

export function getSeller(seller_id) {
    return ApiService.get("/sellers/" + seller_id)
}

export function sendRegistrationEmail(seller_id) {
    return ApiService.put("/sellers/" + seller_id + "/send-registration-email")
}

export function createCompanyEmail(seller_id) {
    return ApiService.put("/sellers/" + seller_id + "/create-company-email")
}

export function sendStatusActivityMail(seller_id) {
    return ApiService.put("/sellers/" + seller_id + "/send-status-activity-email")
}

export function recomputeHierarchy(seller_id) {
    return ApiService.put("/sellers/" + seller_id + "/recompute")
}