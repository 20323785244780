import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function generateReport(filter_data) {
    return ApiService.post("/production", filter_data)
}

export function getReportRecords(filter_data, cancel_request) {
    return ApiService.cancellableGet("/production" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getAgreementsForReportRecord(record_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/production/" + record_id + "/agreements" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getPackagesForReportRecord(record_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/production/" + record_id + "/packages" + Utils.getFilterUrl(filter_data), cancel_request)
}
