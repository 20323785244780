import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getSalaryReports(filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/salary-reports" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getSalariesForReport(report_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/salary-reports/" + report_id + "/salaries" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getSellerSalaries(seller_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/seller-salaries/" + seller_id + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getCommissionAgreementsForSalary(salary_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/salary-details/" + salary_id + "/commission-agreements" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getRentAgreementsForSalary(salary_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/salary-details/" + salary_id + "/rent-agreements" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getCommissionPackagesForSalary(salary_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/salaries/salary-details/" + salary_id + "/commission-packages" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function downloadSalaryInvoice(salary_id, salary_date) {
    let dateSplit = salary_date.split(".")
    return ApiService.download("/salaries/salary-details/" + salary_id + "/invoice", "vyplata_fa_" + dateSplit[2] + "_" + dateSplit[1] + "_" + dateSplit[0] + ".pdf");
}

export function getPartnerSalaries() {
    return ApiService.get("/salaries/partner-salaries")
}

export function generatePartnerSalaries(data) {
    return ApiService.put("/salaries/generate-partner-salaries", data)
}

export function generateRentSalaries(data) {
    return ApiService.put("/salaries/generate-rent-salaries", data)
}