<template>
    <v-row no-gutters align-content="center">
        <v-dialog
            v-model="dialog_login" max-width="600"
            hide-overlay persistent
            transition="slide-y-reverse-transition"
            no-click-animation

        >
            <v-card class="pa-3 login-dialog" :class="{ 'login-dialog-dark' : isDark }">
                <!-- LOGO -->
                <v-row>
                    <v-col align="center">
                        <v-img src="@/assets/rgg_logo.png" max-width="480" class="mr-4"/>
                    </v-col>
                </v-row>

                <v-divider class="pb-3" style="visibility: hidden;"/>

                <v-card-text>
                    <v-form @submit.prevent="login" @keyup.native.enter="login" id="form" ref="form">
                        <v-row class="login-input">
                            <v-col cols="12" sm="6" md="6" class="pb-0" align="center">
                                <v-text-field v-model="username" :label="$t('login.username')" color="gold"
                                              validate-on-blur
                                              prepend-inner-icon="mdi-account"
                                              outlined dense/>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="pb-0" align="center">
                                <v-text-field v-model="password" type="password" :label="$t('login.password')"
                                              color="gold"
                                              prepend-inner-icon="mdi-lock" outlined dense/>
                            </v-col>
                            <v-col cols="12" xs="12" align="center" class="mx-0 px-0 pt-0">
                                <v-btn text class="mt-1" @click="login">
                                    {{ $t('login.submit') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-text class="pb-1">
                    <v-row>
                        <v-col xs="12" align="center">
                            <a :href="privacyLink" target="_blank" style="text-decoration: none;">
                                <v-btn text dense small>
                                    <v-icon x-small class="grey--text">mdi-text-box-outline</v-icon>
                                    <span class="caption grey--text"
                                          style="font-size: 10px !important;">{{ $t('login.gdpr') }}</span>
                                </v-btn>
                            </a>
                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>
            <v-expand-transition>
                <v-alert v-show="show_login_error"
                         type="error"
                         dense text
                         class="mx-auto mb-0">
                    <span>
                        {{ $t('login.error') }}
                    </span>
                </v-alert>
            </v-expand-transition>

            <v-card style="background-color: rgba(0,0,0,0.2);">
                <v-btn text color="gold" @click="dialog_price_list = true">
                    <v-icon left small>mdi-gold</v-icon>
                    <span class="body-2">{{ $t('login.gold_price_list') }}</span>
                </v-btn>
                <v-dialog
                    v-model="dialog_price_list"
                    max-width="640" scrollable
                >
                    <v-card>
                        <v-card-title class="text-uppercase gold--text">
                            <v-spacer></v-spacer>
                            <span>{{ $t('login.gold_price_list') }}</span>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-divider class="gold darken-2 mb-2"/>
                        <v-card-text v-if="document_ready">
                            <!--VALID-->
                            <v-row>
                                <v-col cols="12" align="center" class="body-1 pb-0">
                                </v-col>
                                <v-col cols="12" align="center" class="body-2 gold--text pt-0">
                                    {{ $t('gold_price_lists.hint_login', {price: getCurrentPriceList.saving1g}) }}
                                </v-col>
                            </v-row>

                            <!--PRICE LIST-->
                            <v-row no-gutters class="grey darken-4 mt-3 pa-4 pt-0 rounded"
                                   style="max-width: 300px; margin: auto auto;">
                                <v-row class="gold darken-1 white--text py-0 my-0 mb-2 rounded">
                                    <v-col cols="6" align="left" class="py-0 font-weight-bold">
                                        {{ $t('gold_price_lists.login_price_list.grams') }}
                                    </v-col>
                                    <v-col cols="6" align="right" class="py-0 font-weight-bold">
                                        {{ $t('gold_price_lists.login_price_list.price') }}
                                    </v-col>
                                </v-row>

                                <v-col cols="12" class="pa-0" v-for="(item, i) in current_prices" :key="i">
                                    <v-row>
                                        <v-col cols="6" align="left">
                                            {{ item.name }}
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            {{ item.price.toFixed(2) }} €
                                        </v-col>
                                    </v-row>
                                    <v-divider v-if="i < current_prices.length-1" class="my-1"/>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text dense small class="px-4 gold white--text" @click="dialog_price_list = false">
                                <span class="caption">{{ $t('common.close') }}</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog_gdpr" max-width="600" persistent overlay-color="black" overlay-opacity="1">
            <v-toolbar dense color="gold" dark>
                <v-toolbar-title>
                    {{ $t('gdpr.title') }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text class="pt-4">
                    {{ $t('gdpr.text') }}
                </v-card-text>
                <v-card-actions class="py-8">
                    <v-spacer></v-spacer>
                    <v-btn @click="gdprAccepted" small class="caption px-6" color="gold" dark>
                        {{ $t('gdpr.accept') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {acceptGDPR} from "@/api/user_profile";

export default {
    name: "LoginView",
    mixins: [AuthMixin],
    data: () => ({
        username: '',
        password: '',
        dialog_login: true,
        show_login_error: false,
        dialog_price_list: false,
        current_prices: [],
        document_ready: false,
        dialog_gdpr: false
    }),

    created() {
        this.$vuetify.theme.dark = "true";
    },
    destroyed() {
        let theme = localStorage.getItem("dark");
        if (theme) {
            this.$vuetify.theme.dark = theme === "true";
        } else {
            localStorage.setItem("dark", true);
        }
    },

    computed: {
        isDark() {
            return this.$root.$vuetify.theme.dark;
        },
        privacyLink() {
            return process.env.VUE_APP_RGG_PORTAL_UX_PUBLIC_DATA_URL + "/documents/oou.pdf"
        },
        getCurrentPriceList() {
            return this.$store.getters.getCurrentPriceList;
        },
    },

    methods: {
        login() {
            this.$store.dispatch('login', {username: this.username, password: this.password})
                .then(() => {
                    if (this.isAuthenticated()) {
                        if (this.$store.getters.getGDPRAccepted) {
                            if (this.checkPermission(Permissions.SELLER)) {
                                this.$store.dispatch('getSellerInfo')
                            } else {
                                this.$store.dispatch('clearSellerInfo')
                            }
                            this.$store.dispatch('loadProfileImage').then(() => {
                                this.show_login_error = false
                                if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                                    this.$router.push(this.$router.history.current.query.redirect || '/content-management')
                                } else {
                                    this.$router.push(this.$router.history.current.query.redirect || '/home')
                                }
                            })
                        } else {
                            this.dialog_gdpr = true;
                        }
                    } else {
                        this.show_login_error = true;
                        this.$refs.form.reset();
                    }
                }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })

        },

        gdprAccepted() {
            acceptGDPR().then((response) => {
                if (response.data) {
                    this.login()
                } else {
                    //
                }
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        }
    },

    mounted() {
        this.$store.dispatch('getCurrentPriceList')
            .then(() => {
                let currentList = this.getCurrentPriceList;
                let count = 0;
                for (let key in currentList) {
                    if (count > 3) {
                        this.current_prices.push(
                            {name: this.$t('gold_price_lists.login_price_list.' + key), price: currentList[key]}
                        );
                    }
                    count++;
                }
                this.document_ready = true;
            }).catch(() => {
            this.$root.$emit('overlay', false);
            this.$root.$emit('notification', {
                type: 'error',
                icon: 'info',
                msg: 'notification.server_error'
            });
        })
    }

}
</script>

<style>
.login-dialog {
    background-color: #eee !important;
    border: 1px solid grey;
}

.login-dialog-dark {
    background-color: #222 !important;
    border: 1px solid #333 !important;
}

.login-dialog .login-input .v-icon {
    font-size: 20px;
}
</style>