<template>
    <v-row :class="{ 'rgg-grey' : dark }" class="grey lighten-4 rounded mb-0 widget">
        <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
            {{ $t('sellers.dialog.career_data') }} • {{ $t('seller.position.' + seller_data.position) }}
        </v-col>

        <!--Points-->
        <v-col cols="12" class="block pb-0">
            <v-card :elevation="dark ? 0 : 6">
                <v-card-title class="pb-0">
                    <v-row align="center">
                        <v-col cols="12" :sm="6" class="gold--text">
                            <v-icon left large color="gold">mdi-chart-arc</v-icon>
                            <span class="text-h6 font-weight-light">{{ $t('sellers.dialog.career_points') }}</span>
                        </v-col>
                        <v-col cols="12" :sm="6" align="right" class="body-1 status text-uppercase">
                                        <span class="white gold--text rounded px-4 ml-2 py-1">
                                            {{ $t('sellers.dialog.status') + ': ' + getStatus }}
                                            <v-icon v-if="seller_data.status.active" color="green" right size="22"
                                                    class="mb-1">mdi-check-circle</v-icon>
                                            <v-icon v-else color="red" right size="22"
                                                    class="mb-1">mdi-close-circle</v-icon>
                                        </span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" :sm="6" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase">{{ $t('sellers.dialog.own_career_points') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{
                                        seller_data.status.careerPoints + ' (' + seller_data.status.careerPointsTotal + ')'
                                    }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" :sm="6" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase">{{ $t('sellers.dialog.group_career_points') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{
                                        seller_data.status.groupCareerPoints + ' (' + seller_data.status.groupCareerPointsTotal + ')'
                                    }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" :sm="6" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase ">{{ $t('sellers.dialog.total_career_points') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{
                                        seller_data.status.totalCareerPoints + ' (' + seller_data.status.totalCareerPointsTotal + ')'
                                    }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <!--NextPosition-->
        <v-col cols="12" v-if="showNextPosition" class="block pb-0">
            <v-card :elevation="dark ? 0 : 4">
                <v-card-title>
                    <v-row align="center">
                        <v-col cols="12" :sm="6" class="gold--text">
                            <v-icon left large color="gold">
                                mdi-chevron-triple-up
                            </v-icon>
                            <span class="text-h6 font-weight-light">{{ $t('sellers.dialog.next_position') }}</span>
                        </v-col>
                        <v-col cols="12" :sm="6" align="right" class="body-1 status text-uppercase">
                            <span class="gold white--text rounded px-4 ml-2 py-1">
                                {{ $t('seller.position.' + seller_data.status.nextPosition) }}
                            </span>
                        </v-col>
                    </v-row>
                </v-card-title>

                <!--RequiredCareerPoints-->
                <v-card-text align="center" class="py-0 pb-2">
                    <v-progress-linear
                        :value="progressCalculate(seller_data.status.requiredCareerPoints,seller_data.status.missingCareerPoints)"
                        color="gold lighten-1" :background-color="dark ? '#101010' : ''"
                        height="25" rounded
                    >
                        <span :class="dark ? 'grey--text text--lighten-3' : 'gold--text text--darken-2'">
                            {{
                                $t('sellers.dialog.next_position_points') + ': ' + seller_data.status.requiredCareerPoints + ' (-' + seller_data.status.missingCareerPoints + ')'
                            }}
                        </span>
                    </v-progress-linear>
                </v-card-text>

                <v-card-text v-if="seller_data.status.requiredActiveMembers > 0" align="center" class="py-0 pb-2">
                    <v-progress-linear
                        :value="progressCalculate(seller_data.status.requiredActiveMembers,seller_data.status.missingActiveMembers)"
                        color="gold lighten-1" :background-color="dark ? '#101010' : ''"
                        height="25" rounded
                    >
                        <span :class="dark ? 'grey--text text--lighten-3' : 'gold--text text--darken-2'">
                            {{
                                $t('sellers.dialog.active_members') + ': ' + seller_data.status.requiredActiveMembers + ' ' + seller_data.status.requiredActiveMembersType + ' (-' + seller_data.status.missingActiveMembers + ')'
                            }}
                        </span>
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-col>

        <!--Provision-->
        <v-col cols="12" class="" v-if="can_see">
            <v-card :elevation="dark ? 0 : 2">
                <v-card-title class="pb-0">
                    <v-row align="center">
                        <v-col cols="12" class="gold--text">
                            <v-icon left large color="gold">
                                mdi-finance
                            </v-icon>
                            <span class="text-h6 font-weight-light">{{ $t('sellers.dialog.provisions') }}</span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" :sm="6" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase">{{ $t('sellers.dialog.salary') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{ g_parsePrice(seller_data.status.currentSalary) }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" :sm="6" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase">{{ $t('sellers.dialog.total_salaries') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{ g_parsePrice(seller_data.status.totalSalaries) }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" :md="4" align="center" class="gold--text body-2">
                            <span class="text-uppercase">{{ $t('sellers.dialog.rent') }}</span>
                            <div class="gold rounded py-1">
                                <span class="white--text body-1">
                                    {{
                                        g_parsePrice(seller_data.status.totalRent) + '(' + g_parsePrice(seller_data.status.totalPaidRent) + ')'
                                    }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <!--SELLER UPLINE-->
        <v-row v-if="sellerUpLineWidget.length > 0" :class="{ 'rgg-grey' : this.$vuetify.theme.dark }"
               class="grey lighten-4 rounded ma-0">
            <v-col cols="12" class="body-1 gold--text font-weight-medium py-2">
                {{ $t('title.upline') }}
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-card>
                    <v-card-text>
                        <v-row v-for="(seller, i) in sellerUpLineWidget" :key="seller.id"
                               :style="'margin-left: calc(10px * ' + i + ');'">

                            <v-icon color="gold" left small v-if="i">mdi-arrow-up-left</v-icon>
                            <span :class="getUplineClass(seller)">
                                    {{ seller.name + ' • (' + seller.position + ')' }}
                                </span>
                            <v-row v-if="i >= sellerUpLineWidget.length-1" style="position: relative; width: 100%;"
                                   :style="'margin-left: calc(10px * ' + (i*1) + ');'" class="my-1"
                            >
                                <v-icon color="gold" left small>mdi-arrow-up-left</v-icon>
                                <span :class="getSellerClass">
                                        <v-icon left dark>mdi-account-tie</v-icon>
                                        {{ getSellerFullname }}
                                    </span>
                            </v-row>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-row>
</template>

<script>
import {Utils} from "@/services/utils";

export default {
    name: "CareerWidget",
    props: {
        seller_data: Object,
        can_see: Boolean
    },
    computed: {
        sellerUpLineWidget() {
            return this.$store.getters.getSellerUpLineWidget;
        },
        getStatus() {
            if (this.seller_data.status.active) {
                if (this.seller_data.status.activeDays !== -1) {
                    return this.$t('sellers.dialog.active') + " (" + this.seller_data.status.activeDaysCurrent + ")"
                } else {
                    return this.$t('sellers.dialog.active')
                }
            } else {
                return this.$t('sellers.dialog.inactive')
            }
        },
        getBonusPoints() {
            if (this.seller_data.quickStartDays > 0) {
                let dayKey
                if (this.seller_data.quickStartDays === 1) {
                    dayKey = "day";
                } else if (this.seller_data.quickStartDays > 1 && this.seller_data.quickStartDays < 5) {
                    dayKey = "days_" + this.seller_data.quickStartDays;
                } else {
                    dayKey = "days";
                }
                return this.seller_data.status.bonusCareerPoints + " (" + this.seller_data.quickStartDays + " " + Utils.localizeText("seller.days." + dayKey) + ")"
            } else {
                return "0"
            }
        },
        showNextPosition() {
            return this.seller_data.position !== "P7" && this.seller_data.position !== "GD" && this.seller_data.position !== "CD" && this.seller_data.position !== "RGGP" && this.seller_data.position !== "RGGC";
        },
        dark() {
            return this.$vuetify.theme.dark;
        },

        getSellerFullname() {
            let data = this.$store.getters.getSelectedSeller;
            let fullname = '';
            let company = data.companyName === '' ? '' : ' - ' + data.companyName;
            fullname = data.name + ' ' + data.surname + company + ' • (' + this.$t('seller.position.' + this.seller_data.position) + ')';
            return fullname;
        },

        getSellerClass() {
            if (!this.seller_data.active) {
                return "grey rounded white--text py-1 px-8 pl-4 font-weight-bold"
            } else {
                if (this.seller_data.status.active) {
                    return "green rounded white--text py-1 px-8 pl-4 font-weight-bold"
                } else {
                    return "red rounded white--text py-1 px-8 pl-4 font-weight-bold"
                }
            }
        }
    },
    methods: {
        progressCalculate(_required, _missing) {
            return Math.abs(((_missing / _required) * 100) - 100);
        },

        getUplineClass(uplineSeller) {
            if (uplineSeller.disabled) {
                return "grey rounded white--text my-1 px-3"
            } else {
                return "gold rounded white--text my-1 px-3"
            }
        }
    },

    created() {
        this.$store.dispatch("getSellerUpLineWidget", this.$store.getters.getSelectedSeller.id);
    },

    mounted() {
    },
    destroyed() {
        this.$store.dispatch('clearSellerUpLineWidget');
    }
}
</script>