import ApiService from "@/services/api_service";

export function getProfileImage() {
    return ApiService.getData("/user-profile/image")
}

export function getBossProfileImage() {
    return ApiService.getData("/user-profile/boss-image")
}

export function getBossContact() {
    return ApiService.get("/user-profile/boss-contact")
}

export function getSellerInfo() {
    return ApiService.get("/user-profile/seller-info")
}

export function acceptGDPR() {
    return ApiService.post("/user-profile/accept-gdpr")
}

export function uploadProfileImage(form_data) {
    return ApiService.upload('/user-profile/image', form_data)
}