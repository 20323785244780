var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float-left"},[(!_vm.item.hasOwnProperty('method'))?_c('v-dialog',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 px-2",attrs:{"small":"","depressed":"","dark":"","color":"gold lighten-1","height":"22"},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attr,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({staticClass:"px-3",attrs:{"align":"center"}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.item.icon))]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.name + _vm.action_buttons + _vm.item.label))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$route.name + _vm.action_buttons + _vm.item.label + '_tooltip')))])])],1)]}}],null,false,2353383149),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'v-dialog',_vm.dialog_settings,false),[(_vm.dialog)?_c('v-card',[_c('v-toolbar',{staticStyle:{"z-index":"10","flex":"unset"},attrs:{"fixed":"","dark":"","tile":"","dense":"","color":"gold","height":"48"}},[_c('v-btn',{attrs:{"plain":"","icon":"","dark":""},on:{"click":function($event){return _vm.close('toolbar')}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-toolbar-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t('toolbar_title_new.' + _vm.item.label)))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('rgg-table-dialog',{attrs:{"component":_vm.item.dialog,"type":_vm.item.state,"options":_vm.item.options},on:{"call":_vm.callback,"action_dialog":_vm.callback}})],1)],1):_vm._e()],1):_c('v-btn',{staticClass:"mr-1 px-2",attrs:{"small":"","depressed":"","dark":"","color":"gold lighten-1","height":"22"},on:{"click":function($event){return _vm.action(_vm.item.method)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({staticClass:"px-3",attrs:{"align":"center"}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.item.icon))]),(_vm.$vuetify.breakpoint.width > 450)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.name + _vm.action_buttons + _vm.item.label))+" ")]):_c('span',{staticClass:"caption",staticStyle:{"font-size":"0.9em !important"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.name + _vm.action_buttons + _vm.item.label))+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$route.name + _vm.action_buttons + _vm.item.label + '_tooltip')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }