import ApiService from "@/services/api_service";

export function uploadAttachment(form_data) {
    return ApiService.upload('/mail/attachments', form_data)
}

export function initializeMailService(data) {
    return ApiService.post('/mail/initialize', data)
}

export function sendMail(data) {
    return ApiService.post('/mail/send', data)
}

export function getMailSendingProgress() {
    return ApiService.get('/mail/progress')
}

export function cancelMailSending() {
    return ApiService.put('/mail/cancel')
}