<template>
    <v-btn @click="click" depressed color="gold" dark class="px-4 mx-1" v-bind="button_type[type]">
        <v-icon left>mdi-delete</v-icon>
        {{ label }}
    </v-btn>
</template>

<script>
    export default {
        name: "ButtonUI",
        props: {
            label: String,
            click: Function,
            type: String
        },
        data: () => ({
            button_type: {
                delete: {
                    outlined: true
                }
            }
        })
    }
</script>