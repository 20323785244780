import {i18n} from "@/main";
import {router} from '@/router';
import views from "@/views/views";

export default {
    install(Vue) {
        Vue.prototype.g_isDefined = (_key) => {
            if(_key !== '' && _key !== undefined && _key !== null && _key !== "NaN") {
                return _key;
            } else {
                return '- - -';
            }
        }

        Vue.prototype.g_i18_keys = (_key) => {
            return i18n.t( router.app.$route.name + '.table_keys.' + _key );
        }

        Vue.prototype.g_i18_filter = (_key, _option) => {
            return i18n.t( router.app.$route.name + '.toolbar_filter.' + _key, {option: _option === '' ? '' : _option } );
        }

        Vue.prototype.g_cardState = (_state) => {
            if(!_state) {
                return 'state-red';
            }
        }

        Vue.prototype.g_getRouteName = () => {
            return router.app.$route.name;
        }

        Vue.prototype.g_getDate = () => {
            return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        }

        Vue.prototype.g_removeDiacritics = (_str) => {
            return _str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
        }

        Vue.prototype.g_convertDate = (_inputDate) => {
            if(_inputDate !== '' && _inputDate !== undefined && _inputDate !== null) {
                return _inputDate.substring(8, 10) + "." + _inputDate.substring(5, 7) + "." + _inputDate.substring(0, 4);
            } else {
                return '- - -';
            }
        }

        Vue.prototype.g_parseBankAccount = (_number) => {
            if(_number !== '' && _number !== undefined && _number !== null) {

                if( _number.indexOf(" ") !== -1) {
                    _number = _number.replace(/\s+/g, '');
                }

                let result = _number.match(/.{1,4}/g);
                return result.join(' ');
            } else {
                return '____  ____  ____  ____  ____  ____  ____';
            }
        }

        Vue.prototype.g_card_info = (_name) => {
            return views.getDataView(_name);
        }

        Vue.prototype.g_parsePrice = (_price) => {
            if (_price === null || _price === undefined) {
                return "---"
            }
            if( !(/^[0-9]*[.,]?[0-9]{0,2}$/g.test(_price)) && isNaN(_price) ) {
                return null;
            } else {
                let currency = "€";
                let sum = parseFloat(_price).toFixed(2);
                sum = sum.replace(/[.]/g, ',');

                return sum + " " + currency;
            }

        }

        Vue.prototype.g_formatBytes = (_bytes, _decimals = 2) => {
            if (_bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = _decimals < 0 ? 0 : _decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(_bytes) / Math.log(k));

            return parseFloat((_bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        Vue.prototype.g_fileExtension = (_file) => {
            let regex = /\.pdf$|\.jpg$/gi;
            let extension = _file.match(/\.[a-z,0-9]{0,8}$/gi)[0].replace('.','');
            let default_icon = 'mdi-file-question-outline';

            if( regex.test(_file) ) {
                default_icon = 'mdi-file-' + extension + '-box';
            }

            return {
                icon: default_icon,
                type: extension.toUpperCase()
            }
        }

        Vue.prototype.g_dark = (_this) => {
            return _this.$vuetify.theme.dark;
        }

        Vue.prototype.g_theme = (_this) => {
            return _this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        }
    }
}