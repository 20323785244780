import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getGoldPurchaseReports(filter_data, cancel_request) {
    return ApiService.cancellableGet("/purchase/gold-purchase-reports" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getGoldPurchasesForReport(report_id, filter_data, cancel_request) {
    return ApiService.cancellableGet("/purchase/gold-purchase-reports/" + report_id + "/gold-purchases" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function updateGoldPurchasePriceForReport(report_id, gold_purchase_id, data) {
    return ApiService.put("/purchase/gold-purchase-reports/" + report_id + "/gold-purchases/" + gold_purchase_id + "/payment", data)
}

export function getGoldPurchaseReport(report_id) {
    return ApiService.get("/purchase/gold-purchase-reports/" + report_id)
}

export function updateUnitOuncePrice(report_id, data) {
    return ApiService.put("/purchase/gold-purchase-reports/" + report_id + "/unit-ounce-price", data)
}

export function recomputeGoldPurchaseReport(report_id) {
    return ApiService.put("/purchase/gold-purchase-reports/" + report_id + "/recompute")
}