import ApiService from "@/services/api_service";

export function exportAgreements() {
    return ApiService.download("/export?type=agreements", "agreements.csv");
}

export function exportPackages() {
    return ApiService.download("/export?type=packages", "packages.csv");
}

export function exportPersonalWebs() {
    return ApiService.download("/export?type=personal_webs", "personal_webs.csv");
}

export function exportCustomers() {
    return ApiService.download("/export?type=customers", "customers.csv");
}

export function exportSellers() {
    return ApiService.download("/export?type=sellers", "sellers.csv");
}

export function exportGoldDeposit() {
    return ApiService.download("/export?type=gold_deposit", "gold_deposit.csv");
}

export function exportSalaryReport(report_id, report_date) {
    let dateSplit = report_date.split(".")
    return ApiService.download("/export-salaries/" + report_id, "salaries_" + dateSplit[2] + "_" + dateSplit[1] + "_" + dateSplit[0] + ".csv");
}