<template>

    <div class="navigation">
        <v-list class="gold--text" align="center">
            <v-list-item class="px-0" dense v-for="link in filteredLinks" :key="link.title" router :to="link.route" >
                <v-list-item-content v-if="!link.separator">
                    <v-icon regular color="cx-drawer-text">{{ link.icon }}</v-icon>
                    <v-list-item-title class="cx-drawer-text--text" style="white-space: unset; font-size: 11px;">{{ $t(link.title) }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content class="separator" v-else>
                    <v-divider class="section-divider " color="white"></v-divider>
                </v-list-item-content>
            </v-list-item>
        </v-list>

    </div>

</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";

export default {
    name: "Navigation",
    props: {
        links: Array,
        filter: Boolean,
    },
    mixins: [AuthMixin],
    data: () => ({}),
    computed: {
        filteredLinks() {
            if (this.filter === true) {
                let filteredLinks = []
                for (let i = 0; i < this.links.length; i++) {
                    if (this.links[i].permission === undefined || this.checkPermission(this.links[i].permission)) {
                        if (this.links[i].separator === undefined || (filteredLinks.length > 0 && filteredLinks[filteredLinks.length - 1].separator === undefined)) {
                            filteredLinks.push(this.links[i])
                        }
                    }
                }

                if (filteredLinks[0].separator !== undefined) {
                    filteredLinks.splice(0, 1)
                }

                if (filteredLinks[filteredLinks.length - 1].separator !== undefined) {
                    filteredLinks.splice(filteredLinks.length - 1, 1)
                }

                return filteredLinks
            } else {
                return this.links
            }
        }
    },
    methods: {
        hasPermission(link) {
            return this.checkPermission(link.permission)
        }
    }
}
</script>

<style>
.navigation .v-list-item--active:not(.theme--dark) {
    background-color: rgba(255, 255, 255, 0.3);
}

.navigation .section-icon,
.navigation .section-divider {
    opacity: 0.2;
}
</style>