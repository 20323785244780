<template>
    <v-container v-if="isAuthenticated()" class="pa-0" fluid>
        <v-row align="center" class="cl-title white elevation-6" no-gutters :class="g_theme(this)">
            <span class="headline gold--text text-uppercase font-weight-light pa-2">
                {{ $t('title.emails') }}
            </span>

            <!--TABS-->
            <template>
                <v-tabs
                    fixed-tabs height="26"
                    color="gold" background-color="#0000000F"
                    active-class="gold lighten-1 white--text"
                    style="flex: unset; border-top: 1px solid rgba(0,0,0,0.1);"
                >
                    <v-tab @click="recipient = 'All'; validatorRefresh()" class="caption font-weight-bold">
                        <v-icon left small>mdi-account-multiple</v-icon>
                        {{ $t('emails.all') }}
                    </v-tab>
                    <v-tab @click="recipient = 'Sellers'; validatorRefresh()" class="caption font-weight-bold">
                        <v-icon left small>mdi-account-tie</v-icon>
                        {{ $t('emails.sellers') }}
                    </v-tab>
                    <v-tab @click="recipient = 'Customers'; validatorRefresh()" class="caption font-weight-bold">
                        <v-icon left small>mdi-account-circle</v-icon>
                        {{ $t('emails.customers') }}
                    </v-tab>
                    <v-tab @click="recipient = 'Users'; validatorRefresh()" class="caption font-weight-bold">
                        <v-icon left small>mdi-account</v-icon>
                        {{ $t('emails.email') }}
                    </v-tab>
                    <v-tab @click="recipient = 'Test'; validatorRefresh()" class="caption font-weight-bold">
                        <v-icon left small>mdi-test-tube</v-icon>
                        {{ $t('emails.test') }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-row>

        <v-container class="pt-8">
            <v-form @submit.prevent="submit" id="form" ref="form">
                <!--USER-->
                <v-row v-if="recipient === 'Users'" no-gutters>
                    <v-col cols="1">
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn small depressed color="gold" dark @click="dialog_users = true" v-on="on">
                                    <v-icon small>mdi-email-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('tooltip.users_emails') }}</span>
                        </v-tooltip>


                        <v-dialog v-model="dialog_users" scrollable max-width="1050"
                                  transition="dialog-bottom-transition">
                            <v-card min-height="600">
                                <v-toolbar color="gold" dark dense style="flex: unset;">
                                    <v-btn icon @click="dialog_users = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Výber používateľa</v-toolbar-title>
                                </v-toolbar>

                                <v-card-text class="px-0">
                                    <DataSelect v-if="dialog_users" :data_view_name="'users_email_list_select'"/>
                                </v-card-text>
                            </v-card>

                        </v-dialog>
                    </v-col>
                    <v-col cols="11" class="pa-0 pb-1 grey lighten-4 rounded"
                           :class="{'darken-4' : $vuetify.theme.dark}">
                        <v-chip v-for="(mail,i) in users_mails" :key="i"
                                class="px-4 ml-1 mt-1" small :class="{'user-inactive' : !mail.item.active}"
                                color="gold" dark
                                label close @click:close="clearUserEmail(i)"
                        >
                            <v-icon left small v-if="mail.item.type === 'Customer'">mdi-account-circle</v-icon>
                            <v-icon left small v-else-if="mail.item.type === 'Seller'">mdi-account-tie</v-icon>
                            <v-icon left small v-else-if="mail.item.type === 'Administrator'">mdi-shield-account
                            </v-icon>
                            <v-icon left small v-else>mdi-face-agent</v-icon>
                            <span>{{ mail.item.fullname }}</span>
                        </v-chip>
                    </v-col>

                </v-row>
                <v-alert v-if="recipient === 'Users' && users_mails.length === 0"
                         dense text type="info" class="mt-2" border="left" color="gold"
                >
                    {{ $t('emails.send_disabled') }}
                </v-alert>

                <!--SUBJECT-->
                <v-row>
                    <v-col class="pt-0">
                        <v-text-field v-model="subject"
                                      class="pb-4" color="gold"
                                      :label="$t('emails.subject')"
                                      hide-details="auto"

                                      v-validate="'required'"
                                      data-vv-name="mail_subject"
                                      :error-messages="errors.collect('mail_subject')"
                        />
                    </v-col>
                </v-row>

                <!--EDITOR-->
                <Tiptap v-model="content"/>

                <!--FILES-->
                <v-row>
                    <v-col cols="12">
                        <template>
                            <v-file-input
                                v-model="files"
                                color="gold"
                                :label="$t('emails.attachments')"
                                multiple
                                prepend-icon="mdi-paperclip"
                                outlined
                                dense
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip
                                        color="gold"
                                        dark
                                        label
                                        small
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </template>
                    </v-col>
                </v-row>

                <!--SENDING-->
                <v-row>
                    <v-col cols="12" align="center">
                        <v-btn depressed dark color="gold"
                               v-if="recipient !== 'Users' && content !== '' && subject !== ''"
                               @click="submit(recipient)">
                            <v-icon left>mdi-email-fast</v-icon>
                            {{ $t('emails.send') }}
                        </v-btn>
                        <v-btn depressed dark color="gold"
                               v-else-if="recipient === 'Users' && users_mails.length  && content !== '' && subject !== ''"
                               @click="submit('Users')">
                            <v-icon left>mdi-email-fast</v-icon>
                            {{ $t('emails.send') }}
                        </v-btn>
                        <v-btn disabled v-else>
                            <v-icon left>mdi-email-fast</v-icon>
                            {{ $t('emails.send') }}
                        </v-btn>

                        <v-dialog v-model="dialog_send" max-width="500" persistent>
                            <v-card>
                                <v-toolbar dense color="gold" dark>
                                    <v-toolbar-title>
                                        <v-icon left>mdi-email-fast</v-icon>
                                        {{ $t('emails.sending') }}
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" align="center" class="mt-3">
                                            {{ progress_current }} {{ $t('emails.zet') }} {{ progress_total }}
                                        </v-col>
                                        <v-col cols="12" class="pt-0">
                                            <v-progress-linear
                                                color="light-blue"
                                                height="12"
                                                :value="(100 / progress_total) * progress_current"
                                                striped
                                            ></v-progress-linear>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="send_cancel">{{ $t('common.cancel') }}</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-form>

        </v-container>

    </v-container>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Tiptap from "@/components/Tiptap";
import DataSelect from "@/components/DataSelect";
import {cancelMailSending, getMailSendingProgress, initializeMailService, sendMail, uploadAttachment} from "@/api/mail";

export default {
    name: "MailView",
    components: {
        Tiptap,
        DataSelect
    },
    mixins: [AuthMixin],
    data: () => ({
        dialog_users: false,
        dialog_send: false,
        is_sending: false,
        recipient: 'All',
        subject: '',
        content: '',
        files: [],
        users_mails: [],
        progress_current: 0,
        progress_total: 0,
        progress_finished: null,
        interval: 0
    }),
    created() {
        this.$validator.extend('editor', {
            validate: (value) => {
                return value !== '' || !/<p><\/p>/.test(value);
            }
        });
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        },
        getUsersEmails() {
            return this.users_mails;
        }
    },
    watch: {
        progress_finished: function (val) {
            if (val === false) {
                this.get_processing();
            } else {
                clearInterval(this.interval);
                this.dialog_send = false;

                if (!this.is_sending) {
                    this.$root.$emit('notification', {
                        type: 'info',
                        icon: 'info',
                        msg: 'notification.emails_canceled'
                    });
                } else {
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'notification.emails_sent'
                    });
                    this.is_sending = false;
                }

            }
        }
    },
    methods: {
        clearUserEmail(_index) {
            this.users_mails.splice(_index, 1);
        },

        removeDuplicates() {
            let uniqe = Array.from(new Set(this.users_mails.map(a => a.id)))
                .map(id => {
                    return this.users_mails.find(a => a.id === id)
                });

            this.users_mails = uniqe;
        },

        send(_recipient) {

            let userIds = []
            if (_recipient === 'Users') {
                for (let i = 0; i < this.users_mails.length; i++) {
                    userIds.push(this.users_mails[i].id)
                }
            }

            let mailForm = {
                recipientType: _recipient,
                title: this.subject,
                body: this.content,
                users: userIds
            }

            sendMail(mailForm).then(() => {
                getMailSendingProgress().then(response => {
                    this.progress_current = response.data.current
                    this.progress_total = response.data.total
                    this.progress_finished = response.data.finished;
                    this.$root.$emit('overlay', false);
                    this.dialog_send = true
                    this.is_sending = true
                })
            })
        },

        get_processing() {
            clearInterval(this.interval);

            this.interval = setInterval(() => {
                getMailSendingProgress().then(response => {
                    this.progress_current = response.data.current;
                    this.progress_total = response.data.total;
                    this.progress_finished = response.data.finished;
                })
            }, 1000);
        },

        send_cancel() {
            cancelMailSending().then(() => {
                this.dialog_send = false
                this.is_sending = false
            })
        },

        uploadAttachments(index, _recipient) {
            if (this.files.length === index) {
                this.send(_recipient);
            } else {
                let formData = new FormData()
                formData.append('file', this.files[index])
                uploadAttachment(formData).then(() => {
                    this.uploadAttachments(index + 1, _recipient)
                })
            }
        },

        submit(_recipient) {
            this.$validator.validateAll().then(() => {
                if (!this.$validator.errors.any()) {
                    this.$root.$emit('overlay', true);
                    initializeMailService().then(() => {
                        this.uploadAttachments(0, _recipient)
                    })
                } else {
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            });
        },

        validatorRefresh() {

            //let vee = this.$validator;
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        }
    },
    mounted() {
        this.$root.$on('item-email', (_id, _item) => {
            let tag = {};
            tag.fullname = _item.fullname;
            tag.type = _item.raw_data.type;
            tag.active = _item.active;

            this.users_mails.push({id: _id, item: tag});
            this.removeDuplicates();
            this.dialog_users = false;
        });
    },
    destroyed() {
        this.$root.$off('item-email');
    }
}
</script>

<style>
div.container div span.user-inactive {
    border-left: 4px solid red !important;
}
</style>