import {getAgreement, getAgreements, getGoldPurchasesForAgreement} from "@/api/agreements";
import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getAgreementsForPackage} from "@/api/packages";
import {getCommissionAgreementsForSalary, getRentAgreementsForSalary} from "@/api/salaries";
import {getAgreementsForReportRecord} from "@/api/production";

const state = {
    agreements_cancel_request: new CancelRequest(),
    agreements: {total_count: 0, items: []},
    agreement: {},
    transfer_agreement: {},
    gold_purchases: {total_count: 0, items: []}
};

const actions = {
    getAgreements(context, data) {
        context.state.agreements_cancel_request.cancelAndCreateToken()
        return getAgreements(data, context.state.agreements_cancel_request)
            .then(response => {
                context.state.agreements_cancel_request.resetCancelToken()
                context.commit('setAgreements', response.data);
            })
    },
    getAgreementsForPackage(context, id) {
        return getAgreementsForPackage(id)
            .then(response => {
                context.commit('setAgreements', response.data);
            })
    },
    getCommissionAgreementsForSalary(context, data) {
        context.state.agreements_cancel_request.cancelAndCreateToken()
        return getCommissionAgreementsForSalary(data.salary_id, data.filter_data, context.state.agreements_cancel_request)
            .then(response => {
                context.state.agreements_cancel_request.resetCancelToken()
                context.commit('setAgreements', response.data);
            })
    },
    getRentAgreementsForSalary(context, data) {
        context.state.agreements_cancel_request.cancelAndCreateToken()
        return getRentAgreementsForSalary(data.salary_id, data.filter_data, context.state.agreements_cancel_request)
            .then(response => {
                context.state.agreements_cancel_request.resetCancelToken()
                context.commit('setAgreements', response.data);
            })
    },
    getAgreementsForReportRecord(context, data) {
        context.state.agreements_cancel_request.cancelAndCreateToken()
        return getAgreementsForReportRecord(data.record_id, data.filter_data, context.state.agreements_cancel_request)
            .then(response => {
                context.state.agreements_cancel_request.resetCancelToken()
                context.commit('setAgreements', response.data);
            })
    },
    getAgreement(context, id) {
        return getAgreement(id)
            .then(response => {
                context.commit('setAgreement', response.data);
            })
    },
    getGoldPurchases(context, id) {
        return getGoldPurchasesForAgreement(id)
            .then(response => {
                context.commit('setGoldPurchases', response.data);
            })
    },
    getTransferAgreement(context, id) {
        return getAgreement(id)
            .then(response => {
                context.commit('setTransferAgreement', response.data);
            })
    },

    clearAgreements(context) {
        context.commit('clearAgreements');
    },
    clearAgreement(context) {
        context.commit('clearAgreement');
    },
    clearTransferAgreement(context) {
        context.commit('clearTransferAgreement');
    },
    clearGoldPurchases(context) {
        context.commit('clearGoldPurchases');
    }
};

const mutations = {
    setAgreements(state, agreements_response) {
        state.agreements.total_count = agreements_response.totalCount;
        state.agreements.items = [];
        agreements_response.items.forEach(function (agreement) {
            let status
            let card_state = ""
            if (agreement.inactive === true) {
                status = Utils.localizeText("products_agreements.status.inactive")
                card_state = "state-red"
            } else {
                if (agreement.bonus === true) {
                    status = Utils.localizeText("products_agreements.status.bonus")
                } else if (agreement.converted === true) {
                    status = Utils.localizeText("products_agreements.status.converted")
                } else if (agreement.packageType !== null) {
                    status = Utils.localizeText("packages." + agreement.packageType)
                } else if (agreement.paymentFree === true) {
                    status = Utils.localizeText("products_agreements.status.payment_free")
                } else {
                    if (agreement.status === 'CREATED') {
                        let dayKey
                        if (agreement.daysRemaining === 1) {
                            dayKey = "day";
                        } else if (agreement.daysRemaining > 1 && agreement.daysRemaining < 5) {
                            dayKey = "days_" + agreement.daysRemaining;
                        } else {
                            dayKey = "days";
                        }
                        status = Utils.localizeText("products_agreements.status.not_paid") +
                            " (-" + Utils.parsePrice(agreement.missingPayment) + ") (" + agreement.daysRemaining + " " + Utils.localizeText("products_agreements.days." + dayKey) + ")"
                        card_state = "state-orange"
                    } else {
                        status = Utils.localizeText("products_agreements.status.paid")
                        card_state = "state-green"
                    }
                }
            }

            let coins = ''
            if (agreement.product.productType !== 'ProductGoldT3') {
                coins = agreement.coinsAmount + " + " + Utils.parseValue(agreement.coinsRemainder) + " g"
                if (agreement.coinsAmount > 0) {
                    coins += " (" + agreement.coinsAtCustomer + " " + Utils.localizeText("products_agreements.coins_at_customer") + ")"
                }
            }

            let salary_amount = ""
            if (agreement.salaryAmount !== undefined) {
                salary_amount = Utils.parsePrice(agreement.salaryAmount);
            }

            let bonusPayments =  ""
            let payments_bonus = "- - -"
            if (agreement.bonusPayment !== null && agreement.bonusPayment !== undefined) {
                let bonus = Utils.parsePrice(agreement.bonusPayment)
                if (!bonus.startsWith('0,00')) {
                    bonusPayments = " + " + bonus
                    payments_bonus = bonus
                }
            }

            let payments = ""
            if (agreement.product.productType !== 'ProductGoldT3') {
                payments = Utils.parsePrice(agreement.currentYearPayment) + "(" + Utils.parsePrice(agreement.nettTotalPayment) + bonusPayments + ")"
            } else {
                payments = Utils.parsePrice(agreement.totalPayment)
            }

            state.agreements.items.push({
                state: card_state,
                date: Utils.convertDate(agreement.signatureDate),
                id: agreement.id,
                product: Utils.localizeText("products_agreements.action_buttons." + agreement.product.productType.toLowerCase()),
                target_price: Utils.parsePrice(agreement.product.productTargetPrice),
                monthly_payment: agreement.product.monthlyPayment !== 0 ? Utils.parsePrice(agreement.product.monthlyPayment) : '- - -',
                points: agreement.doublePoints && agreement.product.productType !== 'ProductGoldT3' ? Utils.parseValue(agreement.points) + " + " + Utils.parseValue(agreement.points) : Utils.parseValue(agreement.points),
                customer: Utils.getFullName(agreement.customer),
                seller: Utils.getFullNameWithPosition(agreement.seller),
                status: status,
                payments: payments,
                payments_total: Utils.parsePrice(agreement.nettTotalPayment),
                payments_bonus: payments_bonus,
                grams: agreement.bonusGoldAmount !== 0 ? Utils.parseValue(agreement.totalGoldAmount) + " g" : Utils.parseValue(agreement.goldAmount) + " g",
                grams_regular: Utils.parseValue(agreement.goldAmount) + " g",
                grams_total: Utils.parseValue(agreement.totalGoldAmount) + " g",
                coins: coins,
                salary_amount: salary_amount,
                raw_data: {
                    product_type: agreement.product.productType.toLowerCase(),
                    seller_id: agreement.seller.id,
                    customer_id: agreement.customer.id,
                    grams_total: agreement.totalGoldAmount
                }
            })
        })
    },
    setAgreement(state, agreement_response) {
        state.agreement = {};
        let response = agreement_response;
        let productSelectionDataRewrite = [];

        if (response.product.productType === 'ProductGoldT3') {
            let product_selection = response.product.productSelection;
            for (const item in product_selection) {
                let exp = /Price/g;
                if (!exp.test(item.toString())) {
                    productSelectionDataRewrite.push({
                        name: Utils.localizeText("products." + item.toString()),
                        count: product_selection[item],
                    });
                }
            }
            let count = 0;
            for (const price in product_selection) {
                let exp = /Price/g;
                if (exp.test(price.toString())) {
                    productSelectionDataRewrite[count++].price = product_selection[price];
                }
            }
        }
        response.product.productSelection = productSelectionDataRewrite;

        state.agreement = response;
    },
    setTransferAgreement(state, agreement_response) {
        state.transfer_agreement = {};
        let response = agreement_response;
        let productSelectionDataRewrite = [];

        if (response.product.productType === 'ProductGoldT3') {
            let product_selection = response.product.productSelection;
            for (const item in product_selection) {
                let exp = /Price/g;
                if (!exp.test(item.toString())) {
                    productSelectionDataRewrite.push({
                        name: Utils.localizeText("products." + item.toString()),
                        count: product_selection[item],
                    });
                }
            }
            let count = 0;
            for (const price in product_selection) {
                let exp = /Price/g;
                if (exp.test(price.toString())) {
                    productSelectionDataRewrite[count++].price = product_selection[price];
                }
            }
        }
        response.product.productSelection = productSelectionDataRewrite;

        state.transfer_agreement = response;
    },
    setGoldPurchases(state, response) {
        state.gold_purchases.total_count = response.totalCount;
        state.gold_purchases.items = [];
        response.items.forEach(function (gold_purchase) {
            let transaction
            if (gold_purchase.bonus) {
                transaction = Utils.localizeText("products_agreements.gold_purchase.bonus")
            } else {
                if (gold_purchase.goldAmount.toString().startsWith("-")) {
                    transaction = Utils.localizeText("products_agreements.gold_purchase.repurchase")
                } else {
                    transaction = Utils.localizeText("products_agreements.gold_purchase.regular")
                }
            }
            let unitType
            if (gold_purchase.type === 'SAVING_1G') {
                unitType = Utils.localizeText("products_agreements.gold_purchase.grams")
            } else {
                unitType = Utils.localizeText("products_agreements.gold_purchase.pieces")
            }
            let payment = Utils.parsePrice(gold_purchase.payment)
            if (gold_purchase.bonusPayment !== null && gold_purchase.bonusPayment !== undefined) {
                let bonusPayment = Utils.parsePrice(gold_purchase.bonusPayment)
                payment = payment + " + " + bonusPayment
            }
            state.gold_purchases.items.push({
                id: gold_purchase.id,
                date: Utils.convertDate(gold_purchase.purchaseDate),
                transaction: transaction,
                type: Utils.localizeText("products_agreements.gold_type." + gold_purchase.type.toLowerCase()),
                payment: payment,
                goldAmount: gold_purchase.goldAmount + " " + unitType,
            })
        })
    },

    clearAgreements(state) {
        state.agreements = {total_count: 0, items: []};
    },
    clearAgreement(state) {
        state.agreement = {};
    },
    clearTransferAgreement(state) {
        state.transfer_agreement = {};
    },
    clearGoldPurchases(state) {
        state.gold_purchases = {total_count: 0, items: []};
    }
};

const getters = {

    getAgreements(state) {
        return state.agreements;
    },
    getCommissionAgreementsForSalary(state) {
        return state.agreements;
    },
    getRentAgreementsForSalary(state) {
        return state.agreements;
    },
    getAgreement(state) {
        return state.agreement;
    },
    getTransferAgreement(state) {
        return state.transfer_agreement;
    },
    getGoldPurchases(state) {
        return state.gold_purchases
    },
    getAgreementsForReportRecord(state) {
        return state.agreements;
    }
};

export const agreements = {
    state,
    actions,
    mutations,
    getters
};