import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getPackages} from "@/api/packages";
import {getCommissionPackagesForSalary} from "@/api/salaries";
import {getPackagesForReportRecord} from "@/api/production";

const state = {
    packages_cancel_request: new CancelRequest(),
    packages: {total_count: 0, items: []},
};

const actions = {
    getPackages(context, data) {
        context.state.packages_cancel_request.cancelAndCreateToken();
        return getPackages(data, context.state.packages_cancel_request)
            .then(response => {
                context.state.packages_cancel_request.resetCancelToken();
                context.commit('setPackages', response.data);
            })
    },

    getCommissionPackagesForSalary(context, data) {
        context.state.packages_cancel_request.cancelAndCreateToken();
        return getCommissionPackagesForSalary(data.salary_id, data.filter_data, context.state.packages_cancel_request)
            .then(response => {
                context.state.packages_cancel_request.resetCancelToken();
                context.commit('setPackages', response.data);
            })
    },

    getPackagesForReportRecord(context, data) {
        context.state.packages_cancel_request.cancelAndCreateToken();
        return getPackagesForReportRecord(data.record_id, data.filter_data, context.state.packages_cancel_request)
            .then(response => {
                context.state.packages_cancel_request.resetCancelToken();
                context.commit('setPackages', response.data);
            })
    },

    clearPackages(context) {
        context.commit('clearPackages');
    }
};

const mutations = {
    setPackages(state, response) {
        state.packages.total_count = response.totalCount
        state.packages.items = []
        response.items.forEach(function (sellerPackage) {
            let paid
            if (sellerPackage.inactive === true) {
                paid = Utils.localizeText("products_packages.cancelled");
            } else {
                paid = Utils.parsePrice(sellerPackage.payment)
            }

            let limit = Utils.parsePrice(sellerPackage.purchaseBonus)
            if (sellerPackage.type === "Silver") {
                limit = "-"
            } else if (sellerPackage.type === "Business") {
                limit = Utils.localizeText("products_packages.unlimited")
            }

            let card_state = "";
            if (sellerPackage.inactive === true) {
                card_state = "state-red"
            } else if (sellerPackage.payment) {
                card_state = "state-green"
            } else if (sellerPackage.payment === 0) {
                card_state = "state-orange"
            }

            let salary_amount = ""
            if (sellerPackage.salaryAmount !== undefined) {
                salary_amount = Utils.parsePrice(sellerPackage.salaryAmount);
            }

            state.packages.items.push({
                id: sellerPackage.id,
                date: Utils.convertDate(sellerPackage.purchaseDate),
                active: sellerPackage.active,
                inactive: sellerPackage.inactive,
                seller: Utils.getFullNameWithPosition(sellerPackage.owner),
                type: Utils.localizeText("products_packages.type." + sellerPackage.type),
                paid: paid,
                agreement_limit: Utils.parsePrice(sellerPackage.agreementLimit),
                agreement_count: sellerPackage.agreementCount,
                purchase_limit: limit,
                points: sellerPackage.careerPoints,
                state: card_state,
                salary_amount: salary_amount
            });
        });
    },

    clearPackages(state) {
        state.packages = {total_count: 0, items: []};
    }
};

const getters = {
    getPackages(state) {
        return state.packages;
    },

    getCommissionPackagesForSalary(state) {
        return state.packages;
    },

    getPackagesForReportRecord(state) {
        return state.packages;
    }
};

export const packages = {
    state,
    actions,
    mutations,
    getters
};