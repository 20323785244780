import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getGoldPurchaseReport, getGoldPurchaseReports, getGoldPurchasesForReport} from "@/api/purchase";

const state = {
    gold_purchase_reports_cancel_request: new CancelRequest(),
    gold_purchase_reports: {total_count: 0, items: []},
    gold_purchase_report: {},
    gold_purchases_for_report_cancel_request: new CancelRequest(),
    gold_purchases_for_report: {total_count: 0, items: []},
    message: {},
    report_id: ''
};

const actions = {
    getGoldPurchaseReports(context, data) {
        context.state.gold_purchase_reports_cancel_request.cancelAndCreateToken();
        return getGoldPurchaseReports(data, context.state.gold_purchase_reports_cancel_request)
            .then(response => {
                context.state.gold_purchase_reports_cancel_request.resetCancelToken();
                context.commit('setGoldPurchaseReports', response.data);
            }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
    },

    getGoldPurchasesForReport(context, data) {
        context.state.report_id = '';
        context.state.gold_purchases_for_report_cancel_request.cancelAndCreateToken();
        return getGoldPurchasesForReport(data.report_id, data.filter_data, context.state.gold_purchases_for_report_cancel_request)
            .then(response => {
                context.state.report_id = data.report_id;
                context.state.gold_purchases_for_report_cancel_request.resetCancelToken();
                context.commit('setGoldPurchasesForReport', response.data);
            }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
    },

    getGoldPurchaseReport(context, report_id) {
        return getGoldPurchaseReport(report_id)
            .then(response => {
                context.commit('setGoldPurchaseReport', response.data);
            }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
    },

    clearGoldPurchaseReports(context) {
        context.commit('clearGoldPurchaseReports');
    },

    clearGoldPurchasesForReport(context) {
        context.commit('clearGoldPurchasesForReport');
    }
};

const mutations = {
    setGoldPurchaseReports(state, response) {
        state.gold_purchase_reports.total_count = response.totalCount
        state.gold_purchase_reports.items = []
        response.items.forEach(function (report) {
            state.gold_purchase_reports.items.push({
                id: report.id,
                date: Utils.convertDate(report.reportDate),
                savings: Utils.parseValue(report.totalGoldAmount) + " g",
                payments: Utils.parsePrice(report.totalPaymentsAll),
                purchase_price: Utils.parsePrice(report.totalPriceAll),
                advance_payments: Utils.parsePrice(report.totalBalanceCharge),
                remaining: Utils.parsePrice(report.companyAmount),
                total_remaining: Utils.parsePrice(report.companyAmountTotal),
                historical_remaining: Utils.parsePrice(report.companyAmountGrandTotal)
            });
        });
    },

    setGoldPurchasesForReport(state, response) {
        state.gold_purchases_for_report.total_count = response.totalCount
        state.gold_purchases_for_report.items = []
        response.items.forEach(function (gold_purchase) {
            let payment = Utils.parsePrice(gold_purchase.payment)
            if (gold_purchase.bonusPayment !== null && gold_purchase.bonusPayment !== undefined) {
                let bonusPayment = Utils.parsePrice(gold_purchase.bonusPayment)
                payment = payment + " + " + bonusPayment
            }
            state.gold_purchases_for_report.items.push({
                id: gold_purchase.id,
                agreement_id: gold_purchase.agreementId,
                goldAmount: Utils.parseValue(gold_purchase.goldAmount) + " g",
                type: Utils.localizeText("products." + gold_purchase.type),
                payment: payment,
                customer: Utils.getFullName(gold_purchase.customer),
                raw_data: {
                    report_id: state.report_id,
                    id: gold_purchase.id,
                    customer: Utils.getFullName(gold_purchase.customer),
                    agreementId: gold_purchase.agreementId,
                    goldAmount: gold_purchase.goldAmount,
                    type: gold_purchase.type,
                    payment: gold_purchase.payment,
                }
            });
        });
    },

    setGoldPurchaseReport(state, response) {
        state.gold_purchase_report = response
    },

    clearGoldPurchaseReports(state) {
        state.gold_purchase_reports = {total_count: 0, items: []};
    },

    clearGoldPurchasesForReport(state) {
        state.gold_purchases_for_report = {total_count: 0, items: []};
    }
};

const getters = {
    getGoldPurchaseReports(state) {
        return state.gold_purchase_reports;
    },

    getGoldPurchaseReport(state) {
        return state.gold_purchase_report;
    },

    getGoldPurchasesForReport(state) {
        return state.gold_purchases_for_report;
    }
};

export const purchase = {
    state,
    actions,
    mutations,
    getters
};