<template>
    <v-container v-if="isAuthenticated()" class="pa-0" fluid>
        <v-row align="center" class="cl-title elevation-6" no-gutters :class="g_theme(this)">
            <span class="headline gold--text text-uppercase font-weight-light pa-2">
                {{ getTitle }}
            </span>
        </v-row>

        <!--TABS-->
        <template v-if="false">
            <v-tabs
                fixed-tabs height="32"
                color="gold" background-color="#0000000F"
                active-class="gold lighten-1 white--text" style="flex: unset;"
            >
                <v-tab @click="recipient = 'All'">
                    <v-icon left small>mdi-home</v-icon>
                    <span class="caption">{{ $t('content_management.home.administrators') }}</span>
                </v-tab>
                <v-tab @click="recipient = 'Sellers'">
                    <v-icon left small>mdi-home</v-icon>
                    <span class="caption">{{ $t('content_management.home.sellers') }}</span>
                </v-tab>
                <v-tab @click="recipient = 'Customers'">
                    <v-icon left small>mdi-home</v-icon>
                    <span class="caption">{{ $t('content_management.home.customers') }}</span>
                </v-tab>
                <v-tab @click="recipient = 'Users'">
                    <v-icon left small>mdi-home</v-icon>
                    <span class="caption">{{ $t('content_management.home.service_center') }}</span>
                </v-tab>
            </v-tabs>
        </template>

        <v-row class="px-3 pt-2 pb-4" no-gutters>
            <!--{{getContent}}-->
            <div v-for="(item,index) in getContent" :key="item.id" style="width: calc(100% / 1);">

                <!--DOKUMENTY-->
                <div v-if="item.type === 'File'" class="content-file">
                    <v-row no-gutters class="document-row px-2 pr-1 py-0">
                        <v-col cols="11">
                            <a :href="getData(item)" target="_blank" :download="item.file" class="item gold--text" style="text-decoration: none;">
                                <v-icon left small class="gold--text pb-1">mdi-cloud-download-outline</v-icon>
                                <span class="">{{ item.title }}</span>
                            </a>
                        </v-col>
                        <v-col cols="1" align="right">
                            <div style="text-align: center; display: table;"
                                 class="caption font-weight-medium gold white--text rounded px-2 py-0">
                                {{ g_fileExtension(item.file).type }}
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <!--VIDEA-->
                <div v-if="item.type === 'Video'" class="content-video">
                    <v-card class="mt-3 grey lighten-4" flat :class="theme">
                        <v-card-text>
                            <v-row class="pb-6">
                                <v-col cols="12" class="pb-0">{{ item.title }}</v-col>
                                <v-col cols="12" align="center">
                                    <iframe :id="'video' + index" type="text/html" width="640" height="360"
                                            :src="'https://www.youtube.com/embed/' + item.file"
                                            frameborder="0"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>

                <!--IMAGE-->
                <div v-if="item.type === 'Image'" class="content-image">
                    <v-card class="mt-3 grey lighten-4" flat :class="theme">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" v-if="item.title !== ''" class="py-0">
                                    <span class="headline gold--text">{{ item.title }}</span>
                                </v-col>
                                <v-col cols="12" v-if="item.file !== null">
                                    <img class="home_img" :src="getData(item)" alt="" >
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </div>

                <!--ARTICLE-->
                <div v-if="item.type === 'Article'" class="content-article">
                    <v-card class="mt-3 grey lighten-4" flat :class="theme">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" align="right" class="pa-0 px-2">
                                    <span class="caption">{{ g_convertDate(item.updateDate) }}</span>
                                </v-col>
                                <v-col cols="12" v-if="item.title !== ''" class="py-0">
                                    <span class="headline gold--text">{{ item.title }}</span>
                                </v-col>
                                <v-col cols="12" v-if="item.text !== ''" v-html="item.text"/>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>

            </div>

        </v-row>

        <v-dialog v-model="dialog_preview">
            <v-card v-if="dialog_preview">
                <v-card-text>
                    CONTENT HERE
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {getContentData} from "@/api/content";
import AuthMixin from "@/services/auth/auth_mixin";



export default {
    name: "ContentView",
    mixins: [AuthMixin],
    data: () => ({
        blob: undefined,
        dialog_preview: false
    }),

    computed: {
        getTitle() {
            return this.$t('title.' + this.$route.name);
        },
        getContent() {
            return this.$store.getters.getContent;
        },
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        }
    },

    methods: {
        download(_item) {
            getContentData(_item.category, _item.id, this.$store.getters.getJWT)
                .then().catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        },

        getData(content) {
            return process.env.VUE_APP_RGG_PORTAL_UX_API_URL + "/content/" + content.category + "/" + content.id + "/data?jwt=" + this.$store.getters.getJWT
        }
    },

    created() {
        this.$store.dispatch('getContent', this.$route.meta.category);
    }
}
</script>

<style>
    .item:hover {
        text-decoration: underline !important;
        font-weight: bold;
    }

    iframe, img.home_img {
        width: 100%;
        max-width: 640px;
    }

    img.home_img {
        border-radius: 1em;
        max-width: 800px;
    }

    .document-row:hover {
        border-radius: 4px;
        background-color: rgba(0,0,0,0.05);
    }

    .content-article ul li p,
    .content-article ol li p {
        margin-bottom: 0;
    }

    .content-article ul,
    .content-article ol,
    .rgg-rich-editor ul,
    .rgg-rich-editor ol {
        margin-bottom: 1em;
    }
</style>