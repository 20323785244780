<template>
    <div class="float-left">
        <!--DIALOG BUTTON-->
        <v-dialog v-if="!item.hasOwnProperty('method')" v-model="dialog" v-bind="dialog_settings">
            <template v-slot:activator="{ on, attr }">
                <v-btn
                    small depressed dark
                    color="gold lighten-1" height="22" class="mr-1 px-2"
                    v-on="on" v-bind="attr"
                    @click="dialog = true"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-row align="center" class="px-3" v-on="on">
                                <v-icon left small>{{ item.icon }}</v-icon>
                                <span class="caption">
                                {{ $t($route.name + action_buttons + item.label) }}
                            </span>
                            </v-row>
                        </template>
                        <span>{{ $t($route.name + action_buttons + item.label + '_tooltip') }}</span>
                    </v-tooltip>
                </v-btn>
            </template>
            <v-card v-if="dialog">
                <v-toolbar fixed dark tile dense color="gold" height="48" style="z-index: 10; flex: unset;">
                    <v-btn plain icon dark @click="close('toolbar')">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-toolbar-title class="pl-0">{{ $t('toolbar_title_new.' + item.label) }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <!--<v-btn depressed text @click="callback('createItem')">
                        <v-icon left>mdi-text-box-plus-outline</v-icon>
                        <span>{{ $t('common.create') }}</span>
                    </v-btn>-->
                </v-toolbar>

                <v-card-text class="pa-0">
                    <rgg-table-dialog
                        :component="item.dialog"
                        :type="item.state"
                        :options="item.options"
                        @call="callback"
                        @action_dialog="callback"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--ONLY CALL METHOD-->
        <v-btn v-else
            small depressed dark
            color="gold lighten-1" height="22" class="mr-1 px-2"
            @click="action(item.method)"
        >
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-row align="center" class="px-3" v-on="on">
                        <v-icon left small>{{ item.icon }}</v-icon>
                        <span v-if="$vuetify.breakpoint.width > 450" class="caption">
                                {{ $t($route.name + action_buttons + item.label) }}
                        </span>
                        <span v-else class="caption" style="font-size:0.9em !important">
                                {{ $t($route.name + action_buttons + item.label) }}
                        </span>
                    </v-row>
                </template>
                <span>{{ $t($route.name + action_buttons + item.label + '_tooltip') }}</span>
            </v-tooltip>
        </v-btn>
    </div>
</template>

<script>
import views from "@/views/views";

export default {
    name: "ActionButtons",
    props: {
        item: Object,
        dialog_settings: undefined
    },
    data: () => ({
        dialog: false,
        action_buttons: ".action_buttons."
    }),

    methods: {
        close: function(_param) {

            if(_param !== 'toolbar') {
                this.$root.$emit('reload');
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'info',
                    msg: 'notification.' + _param.data
                });
            }
            this.dialog = false;
        },
        callback(e) {
            this.close(e);
            //this.$emit('action', e );
        },
        action(_call) {
            views.actions(_call, this);
        },
        closeDialog(_notification) {

            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
            this.$root.$emit('notification', {
                type: 'success',
                icon: 'info',
                msg: 'notification.' + _notification
            });
            this.dialog = false;
        }
    },
    mounted() {}
}
</script>