import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getReportRecords} from "@/api/production";

const state = {
    report_records_cancel_request: new CancelRequest(),
    report_records: {total_count: 0, items: []},
};

const actions = {
    getReportRecords(context, data) {
        context.state.report_records_cancel_request.cancelAndCreateToken();
        return getReportRecords(data, context.state.report_records_cancel_request)
            .then(response => {
                context.state.report_records_cancel_request.resetCancelToken();
                context.commit('setReportRecords', response.data);
            })
    },

    clearReportRecords(context) {
        context.commit('clearReportRecords');
    }
};

const mutations = {
    setReportRecords(state, response) {
        state.report_records.total_count = response.totalCount
        state.report_records.items = []
        response.items.forEach(function (record) {
            state.report_records.items.push({
                id: record.id,
                seller: Utils.getFullNameWithPosition(record.seller),
                agreement_count: record.agreementCount,
                package_count: record.packageCount,
                career_points_total: record.careerPointsTotal,
                career_points_agreements: record.careerPointsAgreements,
                target_price_agreements: Utils.parsePrice(record.targetPriceAgreements),
                career_points_packages: record.careerPointsPackage,
                target_price_packages: Utils.parsePrice(record.targetPricePackage)
            });
        });
    },

    clearReportRecords(state) {
        state.report_records = {total_count: 0, items: []};
    }
};

const getters = {
    getReportRecords(state) {
        return state.report_records;
    },
};

export const production = {
    state,
    actions,
    mutations,
    getters
};