<template>
    <v-container v-if="isAuthenticated()" class="px-0 pt-0 pb-8 contacts" fluid>
        <v-row align="center" class="cl-title white elevation-6" no-gutters :class="theme">
            <span class="headline gold--text text-uppercase font-weight-light pa-2">
                {{ $t('title.contacts') }}
            </span>
        </v-row>

        <!--boss-->
        <v-row v-if="showBoss" class="px-4">
            <v-col cols="12" class="text-uppercase">
                {{ bossTitle }}
            </v-col>
            <v-col cols="12">
                <v-card color="gold" dark max-width="640">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title v-if="this.$vuetify.breakpoint.width > 450" class="text-h5" v-text="bossContactName" />
                            <v-card-title v-else class="text-h6" v-text="bossContactName" />
                            <v-card-subtitle v-text="bossContact.position" />

                            <v-card-actions>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                    <span>
                                        <v-icon left>mdi-phone</v-icon>
                                        <a class="white--text" :href="'tel:' + bossContact.phone">{{ bossContact.phone }}</a>
                                    </span>
                                    </v-col>
                                    <v-col cols="12">
                                    <span>
                                        <v-icon left>mdi-email</v-icon>
                                        <a class="white--text" :href="'mailto:' + bossContact.email">{{ bossContact.email }}</a>
                                    </span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </div>

                        <v-avatar v-if="$vuetify.breakpoint.width > 450" class="ma-3 rounded" :size="$vuetify.breakpoint.width < 640 ? 64 : 128" tile>
                            <v-img :src="bossProfileImage"></v-img>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!--CONTACTS-->
        <v-row class="px-4">
            <v-col cols="12" class="text-uppercase">
                {{ $t('contacts.company') }}
            </v-col>
            <v-col cols="12" v-for="(item,i) in contacts" :key="i">
                <v-card max-width="640">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5 gold--text" v-text="item.name"/>
                            <v-card-subtitle v-text="item.position"/>
                            <v-card-actions>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                    <span>
                                        <v-icon color="gold" left>mdi-phone</v-icon>
                                        <a class="gold--text" :href="'tel:' + item.phone">{{ item.phone }}</a>
                                    </span>
                                    </v-col>
                                    <v-col cols="12">
                                    <span>
                                        <v-icon color="gold" left>mdi-email</v-icon>
                                        <a class="gold--text" :href="'mailto:' + item.email">{{ item.email }}</a>
                                    </span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";

export default {
    name: "ContactsView",
    mixins: [AuthMixin],
    data: () => ({
        contacts: [
            {
                name: "Mgr. Rudolf Kováč, M.P.H.",
                position: "General Director",
                phone: "+421 905 204 461",
                email: "rudolf.kovac@royalgoldengroup.sk"
            },
            {
                name: "Michal Pindroch",
                position: "Executive Director",
                phone: "+421 908 204 396",
                email: "michal.pindroch@royalgoldengroup.sk"
            },
            {
                name: "Lýdia Sovová",
                position: "Division Director",
                phone: "+421 917 215 878",
                email: "lydia.sovova@royalgoldengroup.sk"
            },
            {
                name: "Servisné centrum",
                position: "",
                phone: "+421 911 605 905",
                email: "service.center@royalgoldengroup.sk"
            }
        ]
    }),
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        },
        showBoss() {
            if (this.checkPermission(Permissions.SELLER)) {
                let position = this.$store.getters.getUserPosition
                return !position.startsWith("P7") && !position.startsWith("GD") && !position.startsWith("CD") && !position.startsWith("RGGP") && !position.startsWith("RGGC")
            } else {
                return this.checkPermission(Permissions.CUSTOMER)
            }
        },
        bossProfileImage() {
            let profileImage = this.$store.getters.getBossProfileImage
            if (profileImage == null) {
                return require('@/assets/missing_photo.png');
            } else {
                return profileImage
            }
        },
        bossContact() {
            return this.$store.getters.getBossContact
        },
        bossContactName() {
            return this.$store.getters.getBossContact.name.split(" - ")[0]
        },
        hasBossCompanyName() {
            return this.$store.getters.getBossContact.name.includes(" - ")
        },
        bossContactCompanyName() {
            return this.$store.getters.getBossContact.name.split(" - ")[1]
        },
        bossTitle() {
            if (this.checkPermission(Permissions.SELLER)) {
                return this.$t('contacts.boss')
            } else if (this.bossContact.email === "service.center@royalgoldengroup.sk") {
                return this.$t('contacts.contact')
            } else {
                return this.$t('contacts.seller')
            }
        }
    },
    mounted() {
        if(this.showBoss) {
            this.$store.dispatch("loadBossProfileImage");
            this.$store.dispatch("getBossContact");
        }
    }
}
</script>

<style>
    .contacts a {
        text-decoration: none;
    }
    .contacts a:hover {
        text-decoration: underline;
        font-weight: bold;
    }
</style>