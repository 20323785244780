<template>
    <!-- ACCOUNT MENU -->
    <v-menu
        v-model="menu_user"
        :close-on-content-click="false"
        :nudge-width="200"
        transition="slide-y-transition"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                color="gold" style="right: 8px;"
            >
                <v-avatar style="border: 2px solid" size="44">
                    <v-img :src="profileImage"/>
                </v-avatar>
            </v-btn>
        </template>

        <v-card>
            <!-- ACCOUNT OVERVIEW -->
            <v-list :class="{ 'dark-theme-overview' : dark_theme }">
                <v-list-item>
                    <v-list-item-avatar>
                        <v-img :src="profile_image"/>
                    </v-list-item-avatar>

                    <v-list-item-content style="display: table;">
                        <v-list-item-title>{{ username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ userPosition }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider class="gold lighten-1"></v-divider>

            <!-- MENU ITEMS -->
            <v-list class="px-2" :class="{ 'dark-theme-menu' : dark_theme }" dense>

                <div v-for="(item,index) in user_menu_buttons" :key="item.index">
                    <v-list-item v-if="menuItemVisible(index)" link @click="handleClick(index)">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('user_menu.' + item.label) }}</v-list-item-title>
                        <v-list-item-action v-if="item.external">
                            <v-icon small right>mdi-exit-to-app</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </div>

                <!--PROFILE DIALOG-->
                <v-dialog v-model="dialog_profile" v-bind="dialog_default">
                    <v-toolbar dark dense color="gold" style="z-index: 10; position: sticky; top: 0;">
                        <v-btn icon dark @click="closeProfileDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            <span>{{ $t('user_menu.my_profile') }}</span>
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card tile v-if="dialog_profile">
                        <v-card-text class="pt-4">
                            <v-form @submit.prevent="submit" id="form" ref="change_password" class="formular" lazy-validation v-model="valid">

                                <v-container fluid>
                                    <v-row>
                                        <!--FOTO-->
                                        <v-col cols="12" md="6" class="pl-0" :class="{ 'px-0' : this.$vuetify.breakpoint.width <= 959 }">
                                            <v-row no-gutters :class="{ 'rgg-grey' : this.$vuetify.theme.dark }"
                                                   class="grey lighten-4 rounded pa-3" align="center">
                                                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                                                    {{ $t('user_menu.profile_foto') }}
                                                </v-col>

                                                <v-col cols="12" align="center">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <v-avatar
                                                                class="ma-3 rounded"
                                                                size="201"
                                                                tile
                                                            >
                                                                <v-img :src="profileImage"></v-img>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-btn
                                                                class="gold"
                                                                dark small depressed dense
                                                                @click="$refs.inputUpload.click()"
                                                            >
                                                                <v-icon small left>mdi-account-box</v-icon>
                                                                {{$t('user_menu.profile_foto_file')}}
                                                            </v-btn>
                                                            <input accept="image/jpg,image/jpeg" v-show="false" ref="inputUpload" type="file" @change="uploadImage" />
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                                <v-col cols="12" align="center" class="pt-4">
                                                    <v-alert type="info" dense text class="caption my-0">
                                                        {{ $t('user_menu.profile_foto_info') }}
                                                    </v-alert>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <!--READER - BASIC DATA-->
                                        <v-col cols="12" md="6" class="pr-0" :class="{ 'px-0' : this.$vuetify.breakpoint.width <= 959 }">
                                            <v-row no-gutters :class="{ 'rgg-grey' : this.$vuetify.theme.dark }"
                                                   class="grey lighten-4 rounded pa-3">
                                                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                                                    {{ $t('user_menu.profile_data') }}
                                                </v-col>

                                                <v-col cols="12" v-for="(value,key) in getProfileInfo" :key="key">
                                                    <v-row class="rounded ma-0 my-2 reader">
                                                        <v-col cols="12" class="caption gold--text py-0">
                                                            {{ $t('user_menu.' + key) }}
                                                        </v-col>
                                                        <v-col cols="12" class="body-1 py-0">
                                                            {{ g_isDefined(value) }}
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>

                                    <!--PASSWORD-->
                                    <v-row :class="{ 'rgg-grey' : this.$vuetify.theme.dark }"
                                           class="grey lighten-4 rounded mt-5">
                                        <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                                            {{ $t('user_menu.profile_password') }}
                                        </v-col>

                                        <v-col v-bind="col_default">
                                            <v-text-field outlined dense color="gold"
                                                          :label="$t('user_menu.old_password')"
                                                          v-model="password.old"
                                                          type="password"
                                                          @keyup="resetOldIncorrect"

                                                          v-validate="'required'"
                                                          data-vv-name="old_password"
                                                          :error-messages="incorect_old_password ? $t('validations.custom.old_password.incorrect') : errors.collect('old_password')"

                                            >
                                                <template v-slot:prepend>
                                                    <v-icon x-small color="red">mdi-asterisk</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col v-bind="col_default">
                                            <v-text-field outlined dense color="gold"
                                                          :label="$t('user_menu.new_password')"
                                                          v-model="password.new"
                                                          type="password"

                                                          v-validate="'required|rePassword'"
                                                          data-vv-name="new_password"
                                                          :error-messages="errors.collect('new_password')"

                                            >
                                                <template v-slot:prepend>
                                                    <v-icon x-small color="red">mdi-asterisk</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col v-bind="col_default">
                                            <v-text-field outlined dense color="gold"
                                                          :label="$t('user_menu.new_password_again')"
                                                          v-model="password.renew"
                                                          type="password"

                                                          v-validate="'required|rePassword'"
                                                          data-vv-name="new_password"
                                                          :error-messages="errors.collect('new_password')"
                                            >
                                                <template v-slot:prepend>
                                                    <v-icon x-small color="red">mdi-asterisk</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" align="center">
                                            <v-btn color="gold" dark small depressed dense type="sumbit">
                                                <v-icon small left>mdi-lock-reset</v-icon>
                                                {{$t('user_menu.change_password')}}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!--CAREER DIALOG-->
                <v-dialog v-model="dialog_career" v-bind="dialog_default">
                    <v-toolbar dark dense color="gold" style="z-index: 10; position: sticky; top: 0;">
                        <v-btn icon dark @click="dialog_career = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            <span>{{ $t('user_menu.my_career') }}</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card tile v-if="dialog_career">
                        <v-card-text class="pt-6">
                            <CareerWidget v-if="data_ready" :seller_data="getCareerItem"/>
                        </v-card-text>
                        <v-card-text>
                            <v-row :class="{ 'rgg-grey' : this.$vuetify.theme.dark }" class="grey lighten-4 rounded mb-0 widget">
                                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light pb-0">
                                    {{ $t('sellers.dialog.boss') }}
                                </v-col>
                                <v-col cols="12">
                                    <v-card flat outlined>
                                        <v-card-text>
                                            <span class="headline">
                                                {{ boss.name + " " + boss.surname }}
                                                <span class="gold--text" v-if="boss.companyName !== ''">
                                                    - {{ boss.companyName }}
                                                </span>
                                            </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-list-item :class="{ 'dark-theme-active' : !dark_theme }">
                    <v-list-item-action>
                        <v-switch :false-value="true" :true-value="false"
                            v-model="dark_theme"
                            color="white"
                            @click="dark"
                        ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('user_menu.theme') }}</v-list-item-title>
                </v-list-item>

                <v-divider class="mt-1"/>

                <v-list-item @click="logout()">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('user_menu.logout') }}</v-list-item-title>
                </v-list-item>

            </v-list>

        </v-card>
    </v-menu>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import CareerWidget from "@/components/CareerWidget";
import {changePassword} from "@/api/auth";
import {uploadProfileImage} from "@/api/user_profile";

export default {
    name: "UserMenu",
    components: {CareerWidget},
    data: () => ({
        menu_user: false,
        dark_theme: false,
        dialog_profile: false,
        dialog_career: false,
        grid: {sm: 6, md: 4, lg: 2},
        profile_image: null,
        password: {},
        user_career: {},
        boss: '',
        user_menu_buttons: [
            {
                label: 'my_profile',
                icon: 'mdi-account',
                external: false,
                click() { this.menu_profile() },
                permission: ''
            },
            {
                label: 'my_career',
                icon: 'mdi-finance',
                external: false,
                click() { this.menu_career() },
                permission: Permissions.SELLER
            },
            {
                label: 'my_email',
                icon: 'mdi-email-box',
                external: true,
                click() { this.menu_email() },
                permission: Permissions.SELLER
            },
            {
                label: 'my_page',
                icon: 'mdi-web-box',
                external: true,
                click() { this.menu_webpage() },
                permission: Permissions.SELLER
            },
        ],
        data_ready: true,
        col_default: {cols: 12, sm: 6, md: 4, lg: 4},
        col_email: {cols: 12, sm: 12, md: 6, lg: 6},
        dialog_default: {maxWidth: 1024, persistent: true, overlayOpacity: 0.8, overlayColor: "black"},
        valid: true,
        incorect_old_password: false,
        isLoading: false
    }),
    mixins: [AuthMixin],
    computed: {
        username() {
            return this.$store.getters.getUserDisplayName.split(" - ")[0]
        },

        hasCompanyName() {
            return this.$store.getters.getUserDisplayName.includes(" - ")
        },

        companyname() {
            return this.$store.getters.getUserDisplayName.split(" - ")[1]
        },

        profileImage() {
            let profileImage = this.$store.getters.getProfileImage
            if (profileImage == null) {
                return require('@/assets/missing_photo.png');
            } else {
                return profileImage
            }
        },

        userPosition() {
            return this.$store.getters.getUserPosition
        },
        getCareerItem() {
            return this.user_career;
        },
        getBoss() {
            return this.boss;
        },
        getProfileInfo() {
            return {
                id: this.$store.getters.getUserId,
                name: this.$store.getters.getUserName,
                surname: this.$store.getters.getUserSurname,
                phone: this.$store.getters.getUserPhone,
                email: this.$store.getters.getUserEmail,
            }
        }
    },

    created() {
        this.$validator.extend('rePassword', {
            validate: () => {
                if(this.password.new === this.password.renew) {
                    return true;
                }
                return false;
            }
        });

        this.$validator.extend('checkOldPassword', {
            validate: () => {
                return this.incorect_old_password;
            }
        });
    },

    methods: {

        handleClick(index) {
            this.user_menu_buttons[index].click.call(this);
        },

        menuItemVisible(index) {
            if (this.user_menu_buttons[index].permission !== '') {
                if (this.checkPermission(this.user_menu_buttons[index].permission)) {
                    if (this.user_menu_buttons[index].label === 'my_page') {
                        return this.$store.getters.getPersonalWeb !== ""
                    }
                } else {
                    return false
                }
            }
            return true
        },

        uploadImage() {

            let formData = new FormData();
            formData.append("file", this.$refs.inputUpload.files[0]);
            uploadProfileImage(formData)
                .then(() => {
                    this.$store.dispatch("loadProfileImage")
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'notification.profile_image_updated'
                    });
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        },

        submit() {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    changePassword({oldPassword: this.password.old, newPassword: this.password.new})
                        .then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'notification.password_successful_changed'
                            });
                            this.reset();

                        }).catch( () => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'password_alert',
                                msg: 'notification.old_password_error'
                            });
                            this.incorect_old_password = true;
                    })
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        menu_profile() {

            this.menu_user = false;
            this.dialog_profile = true;

            this.$nextTick(()=>{
                this.password = {};
            });
        },
        menu_career() {
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getSeller', this.$store.getters.getUserId)
                .then(() => {
                    this.user_career = this.$store.getters.getSelectedSeller;
                    this.boss = this.user_career.boss;
                    this.$root.$emit('overlay', false);
                    this.dialog_career = true;
                    this.menu_user = false;
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })


        },
        menu_email() {
            this.$router.push('/email');
        },
        menu_webpage() {
            this.$router.push('/web');
        },

        dark() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem("dark", this.$vuetify.theme.dark.toString());
        },

        closeProfileDialog(){
            this.reset();

            this.$nextTick(() => {
                this.dialog_profile = false;
            });

        },

        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login');
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        },
        resetOldIncorrect() {
            this.incorect_old_password = false;
        },
        reset() {
            this.password = {};

            this.$nextTick(() => {
                this.resetOldIncorrect();
                this.$refs.change_password.resetValidation();
            });

        }
    },

    mounted() {
        this.dark_theme = JSON.parse(localStorage.getItem('dark'));
    },

    updated() {
        this.profile_image = this.profileImage;
    }
}
</script>

<style>
    .dark-theme-overview {
        background-color: #b78c2b !important;
    }

    .dark-theme-menu {
        background-color: #9d7a25 !important;
    }

    .dark-theme-active {
        background-color: #b78c2b !important;
        color: white !important;
        border-radius: 8px;
    }

    .light-theme-active {
        background-color: rgba(255, 255, 255, 0.2) !important;
        border-radius: 8px;
    }
</style>