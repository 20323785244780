import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getGoldDeposit} from "@/api/gold_deposit";

const state = {
    gold_deposit_cancel_request: new CancelRequest(),
    gold_deposit: {total_count: 0, items: []}
};

const actions = {
    getGoldDeposit(context, data) {
        context.state.gold_deposit_cancel_request.cancelAndCreateToken();
        return getGoldDeposit(data, context.state.gold_deposit_cancel_request)
            .then(response => {
                context.state.gold_deposit_cancel_request.resetCancelToken();
                context.commit('setGoldDeposit', response.data);
            })
    },

    clearGoldDeposit(context) {
        context.commit('clearGoldDeposit');
    }
};

const mutations = {
    setGoldDeposit(state, response) {
        state.gold_deposit.total_count = response.totalCount
        state.gold_deposit.items = []
        response.items.forEach(function (gold_deposit) {
            let customer = ''
            let agreement = ''
            if (gold_deposit.agreement !== null) {
                customer = Utils.getFullName(gold_deposit.agreement.customer)
                agreement = gold_deposit.agreement.id
            }

            state.gold_deposit.items.push({
                id: gold_deposit.id,
                modified_date: Utils.convertDate(gold_deposit.modifiedDate),
                type: Utils.localizeText("gold_deposit.type." + gold_deposit.type),
                deposit_type: Utils.localizeText("gold_deposit.deposit_type." + gold_deposit.depositType),
                serial_number: gold_deposit.serialNumber,
                comment: gold_deposit.comment,
                agreement: agreement,
                customer: customer,
                raw_data: {
                    modifiedDate: gold_deposit.modifiedDate,
                    type: gold_deposit.type,
                    depositType: gold_deposit.depositType,
                    serialNumber: gold_deposit.serialNumber,
                    agreementId: agreement,
                    comment: gold_deposit.comment,
                    amount: 1
                }
            });
        });
    },

    clearGoldDeposit(state) {
        state.gold_deposit = {total_count: 0, items: []};
    }
};

const getters = {
    getGoldDeposit(state) {
        return state.gold_deposit;
    }
};

export const gold_deposit = {
    state,
    actions,
    mutations,
    getters
};