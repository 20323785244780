import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getBankTransactions, getBankTransactionsForStatement} from "@/api/transactions";

const state = {
    bank_transactions_cancel_request: new CancelRequest(),
    bank_transactions: {total_count: 0, items: []},
    import: {total_count: 0, items: []}
};

const actions = {
    getBankTransactions(context, data) {
        context.state.bank_transactions_cancel_request.cancelAndCreateToken();
        return getBankTransactions(data, context.state.bank_transactions_cancel_request)
            .then(response => {
                context.state.bank_transactions_cancel_request.resetCancelToken();
                context.commit('setBankTransactions', response.data);
            })
    },

    getBankTransactionsForStatement(context, data) {
        context.state.bank_transactions_cancel_request.cancelAndCreateToken();
        return getBankTransactionsForStatement(data, context.state.bank_transactions_cancel_request)
            .then(response => {
                context.state.bank_transactions_cancel_request.resetCancelToken();
                context.commit('setBankTransactionsForStatement', response.data);
            })
    },

    clearBankTransactions(context) {
        context.commit('clearBankTransactions');
    },

    clearBankTransactionsForStatement(context) {
        context.commit('clearBankTransactionsForStatement');
    }
};

const mutations = {
    setBankTransactions(state, response) {
        state.bank_transactions.total_count = response.totalCount
        state.bank_transactions.items = []
        response.items.forEach(function (transaction) {
            state.bank_transactions.items.push({
                id: transaction.id,
                date: Utils.convertDate(transaction.transactionDate),
                amount: Utils.parsePrice(transaction.amount),
                symbol: transaction.symbol,
                account: transaction.account,
                details: transaction.details,
                status: Utils.localizeText("transactions.type." + transaction.type),
                raw_data: transaction
            });
        });
    },
    setBankTransactionsForStatement(state, response) {
        state.import.total_count = response.totalCount;
        state.import.items = [];

        for (let i = 0; i < response.items.length; i++) {
            let transaction = response.items[i]
            state.import.items.push({
                index: i,
                id: transaction.id,
                date: Utils.convertDate(transaction.transactionDate),
                amount: Utils.parsePrice(transaction.amount),
                symbol: transaction.symbol,
                account: transaction.account,
                details: transaction.details,
                status: Utils.localizeText("transactions.type." + transaction.type),
                raw_data: transaction
            });
        }
    },

    clearBankTransactions(state) {
        state.bank_transactions = {total_count: 0, items: []};
    },
    clearBankTransactionsForStatement(state) {
        state.import = {total_count: 0, items: []};
    }
};

const getters = {
    getBankTransactions(state) {
        return state.bank_transactions;
    },

    getBankTransactionsForStatement(state) {
        return state.import;
    },
};

export const transactions = {
    state,
    actions,
    mutations,
    getters
};