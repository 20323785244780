import messages_sk from "@/i18n/sk/messages";
import messages_en from "@/i18n/en/messages";
import messages_de from "@/i18n/de/messages";
import messages_fr from "@/i18n/fr/messages";

const messages = {
    sk: messages_sk,
    en: messages_en,
    de: messages_de,
    fr: messages_fr
};

export default messages