import Vue from 'vue'
import Vuex from 'vuex'

import {authenticator} from "@/store/authenticator"
import {agreements} from "@/store/agreements";
import {users} from "@/store/users";
import {content} from "@/store/content";
import {price_lists} from "@/store/price_lists";
import {gold_deposit} from "@/store/gold_deposit";
import {personal_webs} from "@/store/personal_webs";
import {purchase} from "@/store/purchase";
import {packages} from "@/store/packages";
import {salaries} from "@/store/salaries";
import {transactions} from "@/store/transactions";
import {sellers} from "@/store/sellers";
import {production} from "@/store/production";


import {getBossContact, getBossProfileImage, getProfileImage} from "@/api/user_profile";
import {getGlobalData} from "@/api/global";
import {Utils} from "@/services/utils";

Vue.use(Vuex);

const state = {
    global_data: {},
    user: {
        display_name: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        position: '',
        id: '',
        GDPRAccepted: false,
        profileImage: null,
        bossProfileImage: null,
        bossContact: {name: "", position: "", email: "", phone: ""},
        personalWeb: "",
        permissions: [],
    }
};

const actions = {

    getGlobalData(context) {
        getGlobalData().then((response) => {
            return context.commit("setGlobalData", response.data)
        });
    },

    setUser(context, userData) {
        context.commit("setUser", userData)
    },

    loadProfileImage(context) {
        getProfileImage().then((response) => {
            return context.commit("setProfileImage", window.URL.createObjectURL(new Blob([response.data])))
        });
    },

    loadBossProfileImage(context) {
        getBossProfileImage().then((response) => {
            return context.commit("setBossProfileImage", window.URL.createObjectURL(new Blob([response.data])))
        });
    },

    getBossContact(context) {
        getBossContact().then((response) => {
            return context.commit("setBossContact", response.data)
        });
    },

    logout(context) {
        context.commit('clearJwtToken')
        context.commit('clearUser')
    }
};

const mutations = {

    setGlobalData(state, data) {
        state.global_data.companyGoldSaving = Utils.parseValue(data.companyGoldSaving)
        state.global_data.companyGoldSavingCoins = data.companyGoldSavingCoins
        state.global_data.companyGoldSavingRemainder = Utils.parseValue(data.companyGoldSavingRemainder)
        state.global_data.totalGoldAmount = Utils.parseValue(data.totalGoldAmount)
        state.global_data.totalOunces = Utils.parseValue(data.totalOunces)
    },

    setUser(state, userData) {
        state.user.displayName = userData.display_name
        state.user.name = userData.name
        state.user.surname = userData.surname
        state.user.email = userData.email
        state.user.phone = userData.phone
        state.user.position = userData.position
        state.user.id = userData.id
        state.user.GDPRAccepted = userData.gdpr_accepted
        state.user.personalWeb = userData.personal_web
        state.user.permissions = userData.permissions
    },

    clearUser(state) {
        state.user = {
            display_name: '',
            name: '',
            surname: '',
            email: '',
            phone: '',
            position: '',
            id: '',
            GDPRAccepted: false,
            profileImage: null,
            bossProfileImage: null,
            bossContact: {name: "", position: "", email: "", phone: ""},
            personalWeb: "",
            permissions: []
        }
    },

    setProfileImage(state, profileImage) {
        state.user.profileImage = profileImage
    },

    setBossProfileImage(state, profileImage) {
        state.user.bossProfileImage = profileImage
    },

    setBossContact(state, contactData) {
        state.user.bossContact = contactData
    }
};

const getters = {

    getGlobalData(state) {
        return state.global_data;
    },

    getUserDisplayName(state) {
        return state.user.displayName;
    },

    getUserName(state) {
        return state.user.name;
    },

    getUserSurname(state) {
        return state.user.surname;
    },

    getUserEmail(state) {
        return state.user.email;
    },

    getUserPhone(state) {
        return state.user.phone;
    },

    getUserPosition(state) {
        return state.user.position;
    },

    getUserId(state) {
        return state.user.id;
    },

    getProfileImage(state) {
        return state.user.profileImage;
    },

    getGDPRAccepted(state) {
        return state.user.GDPRAccepted;
    },

    getBossProfileImage(state) {
        return state.user.bossProfileImage;
    },

    getBossContact(state) {
        return state.user.bossContact;
    },

    getPersonalWeb(state) {
        return state.user.personalWeb;
    },

    getPermissions(state) {
        return state.user.permissions;
    }
};

export const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules: {
        authenticator,
        agreements,
        users,
        content,
        price_lists,
        gold_deposit,
        personal_webs,
        packages,
        purchase,
        salaries,
        transactions,
        sellers,
        production
    }
});