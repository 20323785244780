<template>
    <v-container fluid class="toolbar-filter pa-5 pt-0 pb-0 mb-2"
                 :class="{ 'grey darken-4' : this.$vuetify.theme.dark }"
    >
        <!--SEARCH-->
        <v-row align="center" class="search pt-1 pb-2" v-if="groups.length">
            <v-col cols="2" xs="2" sm="3" md="4" class="pt-0"></v-col>
            <v-col cols="8" xs="8" sm="6" md="4" class="pt-0">
                <v-text-field class="search-input"
                              :placeholder="getPlaceholderText"
                              prepend-inner-icon='mdi-card-search'
                              dense hide-details color="gold"
                              v-on:keyup="filterSearch"
                              v-model="filter_ids[0]"
                              @blur="isSearchTyping = false"
                              clearable
                              clear-icon="mdi-close-circle"
                ></v-text-field>
            </v-col>
            <v-col cols="2" xs="2" sm="3" md="4" class="pt-0" align="right">
                <v-btn v-if="groups.length-1 && breakPoint < filter_hide" icon @click="filter_show = !filter_show">
                    <v-icon color="gold" class="mdi mdi-filter-menu" :class="{'mdi-filter-menu-outline' : filter_show}"/>
                </v-btn>
            </v-col>
        </v-row>
        <!--FILTER-->
        <v-row align="center" v-if="filter_show">
            <v-col class="py-0 px-0">
                <div v-for="(group, index) in groups" :key="group.id" class="group px-0 mb-0">
                    <!--TYPE-BUTTONS-->
                    <div v-if="group.type === 'buttons'"
                         class="filter-outline px-1 pt-0 mb-3 mr-2 rounded mobil"
                    >
                        <v-row align="center" justify="start">
                            <span class="filter-label caption pr-1 grey--text px-1 ml-3 pa-0 ma-0"
                                  :class="{ 'gold--text font-weight-bold' : filter_ids[index] !== undefined && filter_ids[index].length !== 0 }"
                                  style="font-size: 12px !important;">
                                {{ $t(getSelectorPath + group.id) }}
                            </span>
                        </v-row>
                        <v-btn-toggle
                            v-model="filter_ids[index]"
                            dense :multiple="group.multiselect"
                            :mandatory="group.mandatory"
                            class="pa-0 ma-0 mt-2 lighten-2 filter-group" borderless
                        >
                            <!--{{ group.values.length }}-->
                            <v-btn v-for="(v, b) of group.values" :key="b" active-class="gold white--text"
                                   x-small depressed class=""
                                   :style="breakPoint < filter_mobile ? 'width: calc(100% / ' + mobilButtons(group.values.length) + ' - 4px);' : ''"
                            >
                                <span class="body-2">
                                    <!--{{ $t(getSelectorPath + v['id'].toLowerCase()) }}-->
                                    {{ g_i18_filter(v['id'].toLowerCase(), '') }}
                                </span>
                            </v-btn>
                        </v-btn-toggle>
                    </div>

                    <!--TYPE-INPUTS-->
                    <div v-if="group.type === 'date_input'"
                         class="filter-outline pl-1 pt-0 mb-3 mr-2 rounded mobil"
                    >
                        <v-row align="center" justify="start">
                            <span class="filter-label caption pr-1 grey--text px-1 ml-3 pa-0 ma-0"
                                  :class="{ 'gold--text font-weight-bold' : filter_ids[index] !== undefined && filter_ids[index].length !== 0 }"
                                  style="font-size: 12px !important;">
                                {{ $t(getSelectorPath + group.id) }}
                            </span>
                        </v-row>

                        <v-menu v-for="(v, b) of group.values" :key="b"
                                class=""

                                v-model="date_input['menu_'+index+b]"
                                :close-on-content-click="false"

                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn active-class="gold white--text" :class="{'gold white--text' : !!isIds[index][b]}"
                                       x-small depressed class="mt-2 type-inputs mb-1 mr-1"
                                       v-on="on" v-bind="attrs" :ref="'reset_'+index+b"
                                       :style="breakPoint < filter_mobile ? 'width: calc(100% / ' + mobilButtons(group.values.length) + ' - 4px);' : ''"
                                >
                                    <span v-if="!isIds[index][b]" class="caption">
                                        {{ $t(getSelectorPath + v['id']) }}
                                    </span>
                                    <span v-else class="caption">
                                        {{ isIds[index][b] }}
                                        <v-icon small @click.native.stop="resetDate('reset_' + index + b, index,b)">mdi-close-circle</v-icon>
                                    </span>
                                </v-btn>
                            </template>
                            <v-date-picker
                                v-model="isIds[index][b]"
                                no-title color="gold" class=""
                            >
                                <v-spacer></v-spacer>
                                <v-btn text small color="gold" class="white"
                                       @click="date_input['menu_'+index+b] = false"
                                >
                                    {{ $t('common.close') }}
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!--COUNTER/PROGRESS-BAR-->
        <v-row align="center" class="ma-0 pa-0">
            <v-col cols="12" class="pa-0" align="right" :class="!groups.length ? 'pt-3' : ''">
                <span class="caption gold--text rounded">
                    {{$t(getSelectorPath + 'counts') }}: <strong>{{ counts }}</strong>
                </span>
            </v-col>
        </v-row>
        <v-progress-linear
            :active="progress"
            indeterminate
            absolute
            color="gold lighten-1"
            height="3"
        />
    </v-container>
</template>

<script>
export default {
    name: "ToolbarFilter",
    props: {
        filter: Object,
        groups: Array,
        preselected: Array,
        counts: Number,
        progress: Boolean,
        name: String,
        selector: Boolean
    },
    data: () => ({
        menu: {},
        picker: {},
        date_input: {},

        filter_show: true,
        filter_hide: 666,
        filter_mobile: 451,
        filter_ids: [],
        filter_serialize: {},

        isSearchTyping: false,

        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

    }),
    computed: {
        isIds() {
            return this.filter_ids;
        },
        theme() {
            return 0;
        },
        breakPoint() {
            if (this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height) {
                return this.$vuetify.breakpoint.height
            } else {
                return this.$vuetify.breakpoint.width
            }
        },
        getSelectorPath() {
            if(this.selector) {
                return this.name + '.toolbar_filter.';
            } else {
                return this.$route.name + '.toolbar_filter.';
            }
        },
        getPlaceholderText() {
            if (this.breakPoint < 700) {
                return this.$t('toolbar_filter.search')
            } else {
                return this.$t(this.getSelectorPath + 'search')
            }
        }
    },

    watch: {
        'filter_ids': {
            handler: function (val) {
                this.filter.toolbar_filter = {};
                this.filter.page = 1;

                for (let i = 0; i < val.length; i++) {
                    if (val[i] !== undefined && val[i] !== null) {
                        switch (this.groups[i].type) {
                            case 'search':
                                this.filter.toolbar_filter['search'] = val[i];
                                break;

                            case 'date_input':
                                for (const index in this.filter_ids[i]) {
                                    if (typeof val[i][index] !== 'undefined') {
                                        this.filter.toolbar_filter[this.groups[i].values[index].id] = this.filter_ids[i][index];
                                    }
                                }
                                break;

                            case 'buttons':
                                if (!this.groups[i].multiselect) {
                                    this.filter.toolbar_filter[this.groups[i].id] = this.groups[i].values[val[i]].id;
                                } else {
                                    if (this.filter_ids[i].length) {
                                        this.filter.toolbar_filter[this.groups[i].id] = [];
                                        for (let j = 0; j < val[i].length; j++) {
                                            this.filter.toolbar_filter[this.groups[i].id].push(this.groups[i].values[val[i][j]].id);
                                        }
                                    }
                                }
                                break;

                            case 'static':
                                //
                                break;

                            default:
                                console.debug("FilterType Not Defined");
                                break;
                        }
                    }
                }
            },
            deep: true
        },

        '$vuetify.breakpoint.width': function (val) {
            if( val < this.filter_hide) {
                this.filter_show = false;
            } else {
                this.filter_show = true;
            }
        }
    },

    methods: {
        mobilButtons(_count) {
            if( _count > 3 || _count === 2) {
                return 2;
            } else if(_count === 3) {
                return 3;
            } else {
                return 1;
            }
        },

        resetDate(_ref, _index, _sub) {
            this.$set(this.filter_ids[_index], _sub, undefined);
        },

        filterSearch: function () {
            this.isSearchTyping = true;
            clearTimeout(this.timeout);

            let self = this;
            this.timeout = setTimeout(function () {
                self.filter.toolbar_filter['search'] = self.filter_ids[0];
            }, 300);
        },
        cancelDate(_index, _sub) {
            this.filter_ids[_index][_sub] = undefined;
            this.date_input['menu_' + _index + _sub] = false;
        },
        recordDate(_index, _sub) {
            this.date_input['menu_' + _index + _sub] = false;
        }
    },

    created() {

        if( this.groups.length !== 0 ) {
            for (const group of this.groups) {
                if (group.multiselect) {
                    this.filter_ids.push([]);
                } else {
                    this.filter_ids.push(undefined);
                }
            }

            if(this.preselected.length) {
                this.filter_ids = this.preselected;
            }
        }

        if(this.$vuetify.breakpoint.name === 'xs') {
            this.filter_show = false;
        }
    }
}
</script>

<style>
    .toolbar-filter {
        /*position: sticky;
        top: 106px;*/
        box-shadow: 8px 0 10px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .toolbar-filter .v-btn {
        text-transform: unset;
    }

    .toolbar-filter .group > div {
        position: relative;
        float: left;
        height: 28px;
    }

    .toolbar-filter .search > .col {
        padding-top: 0;
    }

    .toolbar-filter .search .search-input button.v-icon {
        font-size: 20px;
    }
</style>