import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                /*primary: '',
                secondary: '',
                accent: '',
                error: '',
                info: '',
                success: '',
                warning: '',*/
                gold: '#b78c2b',
                card_hover: '#f1f1f1',
            },
            dark: {
                /*primary: '',
                secondary: '',
                accent: '',
                error: '',
                info: '',
                success: '',
                warning: '',*/
                gold: '#b78c2b',
                card_hover: '#343434',
            }
        }
    }
});