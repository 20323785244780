import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getCurrentPriceList, getPriceList, getPriceLists, getPriceMarginList} from "@/api/price_lists";

const state = {
    price_lists_cancel_request: new CancelRequest(),
    price_lists: {total_count: 0, items: []},
    current_price_list: {},
    price_list: {},
    price_margin_list: {}
};

const actions = {
    getPriceLists(context, data) {
        context.state.price_lists_cancel_request.cancelAndCreateToken();
        return getPriceLists(data, context.state.price_lists_cancel_request)
            .then(response => {
                context.state.price_lists_cancel_request.resetCancelToken();
                context.commit('setPriceLists', response.data);
            })
    },
    getCurrentPriceList(context) {
        return getCurrentPriceList()
            .then(response => {
                context.commit('setCurrentPriceList', response.data);
            })
    },
    getPriceList(context, id) {
        return getPriceList(id)
            .then(response => {
                context.commit('setPriceList', response.data);
            })
    },
    getPriceMarginList(context, id) {
        return getPriceMarginList(id)
            .then(response => {
                context.commit('setCurrentPriceList', response.data);
            })
    },

    clearPriceLists(context) {
        context.commit('clearPriceLists');
    },
    clearCurrentPriceList(context) {
        context.commit('clearCurrentPriceList');
    },
    clearPriceList(context) {
        context.commit('clearPriceList');
    }
};

const mutations = {
    setPriceLists(state, response) {
        state.price_lists.total_count = response.totalCount
        state.price_lists.items = []
        response.items.forEach(function (price_list) {
            state.price_lists.items.push({
                id: price_list.id,
                validFrom: Utils.convertDate(price_list.validFrom),
                saving1g: Utils.parsePrice(price_list.saving1g),
                bar1g: Utils.parsePrice(price_list.bar1g),
                bar2g: Utils.parsePrice(price_list.bar2g),
                bar5g: Utils.parsePrice(price_list.bar5g),
                bar10g: Utils.parsePrice(price_list.bar10g),
                bar20g: Utils.parsePrice(price_list.bar20g),
                bar50g: Utils.parsePrice(price_list.bar50g),
                bar100g: Utils.parsePrice(price_list.bar100g),
                bar250g: Utils.parsePrice(price_list.bar250g),
                bar500g: Utils.parsePrice(price_list.bar500g),
                bar1000g: Utils.parsePrice(price_list.bar1000g),
                coinOneOunce: Utils.parsePrice(price_list.coinOneOunce)
            });
        });
    },
    setPriceList(state, response) {
        state.price_list = {};
        let result = {};

        result.id = response.id;
        result.validFrom = response.validFrom;
        result.validTo = response.validTo;
        result.saving1g = response.saving1g;
        result.products = [];

        let regex = [
            /bar1g/gi,/bar2g/gi,/bar5g/gi,/bar10g/gi,/bar20g/gi,
            /bar50g/gi,/bar100g/gi,/bar250g/gi,/bar500g/gi,/bar1000g/gi,/coinOneOunce/gi
        ];

        for (let i=0; i<regex.length; i++) {

            let filtered = Object.keys(response).filter(k => k.match(regex[i]));
            result.products.push({
                    product: filtered[0].toString(),
                    price: parseFloat(response[filtered[0]]).toFixed(2),
                    purchase: parseFloat(response[filtered[1]]).toFixed(2),
                    margin: parseFloat(response[filtered[2]]).toFixed(2)
                });
        }

        state.price_list = result;
    },
    setPriceMarginList(state, response) {
        state.price_margin_list = response
    },
    setCurrentPriceList(state, response) {
        state.current_price_list = response
    },

    clearPriceLists(state) {
        state.price_lists = {total_count: 0, items: []};
    },
    clearCurrentPriceList(state) {
        state.current_price_list = {};
    },
    clearPriceList(state) {
        state.price_list = {};
    }
};

const getters = {
    getPriceLists(state) {
        return state.price_lists;
    },

    getCurrentPriceList(state) {
        return state.current_price_list;
    },

    getPriceList(state) {
        return state.price_list;
    },

    getPriceMarginList(state) {
        return state.price_margin_list;
    }
};

export const price_lists = {
    state,
    actions,
    mutations,
    getters
};