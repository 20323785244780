import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getUsers(filter_data, cancel_request) {
    return ApiService.cancellableGet("/users" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getUser(id) {
    return ApiService.get("/users/" + id);
}

export function updateUser(user_id, data) {
    return ApiService.put("/users/" + user_id, data)
}