<template>
    <v-container fluid class="pa-0 ma-0">

        <!--TOOLBAR-->
        <div class="data-table-toolbar" :class="theme">

            <!--MESSAGE BOX-->
            <v-alert v-if="message_box !== null && !select"
                     dense text type="info" color="gold" :icon="message_box.icon" class="my-0 mb-1 mx-3">
                <span v-html="message_box.msg"/>
            </v-alert>

            <!--ACTION BUTTONS-->
            <div v-if="!mobilActionMenu && !select">
                <v-row v-if="action_menu.length" no-gutters class="pt-0 mb-1 ml-4">
                    <ActionButtons v-for="(button, i) in filteredButtons" :key="i"
                                   :item="button" state="init"
                                   @action="toFunc($event)"
                                   :dialog_settings="getDialogSettings"
                    />
                </v-row>
            </div>

            <!--FILTER-->
            <v-container fluid class="px-0 pb-0">
                <v-menu v-if="mobilActionMenu && !select" content-class="mobil-action-menu gold"
                        v-model="mobil_menu"
                        :close-on-content-click="true"
                        :nudge-width="250"
                        transition="slide-y-transition"
                        offset-y :close-on-click="true"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" style="position: absolute; top: 4px; left: 12px;">
                            <v-icon color="gold">mdi-menu</v-icon>
                        </v-btn>
                    </template>

                    <ActionButtons v-for="(button, i) in filteredButtons" :key="i"
                                   :item="button" state="init"
                                   @action="toFunc($event)"
                    />

                </v-menu>
                <ToolbarFilter v-if="show_report_filter === false"
                               :filter="toolbar_filter"
                               :groups="toolbar_filter_groups"
                               :preselected="toolbar_filter_preselected"
                               :counts="total_count"
                               :progress="progress"
                               :name="localize"
                               :selector="select"
                />
                <ToolbarFilterReport v-else
                                     :filter="toolbar_filter"
                                     :groups="toolbar_filter_groups"
                                     :preselected="toolbar_filter_preselected"
                                     :counts="total_count"
                                     :progress="progress"
                                     :name="localize"/>
            </v-container>
        </div>

        <!--DATA TABLE-->
        <div>
            <v-data-table
                :items="data"
                hide-default-footer
                :items-per-page="toolbar_filter.limit"

            >
                <template v-slot:body="{ items }" v-if="data.length">
                    <div v-if="permission.length === 0 || checkPermissions(permission)">
                        <section v-for="(item, i) in items" :key="i" @click="select ? selectItem(item, emiter) : editItem(item)">
                            <Card :card="card" :item="item" :keys="header" :state="item.state" />
                        </section>
                    </div>
                    <div v-else>
                        <section v-for="(item,i) in items" :key="i">
                            <Card :card="card" :item="item" :keys="header" :state="item.state" class="no-cursor-select" />
                        </section>
                    </div>
                </template>
                <template v-slot:no-data>
                    {{ getNoTableDataText }}
                </template>
            </v-data-table>
            <v-pagination v-if="(toolbar_filter.limit - total_count) <= 0"
                          v-model="toolbar_filter.page"
                          :length="Math.ceil(total_count / toolbar_filter.limit)"
                          :total-visible="10"
                          color="gold"
                          class="py-3 pb-5"
            />
            <div v-else class="py-6"></div>

            <v-dialog v-model="dialog_edit" ref="dialog_edit" v-bind="getDialogSettings" scrollable>
                <v-card v-if="dialog_edit" tile>
                    <v-toolbar fixed dark tile dense color="gold" style="z-index: 10; flex: unset; border-bottom: 3px solid rgba(0,0,0,0.1) !important;">
                        <v-btn plain icon dark @click="editCancel">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title class="pl-0">{{ getToolbarTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <rgg-table-dialog :component="targetDialog"
                                          :id="edit_item_id"
                                          :card_item="edit_item"
                                          edit
                                          @action="toFunc($event)"
                                          :advanced_menu="advanced_menu"
                                          @table_dialog="toFunc($event)"
                        />

                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

    </v-container>

</template>

<script>
import ActionButtons from "@/components/ActionButtons";
//import AdvancedMenu from "@/components/AdvancedMenu";
import ToolbarFilter from "@/components/ToolbarFilter";
import ToolbarFilterReport from "@/components/ToolbarFilterReport";
import Card from "@/components/Card";
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";

export default {
    name: "DataTable",
    components: {
        ActionButtons,
        //AdvancedMenu,
        ToolbarFilter,
        ToolbarFilterReport,
        Card
    },
    props: {
        toolbar_filter: Object,
        toolbar_filter_groups: Array,
        toolbar_filter_preselected: Array,
        data: undefined,
        header: undefined,
        total_count: Number,
        progress: Boolean,
        action_menu: Array,
        info: Boolean,
        card: undefined,
        card_target: Object,
        dialog: undefined,
        dialog_settings: undefined,
        localize: String,
        advanced_menu: Object,
        select: Boolean,
        emiter: String,
        permission: Array,
        message_box: Object,
        show_report_filter:  {
            type: Boolean,
            default: false
        }
    },
    mixins: [AuthMixin],
    data: () => ({
        mobil_menu: false,
        dialog_edit: false,
        edit_item: {},
        edit_item_id: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        toolbar_title: "",
        nodata_message: undefined,
        dialog_settings_default: {
            transition: "dialog-bottom-transition",
            fullscreen: true,
            scrollable: true,
            persistent: true
        }
    }),
    computed: {
        targetDialog(){
            if( this.card_target ) {
                return this.card_target[this.edit_item.raw_data.type];
            }

            return this.dialog;
        },
        getToolbarTitle() {
            return this.toolbar_title;
        },
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        },
        mobilActionMenu() {
            if (this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height) {
                return this.$vuetify.breakpoint.height < 451 && this.filteredButtons.length
            } else {
                return this.$vuetify.breakpoint.width < 451 && this.filteredButtons.length
            }
        },
        filteredButtons() {
            let filteredButtons = []
            for (let i = 0; i < this.action_menu.length; i++) {
                if (this.action_menu[i].permissions.length === 0 || this.checkPermissions(this.action_menu[i].permissions)) {
                    if (this.action_menu[i].conditions.length === 0) {
                        filteredButtons.push(this.action_menu[i]);
                    } else {
                        let passed = true
                        for (let j = 0; j < this.action_menu[i].conditions.length; j++) {
                            if (this.$store.getters[this.action_menu[i].conditions[j]] === false) {
                                passed = false
                                break
                            }
                        }

                        if (passed) {
                            filteredButtons.push(this.action_menu[i]);
                        }
                    }
                }
            }

            return filteredButtons;
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR)) {
                return true;
            }

            return !this.edit;
        },
        products() {
            return this.$route.name.split('_')[0] === 'products';
        },

        getNoTableDataText() {
            return this.$t(this.nodata_message);
        },
        getDialogSettings() {
            if(this.dialog_settings) {
                Object.assign(this.dialog_settings_default, this.dialog_settings);
            }

            return this.dialog_settings_default;
        },

        advancedMenu() {
            return this.advanced_menu;
        }
    },
    watch: {
        toolbar_filter: {
            immediate: true,
            deep: true,
            handler(val) {
                //let result = 'notification.' + this.$route.name + '_' + this.card.toLowerCase();
                if( Object.prototype.hasOwnProperty.call(val.toolbar_filter, 'search') ) {
                    //this.nodata_message =  result + '_search';
                    this.nodata_message = 'notification.no_table_data_search';
                } else {
                    //this.nodata_message = result;
                    this.nodata_message = 'notification.no_table_data';
                }
            }
        },

    },
    methods: {
        test(e) {
            this.$root.$emit(this.advanced_menu.emiter, this.advanced_menu.buttons[e].method);
        },
        toFunc(e) {
            this[e.func](e.data);
        },
        setToolbarTitle(_item_title) {
            this.toolbar_title = _item_title;
        },
        editItem: function (_item) {
            this.edit_item = _item;
            this.edit_item_id = this.edit_item.id;
            this.dialog_edit = true;
            this.$root.$emit('item-report', _item);
        },
        openDialog: function(_id) {
            this.edit_item_id = _id;
            this.dialog_edit = true;
        },
        editCancel: function () {
            this.dialog_edit = false;
            this.edit_item = null;
            this.$root.$emit('reload');
        },
        saveItem() {
            this.dialog_edit = false;
            this.edit_item = null;
            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
        },
        createItem(_item) {
            this.dialog_edit = false;
            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
            console.debug(_item);
        },
        createItemCancel() {
            this.dialog_edit = false;
        },
        updateItem(_item) {
            this.dialog_edit = false;
            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
            console.debug('Item ID', _item, 'updated');
        },
        deleteItem(_item) {
            this.dialog_edit = false;
            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
            console.debug('Item ID', _item, 'deleted');
        },
        close() {
            this.dialog_edit = false;
            this.$root.$emit('overlay', false);
        },
        selectItem(_item, _source) {
            this.edit_item = _item;
            this.edit_item_id = this.edit_item.id;
            this.$root.$emit(_source, this.edit_item_id, _item, this._uid);
        },

        closeDialog(_notification) {
            this.dialog_edit = false;
            this.$root.$emit('overlay', false);
            this.$root.$emit('reload');
            this.$root.$emit('notification', {
                type: 'success',
                icon: 'info',
                msg: 'notification.' + _notification
            });
        }
    },
    mounted() {
        this.$root.$on('open-dialog', (_id, _uid) => {
            if (this._uid === _uid) {
                this.openDialog(_id);
            }
        });
    },
    destroyed() {
        //this.$store.dispatch('clear' + this.section);
        this.$root.$off('open-dialog');
    }
}
</script>

<style>
    .strip {
        display: unset;
    }

    .data-table-toolbar {
        position: sticky;
        top: 106px;
        z-index: 2;
    }
</style>