<template>
    <!--<div v-if="items.length !== 0">-->
        <div v-if="items.length !== 0" style="position: fixed; top: 10px; z-index: 20; right: 128px;">
        <div class="text-center">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small @click="menu = attrs['aria-expanded']"
                           text depressed
                           class="white gold--text"
                           v-bind="attrs"
                           v-on="on"
                    >
                        <v-icon>mdi-cog</v-icon>
                        <span v-if="$vuetify.breakpoint.width > 600" class="pl-1">{{ $t('common.advanced_menu') }}</span>
                    </v-btn>
                </template>
                <v-list >
                    <v-list-item
                        v-for="item in buttons"
                        :key="item.index" @click="handle(item.index);"
                    >
                        <v-list-item-title>
                            {{ $t("advanced_menu." + item.label) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdvancedMenu",
    props: {
        items: Array,
        handle: Function
    },
    data: () => ({
        buttons: [],
        menu: false
    }),

    computed: {
        filteredItems() {
            return this.buttons;
        }
    },

    watch: {
        buttons_: function(val) {
            if(val.length === 0) {
                this.$root.$emit('am-refresh');
            }
        },
        menu: function() {
            this.getButtons();
        }
    },

    created() {},
    methods: {
        getButtons() {
            this.buttons = [];
            for(let i=0; i<this.items.length; i++ ) {
                if( Object.prototype.hasOwnProperty.call(this.items[i], 'disable_item') && this.items[i].disable_item ) {
                    continue;
                }

                let item = this.items[i];
                item.index = i;
                this.buttons.push(item);
            }

            return this.buttons;
        }
    },
    mounted() {
        //this.getButtons();
        //this.$root.$emit('am-refresh', 'test');
    },
    updated() {

    }
}
</script>