import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getBankTransactions(filter_data, cancel_request) {
    return ApiService.cancellableGet("/transactions" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function uploadBankStatement(form_data) {
    return ApiService.upload('/transactions/statement', form_data)
}

export function getBankTransactionsForStatement(filter_data, cancel_request) {
    return ApiService.cancellableGet("/transactions/statement/transactions" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function updateBankTransactionRecord(index, data) {
    return ApiService.put("/transactions/statement/transactions/" + index, {value:data})
}

export function processBankStatement() {
    return ApiService.put('/transactions/statement/process')
}

export function getBankTransactionProcessingProgress() {
    return ApiService.get('/transactions/statement/progress')
}