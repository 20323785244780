<template>
    <v-container v-if="isAuthenticated()" class="pa-0" fluid>
        <v-row align="center" class="cl-title white elevation-6" no-gutters :class="theme">
            <span class="headline gold--text text-uppercase font-weight-light pa-2">
                {{ $t('title.sellers_hierarchy') }}
            </span>
            <v-spacer></v-spacer>

            <div class="pl-2 pr-4">
                <span class="caption gold--text">
                    {{ $t(g_getRouteName() + '.counts') }}: <strong>{{ getCounter }}</strong>
                </span>
            </div>

        </v-row>

        <!--HIERARCHY TREE HERE-->
        <v-card class="formular" flat>
            <!--SELLER UPLINE-->
            <v-row v-if="sellerUpLine.length > 0" :class="{ 'rgg-grey' : this.$vuetify.theme.dark }"
                   class="grey lighten-4 rounded mb-2 ma-4">
                <v-col cols="12" class="body-1 gold--text font-weight-medium py-2">
                    {{ $t('title.upline') }}
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-card>
                        <v-card-text>
                            <v-row v-for="(seller, i) in sellerUpLine" :key="seller.id"
                                   :style="'margin-left: calc(20px * ' + i + ');'">

                                <span class="gold rounded white--text my-1 px-3"
                                      :class="{ 'rgg-grey font-weight-medium gold--text' : $vuetify.theme.dark }">
                                    {{ seller.name + ' • (' + seller.position + ')' }}
                                </span>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>

            <!--TREE-->
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-treeview id="hierarchy"
                                    ref="tree"
                                    :open.sync="open"
                                    :items="sellers"
                                    item-key="id"
                                    item-text="deep"
                                    :load-children="fetch"
                                    return-object
                                    :active.sync="active"
                                    expand-icon="mdi-chevron-down"
                        >
                            <template v-slot:label="{item}">
                                <div @click="showSellerDetail(item)">
                                    <HierarchyCard :item="item"
                                                   :leaf="leaf_id"
                                                   :loading="loading"
                                                   :class="{ 'rgg-grey' : $vuetify.theme.dark }"
                                    />
                                </div>
                            </template>
                        </v-treeview>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
        <v-dialog v-model="dialog_seller" fullscreen>
            <v-card v-if="dialog_seller">
                <v-toolbar dark tile dense color="gold" style="position: sticky; top: 0; z-index: 10; flex: unset;">
                    <v-btn
                        icon dark
                        @click="dialog_seller = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ seller_item.name }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <SellerDialog :id="seller_item.id" edit/>
                </v-card-text>
            </v-card>

        </v-dialog>

    </v-container>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import {getChildSellers, getSellersCount} from "@/api/sellers";
import {Utils} from "@/services/utils";
import HierarchyCard from "@/components/cards/HierarchyCard";
import SellerDialog from "@/components/dialogs/SellerDialog";

export default {
    name: "HierarchyView",
    components: {
        HierarchyCard,
        SellerDialog
    },
    data: () => ({
        dialog_seller: false,
        sellers: [],
        open: [],
        active: [],
        loading: false,
        leaf_id: undefined,
        seller_item: null,
        hide_inactive: false,
        search: 'true',
        counts: 0
    }),
    mixins: [AuthMixin],

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'grey darken-4' : 'white';
        },
        sellerUpLine() {
            return this.$store.getters.getSellerUpLine
        },
        content() {
            return document.getElementById('hierarchy');
        },
        getCounter() {
            return this.counts;
        }
    },

    watch: {
        content(newVal) {
            return newVal;
        }
    },

    methods: {
        showSellerDetail(_item) {
            this.seller_item = _item;
            this.dialog_seller = true;
        },

        fetch(seller) {
            this.leaf_id = seller.id;
            this.loading = true;

            getChildSellers(seller.id).then(response => {
                response.data.items.forEach(function (childSeller) {

                    setTimeout(() => {
                        seller.children.push({
                            name: Utils.getFullName(childSeller) + " (" + childSeller.position + ")",
                            id: childSeller.id,
                            active: childSeller.status.active,
                            disabled: childSeller.disabled,
                            career_points: childSeller.status.careerPointsTotal,
                            total_career_points: childSeller.status.totalCareerPointsTotal,
                            children: childSeller.hasChildren ? [] : null
                        });

                    }, 10)

                });
            }).then(setTimeout(() => (this.loading = false), 500))
                .then(this.$refs.tree.nodes[seller.id].isOpen = true)
        },

        closeRed() {
            let nodes = document.querySelectorAll('.state-red').length;

            if (nodes) {
                let leafs = document.getElementsByClassName('state-red')
                if (!this.hide_inactive) {
                    for (let i = 0; i < nodes; i++) {
                        let element = leafs[i]
                            .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
                            .classList;

                        element.remove('close-state');
                    }
                } else {
                    for (let i = 0; i < nodes; i++) {
                        leafs[i]
                            .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
                            .classList.add('close-state');
                    }
                }
            }
        }

    },

    mounted() {
        let id = this.$store.getters.getUserId;

        this.$store.dispatch("getSellerUpLine", id);
        this.$store.dispatch("getSellerInfo").then(() => {
            getSellersCount(id).then(response => {
                this.counts = response.data;
                this.sellers.push({
                    name: this.$store.getters.getUserDisplayName + " (" + this.$store.getters.getUserPosition.split(" - ")[0] + ")",
                    id: id,
                    active: this.$store.getters.getSellerInfo.status.active,
                    disabled: false,
                    career_points: this.$store.getters.getSellerInfo.status.careerPointsTotal,
                    total_career_points: this.$store.getters.getSellerInfo.status.totalCareerPointsTotal,
                    children: this.counts === 1 ? null : []
                });
                this.leaf_id = this.sellers[0].id;
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        })
    }
}
</script>

<style>
.v-treeview-node__root {
    min-height: auto !important;
}

.tree .node-details {
    padding: 0 8px 0 8px;
}

.tree .node .node-details > span {
    color: black;
}

.close-state {
    display: none;
}

</style>