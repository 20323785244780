<template>
    <component
        :is="path"
        :item="item"
        :keys="keys"
        :state="state"
        class="cursor-select"
    />
</template>

<script>
export default {
    name: "Card",
    props: {
        item: Object,
        keys: undefined,
        state: undefined,
        card: String
    },
    computed: {
        path() {
            return () => import('@/components/cards/' + this.card);
        }
    }
}
</script>

<style>
    .cursor-select {
        cursor: pointer;
    }
    .cursor-select.no-cursor-select {
        cursor: default !important;
    }
</style>