<template>
  <component :is="path" :edit="edit" :type="type" :id="id" :card_item="card_item" :options="options" :advanced_menu="advanced_menu" />
</template>

<script>

export default {
    name: "TableDialogs",
    props: {
        component: String,
        //data: undefined,
        edit: Boolean,
        type: String,
        id: undefined,
        card_item: undefined,
        options: Object,
        advanced_menu: Object,
        call: Function
    },

    computed: {
        path() {
            return () => import('@/components/dialogs/' + this.component);
        }
    }
}
</script>