import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getAgreements(filter_data, cancel_request) {
    return ApiService.cancellableGet("/agreements" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getAgreement(id) {
    return ApiService.get("/agreements/" + id);
}

export function sendAgreementCustomerEmail(id) {
    return ApiService.put("/agreements/" + id + "/send-customer-email");
}

export function sendAgreementCertificateEmail(id) {
    return ApiService.put("/agreements/" + id + "/send-certificate-email");
}

export function sendAgreementMonthlyEmail(id, data) {
    return ApiService.put("/agreements/" + id + "/send-monthly-email", data);
}

export function agreementActivation(id) {
    return ApiService.put("/agreements/" + id + "/activation");
}

export function reallocateGrams(id, data) {
    return ApiService.put("/agreements/" + id + "/reallocate-grams", data);
}

export function transferGoldPurchase(id, data) {
    return ApiService.put("/agreements/" + id + "/transfer-gold-purchase", data);
}

export function getGoldPurchasesForAgreement(id) {
    return ApiService.get("/agreements/" + id + "/gold-purchases");
}

export function downloadAgreementInvoice(id) {
    return ApiService.download("/agreements/" + id + "/invoice", "nakup_fa.pdf");
}

export function createAgreement(data) {
    return ApiService.post("/agreements", data)
}

export function updateAgreement(id, data) {
    return ApiService.put("/agreements/" + id, data)
}