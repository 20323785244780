import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getPersonalWebs} from "@/api/personal_webs";

const state = {
    personal_webs_cancel_request: new CancelRequest(),
    personal_webs: {total_count: 0, items: []},
};

const actions = {
    getPersonalWebs(context, data) {
        context.state.personal_webs_cancel_request.cancelAndCreateToken();
        return getPersonalWebs(data, context.state.personal_webs_cancel_request)
            .then(response => {
                context.state.personal_webs_cancel_request.resetCancelToken();
                context.commit('setPersonalWebs', response.data);
            })
    },

    clearPersonalWebs(context) {
        context.commit('clearPersonalWebs');
    }
};

const mutations = {
    setPersonalWebs(state, response) {
        state.personal_webs.total_count = response.totalCount
        state.personal_webs.items = []

        response.items.forEach(function (personal_web) {

            let duration
            if (personal_web.years === 100 || personal_web.lifetime === true) {
                duration = Utils.localizeText("products_personal_webs.lifetime")
            } else {
                let yearKey
                if (personal_web.years === 1) {
                    yearKey = "year";
                } else if (personal_web.years > 1 && personal_web.years < 5) {
                    yearKey = "years_" + personal_web.years;
                } else {
                    yearKey = "years";
                }
                duration = personal_web.years + " " + Utils.localizeText("products_personal_webs.years." + yearKey)
            }

            let validity
            if (personal_web.years === 100 || personal_web.lifetime === true) {
                validity = Utils.localizeText("products_personal_webs.lifetime")
            } else {
                validity = Utils.convertDate(personal_web.validDate)
            }

            let paid
            let card_state = "";
            if (personal_web.inactive === true) {
                paid = Utils.localizeText("products_personal_webs.cancelled")
                validity = Utils.localizeText("products_personal_webs.cancelled")
                card_state = "state-red";
            } else {
                if (personal_web.packageType !== null && personal_web.active === true) {
                    paid = Utils.localizeText("products_packages.type." + personal_web.packageType)
                    card_state = 'state-' + personal_web.packageType.toLowerCase();
                } else {
                    paid = Utils.parsePrice(personal_web.payment)
                }
            }

            state.personal_webs.items.push({
                id: personal_web.id,
                date: Utils.convertDate(personal_web.purchaseDate),
                active: personal_web.active,
                seller: Utils.getFullNameWithPosition(personal_web.owner),
                duration: duration,
                paid: paid,
                validity: validity,
                state: card_state,
                raw_data: personal_web
            });
        });
    },

    clearPersonalWebs(state) {
        state.personal_webs = {total_count: 0, items: []};
    }
};

const getters = {
    getPersonalWebs(state) {
        return state.personal_webs;
    }
};

export const personal_webs = {
    state,
    actions,
    mutations,
    getters
};