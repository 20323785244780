const messages_sk = {
    validations: {
        custom: {
            /*PRODUCT*/
            child_name: {required: "Vyplňte meno dieťaťa"},
            child_personal_id: {
                required: "Vyplňte rodné číslo dieťaťa",
                child_born_limit: 'Dieťa musi mať menej ako 18 rokov'

            },
            product_target_price: {
                required: "Vyplňte cieľovú sumu",
                min_value: "Minimálna suma 10000 €",
                package_t1t2: "Suma musí byť maximálne do výšky zostávajúceho kreditu v balíčku"
            },
            product_target_price_6000: {
                required: "Vyplňte cieľovú sumu",
                min_value: "Minimálna suma 6000 €"
            },
            monthly_payment: {required: "Vyplňte mesačnú splátku"},
            product_selection: {
                required: "Pre výpočet celkovej investície je nutné zadať minimálne jeden z produktov",
                t3: "Pre výpočet celkovej investície je nutné zadať minimálne jeden z produktov",
                package_t3: "Celková investícia musí byť maximálne do výšky zostávajúceho kreditu v balíčku"
            },

            profile_name: {},
            profile_surname: {},
            profile_email: {},
            profile_web_page: {},

            profile_old_password: {},
            profile_new_password: {},
            profile_new_password_again: {},

            user_name: {},
            user_surname: {},
            user_login_email: {
                required: 'Povinný údaj',
                email: 'Pre správne vygenerovanie prihlasovacieho emailu vyplňte meno a priezvisko',
                checkExistingEmail: 'Email už existuje',
                checkInputs: 'Meno a priezvisko musia byť vyplnené'
            },
            user_private_email: {
                required: 'Povinný údaj',
                email: 'Emailová adresa musí byť validná',
                checkExistingEmail: 'Email už existuje',
            },
            user_tel: {},
            user_nation: {},
            user_date_of_birth: {
                required: 'Povinný údaj',
                date_format: 'date format',
                bornLimit: 'Povinný vek je minimálne 18 rokov'
            },
            user_id_number: {},
            user_company_name: {},
            user_street_and_number: {},
            user_city: {},
            user_psc: {},

            reallocate_grams: {
                required: 'Povinný údaj',
                double: 'Hodnota musí byť číslo s max 2 desatinnými miestami',
                max_gold: 'Hodnota nesmie presahovať maximálne množstvo gramov na zmluve'
            },

            old_password: {
                required: "Zadajte svoje aktuálne platné heslo",
                incorrect: "Zadajte správne staré heslo"
            },

            new_password: {
                required: "Zadajte nové heslo",
                rePassword: "Heslá sa musia zhodovať"
            },

            gold_price: {
                required: "Vyplňte cenu",
                double: "Cena musí byť číslo s max 2 desatinnými miestami"
            },
            ounce_price: {
                required: "Vyplňte cenu",
                double: "Cena musí byť číslo s max 2 desatinnými miestami"
            },
            deposit_amount: {
                required: "Povinný údaj",
                amount: "Minimálny počet je 1 a viac a musí byť celé číslo"
            },

            ico: {},
            dic: {},
            iban: {},

            reduction0: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction1: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction2: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction3: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction4: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction5: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction6: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction7: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction8: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },
            reduction9: {
                double: 'Suma musí byť číslo s max 2 desatinýmmi miestami',
                amount_limit: "Suma nesmie presahovať maximálny podiel partnera"
            },

            hint: { /*ak sa necha objekt prazdny, tak sa nahradi _default z messages*/}
        },
        messages: {
            _default: "Povinný údaj",
            double: "Císlo s max 2 desatinýmmi miestami"
        }
    },

    common: {
        yes: "Áno",
        no: "Nie",
        ok: "Potvrdiť",
        save: "Uložiť",
        cancel: "Zrušiť",
        export: "Exportovať",
        close: "Zavrieť",
        create: "Vytvoriť",
        search: "Vyhľadať",
        advanced_menu: "Možnosti",
        registration: "Zaregistrovať",
        buy: "Kúpiť",
        delete: "Odstrániť",
        add: "Pridať",
        record: "Nahrať"
    },

    login: {
        username: "Email",
        password: "Heslo",
        submit: "Prihlásiť",
        error: "Nesprávne meno alebo heslo",
        gdpr: "Ochrana osobných údajov",
        gold_price_list: "Aktuálny cenník zlata"
    },

    user_menu: {
        my_profile: "Môj profil",
        my_career: "Moja kariéra",
        my_email: "Môj email",
        my_page: "Moja webová stránka",
        theme: 'Svetlá téma',
        logout: 'Odhlásiť sa',

        profile_foto: "Profilové foto",
        profile_foto_file: "Vyberte fotografiu",
        profile_foto_info: "Vyberte fotografiu vo formáte .jpg. Optimálny štvorcový rozmer.",
        profile_data: "Osobné údaje",
        profile_password: "Zmena hesla",

        id: "ID",
        name: "Meno",
        surname: "Priezvisko",
        email: "Email",
        phone: "Telefón",

        old_password: "Staré heslo",
        new_password: "Nové heslo",
        new_password_again: "Nové heslo znova",
        change_password: "Zmeniť heslo"

    },

    advanced_menu: {
        placeholder: "Placeholder Item",
        send_purchase_email: "Odoslať nákupný email",
        send_activate_email: "Odoslať aktivačný email",
        send_registration_email: "Poslať registračný email",
        send_status_activity_mail: "Poslať mail so statusom aktivity",
        create_company_email: "Vytvoriť firmený email",
        reset_password: "Reset hesla",
        deactivate: "Deaktivovať",
        activate: "Aktivovať",
        recompute_hierarchy: "Prepočítať hierarchiu",

        send_to_email: "Odoslať na email",
        send_certificate: "Odoslať certifikát",
        send_monthly_report: "Odoslať mesačný výpis",
        transfer_grams: "Presun gramov",
        transfer_purchase: "Presun nákupu",
        gold_purchase: "Nákupy zlata",

        send_package_purchase_mail: "Odoslať nákupný email",
        send_package_activation_mail: "Odoslať aktivačný email",
        deactivate_package: "Deaktivovať balíček",
        reactivate_package: "Reaktivovať balíček",
        delete_package: "Zmazať balíček"
    },

    toolbar_filter: {
        search: "Vyhľadať"
    },

    packages: {
        Silver: "Silver",
        Gold: "Gold",
        Business: "Business",
        Christmas: "Vianočný"
    },

    navigation: {
        agreements: "Zmluvy",
        packages: "Balíčky",
        personal_webs: "Personálne weby",
        report: "Report produkcie",
        videos: "Videá",
        certificates: "Certifikácia",
        career: "Kariéra",
        contest: "Súťaže",
        sellers_list: "Zoznam",
        sellers_hierarchy: "Hierarchia",
        documents: "Dokumenty"
    },

    title: {
        home: "Aktuálne informácie",
        users: "Používatelia",

        products_agreements: "Zmluvy",
        products_packages: "Balíčky",
        products_personal_webs: "Personálne weby",
        products_report: "Report produkcie",

        sellers_list: "Sprostredkovatelia",
        sellers_hierarchy: "Hierarchia sprostredkovateľov",

        gold_price_lists: "Cenníky zlata",
        my_agreements: "Moje zmluvy",

        gold_deposit: "Sklad zlata",
        price_lists: "Cenníky",
        transactions: "Bankové transakcie",
        purchase_reports: "Reporty nákupu zlata",
        salary_reports: "Reporty výplat",

        education_videos: "Videá",
        education_certificates: "Certifikácia",
        documents: "Dokumenty",
        documents_files: "Dokumenty",
        documents_certificates: "Certifikácia",
        contacts: "Kontakty",
        sellers: "Sprostredkovatelia",
        provisions: "Provízie",
        career_career: "Kariéra",
        career_contest: "Súťaže",

        content_management: 'Správa obsahu',
        emails: "Odosielanie emailov",

        upline: "Sponzorská línia"
    },

    content: {
        home: "",
        videos: "Videos",
        certificates: "Certifikácia",
        career: "Kariéra",
        contest: "Súťaže"
    },

    /* -NEW DEFiNiTiON- */
    main_menu: {
        home: 'Domov',
        users: 'Používatelia',
        products: 'Produkty',
        my_agreements: 'Moje zmluvy',
        gold_price_list: 'Cenníky zlata',
        gold_deposit: 'Sklad zlata',
        price_lists: 'Cenníky',
        transactions: 'Bankové transakcie',
        purchase_reports: 'Report nákupu',
        salary_reports: 'Report výplat',
        education: 'Vzdelávanie',
        documents: 'Dokumenty',
        content_management: 'Správa obsahu',
        sellers: "Sprostredkovatelia",
        provisions: "Provízie",
        career: "Kariéra",
        e_shop: "E-Shop",
        contacts: "Kontakty",
        emails: "Odosielanie emailov"
    },

    sellers: {
        dialog: {
            edit_seller: "Detail sprostredkovateľa",
            career_data: "Kariéra",
            position: "Pozícia",
            status: "Status",
            active: "Aktívny",
            inactive: "Neaktívny",
            career_points: "Body do kariéry",
            own_career_points: "Vlastné",
            bonus_career_points: "Bonusové",
            group_career_points: "Skupinové",
            total_career_points: "Celkovo",
            next_position: "Ďalšia pozícia",
            next_position_points: "Potrebné body",
            active_members: "Aktívni členovia",
            provisions: "Provízie",
            salary: "K výplate",
            total_salaries: "Celkový zárobok",
            rent: "Renta",
            boss: "Nadriadený"
        },
    },

    users: {
        title: "Používatelia",

        dialog: {
            edit_user: "Editovať používateľa",
            basic_data: "Základne údaje",
            personal_data: "Osobné údaje",
            company_data: "Firemné údaje",
            agreement_data: "Zmluvné údaje",
            address: "Adresa",

            id: "ID",
            division: "Divízia",
            type: "Typ",
            title: "Titul",
            name: "Meno",
            surname: "Priezvisko",
            login_email: "Prihlasovací email",
            private_email: "Súkromný email",
            tel: "Telefón",
            certificates: "Certifikáty",

            sex: "Pohlavie",
            nation: "Národnosť",
            date_of_birth: "Dátum narodenia",
            id_number: "Rodné číslo",
            op_number: "Číslo OP",
            op_expire: "Platnosť OP",
            passport_number: "Číslo pasu",

            company_name: "Názov spoločnosti",
            ico: "IČO",
            dic: "DIČ",
            ic_dph: "IČ DPH",
            register: "Zápis v registri",

            street_and_number: "Ulica a číslo",
            city: "Mesto",
            psc: "PSČ",
            country: "Krajina",

            position: "Pozícia",
            boss: "Nadriadený",
            choice_boss: "Vybrať nadriadeného",
            change_boss: "Zmeniť nadriadeného",
            start_collaboration: "Začiatok spolupráce",
            iban: "Bankový účet (IBAN)",

            advanced_menu: "Ďalšie možnosti",
            company_partner: "Firemný partner",
            public_profile: "Zverejniť profil na webovej stránke",
            can_purchase_package: "Povoliť kúpu balíčka",
            can_use_purchase_discount: "Povoliť zľavu na jednorázový nákup",
            can_sell_package: "Povoliť predaj balíčka",
            can_sell_product_t5: "Povoliť produkt Zlatá minca",
            can_sell_product_t5_for_free: "Produkt Zlatá minca bez poplatku",
            unlimited_activity: "Neobmedzený status aktivity",

            seller: "Sprostredkovateľ",
            change_seller: "Zmeniť sprostredkovateľa"
        },

        toolbar_filter: {
            company: "Firma",
            companyyes: "Má firmu",
            companyno: "Bez firmy",
            counts: "Počet používateľov",
            type: "Typ",
            customer: "Zákazník",
            seller: "Sprostredkovateľ",
            supporter: "Podpora",
            administrator: "Administrátor",
            search: "Vyhľadať (zadajte ID, meno alebo email)",
            date: "Dátum registrácie",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",
            range: "Registrácia za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",
            role: "Typ",
            status: "Stav",
            deactivated: "Deaktivovaný"
        },
        action_buttons: {
            add_admin: 'Administrátor',
            add_seller: 'Sprostredkovateľ',
            export_sellers: 'Export sprostredkovateľov',
            export_customers: 'Export zakazníkov',

            add_admin_tooltip: 'Vytvoriť nového administrátora',
            add_seller_tooltip: 'Zaregistrovať nového sprostredkovateľa',
            export_sellers_tooltip: 'Do formátu CSV',
            export_customers_tooltip: 'Do formátu CSV',
        },
        table_keys: {
            id: "ID",
            fullname: "Meno a Priezvisko",
            registration: "Registrácia",
            date: 'Dátum registrácie',
            email: 'Email',
            phone: 'Telefón',
            role: 'Typ'
        },
        role: {
            Customer: "Zákazník",
            Seller: "Sprostredkovateľ",
            Supporter: "Podpora",
            Administrator: "Administrátor",
        },

        update_notify: "Používateľ bol úspešne upravený",
        create_notify: "Používateľ bol úspešne zaregistrovaný",

        activate_notify: "Používateľ bol úspešne aktivovaný",
        deactivate_notify: "Používateľ bol úspešne deaktivovaný",
    },

    customers: {
        toolbar_filter: {
            company: "Firma",
            companyyes: "Má firmu",
            companyno: "Bez firmy",
            counts: "Počet zákazníkov",
            type: "Typ",
            customer: "Zákazník",
            seller: "Sprostredkovateľ",
            supporter: "Podpora",
            administrator: "Administrátor",
            search: "Vyhľadať (zadajte ID, meno alebo email)",
            date: "Dátum registrácie",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",
            range: "Registrácia za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",
            role: "Typ"
        },
    },

    seller: {
        position: {
            P1: "P1 - VIP Member",
            P2: "P2 - Consultant",
            P3: "P3 - Senior Consultant",
            P4: "P4 - Team Manager",
            P5: "P5 - Regional Manager",
            P6: "P6 - Division Director",
            P7: "P7 - Executive Director",
            GD: "GD - General Director",
            CD: "CD - Company Director",
            RGGP: "RGGP - RGG Partners",
            RGGC: "RGGC - RGG Company",
        },

        status: {
            inactive: "Neaktívny",
            unlimited: "Neobmedzene",
            active: "Aktívny"
        },

        days: {
            day: "deň",
            days_2: "dni",
            days_3: "dni",
            days_4: "dni",
            days: "dní",
        },

        send_registration_email: "Poslať registračný email",
        send_registration_email_notify: "Registračný email bol úspešne odoslaný",
        create_company_email: "Vytvoriť firemný email",
        create_company_email_notify: "Firemný email bol úspešne vytvorený",
        reset_password: "Reset hesla",
        reset_password_notify: "Heslo bolo úspešne zmenené a poslané na email",
        activate: "Aktivovať",
        activate_notify: "Sprostredkovateľ bol úspešne aktivovaný",
        deactivate: "Deaktivovať",
        deactivate_notify: "Sprostredkovateľ bol úspešne deaktivovaný",
        send_status_activity_mail: "Poslať mail so statusom aktivity",
        status_activity_mail_notify: "Email bol úspešne odoslaný",
        recompute_hierarchy: "Prepočítať hierarchiu",
        recompute_hierarchy_notify: "Prepočítanie hierarchie bolo úspešné",
        update_notify: "Sprostredkovateľ bol úspešne upravený",
        create_notify: "Sprostredkovateľ bol úspešne zaregistrovaný"
    },

    sellers_list: {
        title: "Sprostredkovatelia",

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",
            date: "Dátum registrácie",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",
            counts: "Počet sprostredkovateľov",
            position: "Pozícia",
            p1: "P1 - VIP Member",
            p2: "P2 - Consultant",
            p3: "P3 - Senior Consultant",
            p4: "P4 - Team Manager",
            p5: "P5 - Regional Manager",
            p6: "P6 - Division Director",
            p7: "P7 - Executive Director",
            status: "Status",
            active: "Aktívny",
            inactive: "Neaktívny",
            range: "Registrácia za aktuálny",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",
        },
        action_buttons: {
            add_seller: 'Zaregistrovať sprostredkovateľa',
            add_seller_tooltip: 'Zaregistrovať nového sprostredkovateľa',
        },
        table_keys: {
            id: "ID",
            name: "Meno a Priezvisko",
            registration: 'Dátum registrácie',
            phone: 'Telefón',
            position: 'Pozícia',
            active: 'Status',
            active_days: 'Počet dní aktivity',
            quick_start: 'Rýchly štart',
            certificates: 'Certifikáty'
        }
    },

    sellers_hierarchy: {
        counts: "Počet sprostredkovateľov"
    },

    provisions: {
        title: "Provízie",
        provision: "Provízia",
        rent: "Renta",

        count: {
            toolbar_filter:{
                counts: 'Počet záznamov',
                search: "Vyhľadať (zadajte ID, meno alebo email)",
            }
        },

        dialog: {
            salaries: "Zoznam výplat",
            salaries_count: "Počet výplat",
            salary_date: "Výplata z",

            button_provisions: "Provízie",
            button_rents: "Renty",
            button_packages: "Balíčky",
            button_agreements: "Zmluvy"
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",
            counts: "Počet sprostredkovateľov",
            status: "Status",
            active: "Aktívny",
            inactive: "Neaktívny",
            salary: "Platby",
            hassalary: "S nárokom na výplatu",
        },

        table_keys: {
            id: "ID",
            name: "Sprostredkovateľ",
            active: 'Status',
            active_days: 'Počet dní aktivity',
            current_salary: 'Aktuálna výplata',
            total_salaries: 'Celkový zárobok',
            total_rent: 'Renta',
            total_paid_rent: 'Vyplatená renta',
            amount: 'Suma na výplatu',
            rent_amount: 'Renta',
            paid: 'Vyplatená',
            paid_yes: 'Áno',
            paid_no: 'Nie',
            type: 'Typ',

            target_price: 'Cieľová suma',
            target_investment_t3: 'Celková investícia',
            payments: 'Vklady',
            grams: 'Nakúpené gramy',
            coins: 'Mince',
            points: 'Body',
            date: "Dátum",

            purchase_limit: 'Limit na nákup',
            agreement_limit: 'Limit na zmluvy',
            agreement_count: 'Počet zmlúv'
        }
    },

    products: {
        title: "Produkty",
        credit: "Váš zostávajúci kredit na balíčkové zmluvy je",

        /* Zlaty poklad */
        bar1g: "1g",
        bar2g: "2g",
        bar5g: "5g",
        bar10g: "10g",
        bar20g: "20g",
        bar50g: "50g",
        bar100g: "100g",
        bar250g: "250g",
        bar500g: "500g",
        bar1000g: "1000g",
        coinOneOunce: "1 uncová minca",

        /* Advanced menu - agreements */
        send_email: "Odoslať na email",
        send_email_notify: "Email odoslaný",
        send_certificate: "Odoslať certifikát",
        send_certificate_notify: "Certifikát odoslaný",
        send_monthly_report: "Odoslať mesačný výpis",
        send_monthly_report_notify: "Mesačný výpis odoslaný",
        deactivate: "Deaktivovať",
        transfer_grams: "Presun gramov",
        transfer_grams_notify: "Presunuté",
        activate_notify: "Zmluva bola úspešne aktivovaná",
        deactivate_notify: "Zmluva bola úspešne deaktivovaná",
    },

    my_agreements: {
        dialog: {
            title: "Nákup zlata",
            agreement: "Zmluva"
        },
        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet zmlúv"
        },
        table_keys: {
            payments_total: "Vklady",
            target_investment_t3: 'Celková investícia',
            payments_bonus: "Bonusové vklady",
            grams_total: "Nakúpené gramy",
            coins: "Mince",
            monthly_payment: "Mesačná splátka",
            target_price: "Cieľová suma"
        },

        action_buttons: {
            vop: "Všeobecné obchodné podmienky",
            vop_tooltip: "Všeobecné obchodné podmienky"
        },
    },

    products_agreements: {
        title: "Zmluvy",
        info_text: "Váš zostávajúci kredit na balíčkové zmluvy je ",
        no_table_data: "msg",

        dialog: {
            product_data: 'Produktové údaje',
            agreement_data: 'Zmluvné údaje',
            basic_client_data: 'Základné údaje klienta',
            personal_client_data: 'Osobné údaje klienta',
            company_details: 'Firemné údaje',
            client_address: 'Adresa klienta',
            new_client: "Nový klient",
            existing_clients: "Existujúci klient",
            sellers: "Sprostredkovatelia",
            choice_seller: "Vybrať sprostredkovateľa",
            change_seller: "Zmeniť sprostredkovateľa",
            options: "Mo",

            seller: "Sprostredkovateľ",
            signature_date: "Dátum uzatvorenia",
            agreement_accept: "Uzatvoriť zmluvu",

            transfer: "Presunúť",
            agreement_amount: "Množstvo na zmluve",
            amount: "Množstvo",
            child: "Prijímateľ plnenia dieťa",
            //gold_transfer: "Priradiť zlato do inej zmluvy",
            agreement_confirm: "Presun nákupu zlata",
            target_agreement: "Cieľová zmluva",
            monthly_report: "Odoslať mesačný výpis",
            monthly_report_title: "Mesačný výpis",

            selector_title: {
                users_list_select: "Registrovaní klienti",
                customers_list_select: "Registrovaní klienti",
                agreements_list_select: "Priradiť nákup zlata do inej zmluvy",
                sellers_list_select: "Vybrať sprostredkovateľa"
            }
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",

            date: "Dátum uzatvorenia",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",

            range: "Zmluvy uzatvorené za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",

            product: "Produkt",
            product_gold_t1: "Zlatá rybka",
            product_gold_t2: "Zlatý dôchodok",
            product_gold_t3: "Zlatý poklad",
            product_gold_t4: "Zlatá istota",
            product_gold_t5: "Zlatá minca",

            type: "Typ",
            bonus: "Bonusová",
            converted: "Prenesená",
            frompackage: "Balíčková",
            standard: "Štandardná",

            status: "Status",
            paid: "Zaplatená",
            notpaid: "Nezaplatená",
            cancelled: "Zrušená",
            paymentfree: "Bez poplatku",
            depositfeefree: "Trezor zdarma",

            user: "Obmedziť vyhľadávanie na",
            customer: "Klienta",
            seller: "Sprostredkovateľa",

            counts: "Počet zmlúv"
        },
        action_buttons: {

            productgoldt1: "Zlatá rybka",
            productgoldt2: "Zlatý dôchodok",
            productgoldt3: "Zlatý poklad",
            productgoldt4: "Zlatá istota",
            productgoldt5: "Zlatá minca",
            export_agreements: "Export zmlúv",

            productgoldt1_tooltip: "Sporenie pre deti",
            productgoldt2_tooltip: "Sporenie pre dospelých",
            productgoldt3_tooltip: "Jednorázový nákup",
            productgoldt4_tooltip: "Sporenie pre každého",
            productgoldt5_tooltip: "Sporenie pre každého",
            export_agreements_tooltip: "Export zmlúv",
        },
        table_keys: {
            date: 'Dátum',
            id: 'Číslo zmluvy(VS)',
            product: 'Produkt',
            target_price: 'Cieľová suma',
            target_price_t3: 'Celková suma',
            target_investment_t3: 'Celková investícia',
            points: 'Body',
            customer: 'Meno klienta',
            seller: 'Sprostredkovateľ',
            status: 'Stav',
            inactive: "Zrušená",
            bonus: "Bonusová",
            converted: "Prenesená",
            payment_free: "Bez poplatku",
            not_paid: "Nezaplatená",
            paid: "Zaplatená",
            payments: 'Vklady',
            grams: 'Nakúpené gramy',
            coins: 'Mince',

            /* Produktove udaje */
            fee: 'Poplatok',
            fee_price: "Suma poplatku",
            free_of_charge: 'Bez poplatku',
            monthly_payment: 'Mesačná splátka',
            child_name_surname: "Meno a priezvisko dieťaťa",
            child_identification_number: "Rodné číslo dieťaťa",
            advance: "Preddavok",
            advance_price: "Suma preddavku",
            investment_target: "Celková investícia",
            multiplier: "Zadajte počet kusov",
            repayments: "Na splátky",

            /* Zmluvne udaje */
            online_agreement_number: 'Číslo online zmluvy',
            paper_agreement_number: 'Číslo papierovej zmluvy',
            agreement_signing: 'Dátum podpisu zmluvy',
            bank_account: 'Bankový účet (IBAN)',
            deposit: 'Depozit/Odber',

            /* Zakladne udaje klienta */
            type: 'Typ',
            title: 'Titul',
            name: 'Meno',
            surname: 'Priezvisko',
            email: 'Email',
            tel: 'Telefón',

            /* Osobne udaje klienta */
            sex: 'Pohlavie',
            nationality: 'Národnosť',
            date_of_birth: 'Dátum narodenia',
            identification_number: 'Rodné číslo',

            /* Firemne udaje */
            company_name: 'Názov spoločnosti',
            ico: 'IČO',
            dic: 'DIČ',
            icdph: 'IČ DPH',
            entry_in_the_register: 'Zápis v registri',

            /* Adresa klienta */
            street_and_number: 'Ulica a číslo',
            city: 'Mesto',
            post_code: 'PSČ',
            country: 'Krajina',
        },

        days: {
            day: "deň",
            days_2: "dni",
            days_3: "dni",
            days_4: "dni",
            days: "dní",
        },

        status: {
            inactive: "Zrušená",
            bonus: "Bonusová",
            converted: "Prenesená",
            payment_free: "Bez poplatku",
            not_paid: "Nezaplatená",
            paid: "Zaplatená",
        },

        gold_type: {
            saving_1g: "Sporenie",
            bar_1g: "1g tehlička",
            bar_2g: "2g tehlička",
            bar_5g: "5g tehlička",
            bar_10g: "10g tehlička",
            bar_20g: "20g tehlička",
            bar_50g: "50g tehlička",
            bar_100g: "100g tehlička",
            bar_250g: "250g tehlička",
            bar_500g: "500g tehlička",
            bar_1000g: "1000g tehlička",
            coin_one_ounce: "1 uncová minca",
        },

        gold_purchase: {
            bonus: "Nákup bonusového zlata",
            regular: "Nákup zlata",
            repurchase: "Odkúpenie zlata",
            grams: "g",
            pieces: "ks"
        },

        product: {
            ProductGoldT1: "Zlatá rybka",
            ProductGoldT2: "Zlatý dôchodok",
            ProductGoldT3: "Zlatý poklad",
            ProductGoldT4: "Zlatá istota",
            ProductGoldT5: "Zlatá minca",
        },

        create_notify: "Zmluva bola úspešne vytvorená",
        update_notify: "Zmluva bola úspešne uložená",

        coins_at_customer: "u zákazníka"
    },

    products_packages: {
        title: "Balíčky",
        count: {toolbar_filter: {counts: "Počet záznamov"}},

        dialog: {
            buy: "Kúpiť balíček",
            package: "Balíček",
            silver: "Silver",
            gold: "Gold",
            business: "Business",
            p2: "II. platová trieda P2",
            p3: "III. platová trieda P3",
            p4: "IV. platová trieda P4",

            point_1: "Webová stránka na {year} rokov v hodnote {price} €",
            point_1a: "Webová stránka doživotne",

            point_2: "{target_price} € sporiaci program v zlate v hodnote {price} €",
            point_3: "{target_price} € BONUSOVÝ sporiaci program v zlate v hodnote {price} €",
            point_4: "možnosť rozdelenia cieľových súm sporiacich programov\n(násobky 10 000 €)",
            point_5: "5%-ný BONUS v zlate každý rok na všetky sporiace programy",
            point_6: "renta",

            point_7: "jednorazové nákupy zlata do výšky 80 000 € bez 5% poplatku (4 000 €)",
            point_7a: "doživotné jednorazové nákupy zlata bez 5% poplatku (min. 25 000 €)",

            point_8: "P4 bez podmienky 3 x P2",
            point_9: "status aktivity navždy",

            target_price: "Celková hodnota",
            now: "Teraz len",

            choice_package: "Vybrať balíček",
            choice_user: "Vybrať používateľa",
            change_seller: "Zmeniť sprostredkovateľa",
            download_invoice: "Stiahnuť faktúru",
            agreements_list: "Zoznam zmlúv"
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",

            date: "Dátum nákupu",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",

            range: "Nákup za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",
            type: "Typ",
            status: "Stav",
            silver: "Silver",
            gold: "Gold",
            business: "Business",
            christmas: "Vianočný",
            paid: "Zaplatený",
            notpaid: "Nezaplatený",
            cancelled: "Zrušený",

            counts: "Počet balíčkov"
        },

        action_buttons: {

            packages: "Kúpiť balíček",
            packages_tooltip: "Kúpiť balíček Silver, Gold, Business",
            export: "Export balíčkov",
            export_tooltip: "Export balíčkov"
        },

        table_keys: {
            date: 'Dátum',
            id: 'ID',
            points: 'Body',
            seller: 'Sprostredkovateľ',
            status: 'Stav',
            paid: "Zaplatené",
            active: "Aktívny",
            type: "Typ",
            purchase_limit: "Limit na nákup",
            agreement_limit: "Limit na zmluvy",
            agreement_count: "Počet zmlúv"
        },

        days: {
            day: "deň",
            days_2: "dni",
            days_3: "dni",
            days_4: "dni",
            days: "dní",
        },

        status: {
            inactive: "Zrušená",
            bonus: "Bonusová",
            converted: "Prenesená",
            payment_free: "Bez poplatku",
            not_paid: "Nezaplatená",
            paid: "Zaplatená",
        },

        unlimited: "Neobmedzený",
        cancelled: "Zrušený",
        type: {
            Silver: "Silver",
            Gold: "Gold",
            Business: "Business",
            Christmas: "Vianočný",
        },

        purchase_validation: "Vyberte prosím typ balíčka.",
        purchase_notify: "Balíček bol úspešne zakúpený. Informácie o platbe boli zaslané na váš email.",
        purchase_error: "Kúpa balíčka vybraným sprostredkovateľom už nie je možná.",

        email_successfully_sent: "Email bol úspešne odoslaný",
        package_deactivated: "Balíček bol úspešne deaktivovaný",
        package_reactivated: "Balíček bol úspešne reaktivovaný",
        package_deleted: "Balíček bol úspešne zmazaný",
    },

    products_personal_webs: {
        title: "Personálne weby",

        dialog: {
            expire: "Platnosť",
            unlimited: "Platná doživotne"
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",

            date: "Dátum nákupu",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",

            range: "Nákup za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",

            status: "Stav",
            paid: "Zaplatený",
            notpaid: "Nezaplatený",
            cancelled: "Zrušený",

            package: "V balíčku",
            silver: "Silver",
            gold: "Gold",
            business: "Business",
            christmas: "Vianočný",
            years: "Samostatne",
            years1: "1 rok",
            years2: "2 roky",
            years5: "5 rokov",
            yearslifetime: "Doživotne",

            counts: "Počet personálnych webov"
        },

        action_buttons: {
            export: "Export personálnych webov",
            export_tooltip: "Export personálnych webov"
        },

        table_keys: {
            date: 'Dátum',
            id: 'ID',
            seller: 'Sprostredkovateľ',
            active: 'Aktívny',
            paid: "Zaplatený",
            duration: 'Obdobie',
            validity: 'Aktivný do'
        },

        years: {
            year: "rok",
            years_2: "roky",
            years_3: "roky",
            years_4: "roky",
            years: "rokov",
        },

        lifetime: "Doživotne",
        cancelled: "Zrušený",
    },

    products_report: {
        title: "Report produkcie",

        count: {toolbar_filter: {counts: "Počet záznamov"}},

        dialog: {
            button_agreements: "Zmluvy",
            button_packages: "Balíčky",
            seller_selector: "Výber sprostredkovateľa"
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte ID, meno alebo email)",

            date: "Rozsah produkcie",
            date_from: "Dátum od",
            from: "Dátum od",
            date_to: "Dátum do",
            to: "Dátum do",
            close_picker_menu: "Zavrieť",

            seller: "Sprostredkovateľ",
            sellerId: "Sprostredkovateľ",
            chooseseller: "Vybrať...",

            production_type: "Typ produkcie",
            type: "Typ produkcie",
            team: "Tímová",
            own: "Vlastná",

            agreements: "Zmluvy",
            agreement: "Zmluvy",
            all: "Všetky",
            notpaid: "Nezaplatené",
            paid: "Zaplatené",
            cancelled: "Zrušené",
            bonus: "Bonusové",
            converted: "Prenesené",
            frompackage: "Z balíčka",

            packages: "Balíčky",
            pack: "Balíčky",

            agreement_types: "Typ zmluvy",
            agreementTypes: "Typ zmluvy",
            productgoldt1: "Zlatá rybka",
            productgoldt2: "Zlatý dôchodok",
            productgoldt3: "Zlatý poklad",
            productgoldt4: "Zlatá istota",
            productgoldt5: "Zlatá minca",

            package_types: "Typ balíčka",
            packTypes: "Typ balíčka",
            silver: "Silver",
            gold: "Gold",
            business: "Business",
            christmas: "Vianočný",

            counts: "Počet reportov",

            generate_report: "Vygenerovať report",
            select_seller: "Vybrať sprostredkovateľa..."
        },

        action_buttons: {},

        table_keys: {
            agreement_count: "Počet zmlúv",
            package_count: "Počet balíčkov",
            career_points_total: "Body celkovo",
            career_points_agreements: "Body zo zmlúv",
            target_price_agreements: "Celková cieľová suma",
            career_points_packages: "Body z balíčkov",
            target_price_packages: "Celková suma za balíčky",

            target_price: "Cieľova suma",
            target_investment_t3: 'Celková investícia',
            payments: "Vklady",
            grams: "Gramy",
            coins: "Mince",
            points: "Body",

            purchase_limit: 'Limit na nákup',
            agreement_limit: 'Limit na zmluvy',
        }
    },

    gold_deposit: {
        title: "Sklad zlata",

        dialog: {
            modified_date: "Dátum",
            storage_item: "Skladová položka",
            delete_confirm_message: "Naozaj chcete odstrániť túto skladovú položku?",
            agreement_number: "Vyberte číslo zmluvy",
            assign_agreement: "Priradiť zmluvu"
        },

        toolbar_filter: {
            search: "Vyhľadať (zadajte číslo zmluvy, sériové číslo, meno alebo email)",

            date: "Zmena",
            date_from: "Dátum od",
            date_to: "Dátum do",
            close_picker_menu: "Zavrieť",

            range: "Zmena za",
            day: "Deň",
            week: "Týždeň",
            month: "Mesiac",

            type: "Typ",
            onetimepurchase: "Jednorázový nákup",
            saving_1g: "1g sporenie",
            bar_1g: "1g{option}",
            bar_2g: "2g{option}",
            bar_5g: "5g{option}",
            bar_10g: "10g{option}",
            bar_20g: "20g{option}",
            bar_50g: "50g{option}",
            bar_100g: "100g{option}",
            bar_250g: "250g{option}",
            bar_500g: "500g{option}",
            bar_1000g: "1000g{option}",
            coin_one_ounce: "1 uncová minca",
            saving_coin_one_ounce: "1 uncová minca sporenie",
            goldbrick: " tehlička",

            deposit_type: "Stav",
            ordered: "Objednané",
            in_deposit: "Sklad priradené",
            at_customer: "U zákazníka",
            free: "Sklad voľné",
            company: "Sklad firemné",
            depositall: "Sklad spolu",

            counts: "Počet"
        },

        action_buttons: {
            add_gold: "Pridať zlato na sklad",
            add_gold_tooltip: "Pridať zlato na sklad",
            assign_gold: "Priradiť mince do sporenia",
            assign_gold_tooltip: "Priradiť mince do sporenia",
            export: "Export skladu",
            export_tooltip: "Export skladu"
        },

        table_keys: {
            modified_date: 'Dátum zmeny',
            type: 'Typ',
            serial_number: 'Sériové číslo',
            deposit_type: 'Stav',
            agreement: 'Číslo zmluvy',
            customer: 'Zákazník',
            comment: 'Poznámka',

            date: "",
            id: "",

        },

        deposit_type: {
            ORDERED: "Objednávka",
            IN_DEPOSIT: "Na sklade",
            AT_CUSTOMER: "U zákazníka",
            FREE: "Voľná",
            COMPANY: "Firemná",
        },

        type: {
            BAR_1G: "1g tehlička",
            BAR_2G: "2g tehlička",
            BAR_5G: "5g tehlička",
            BAR_10G: "10g tehlička",
            BAR_20G: "20g tehlička",
            BAR_50G: "50g tehlička",
            BAR_100G: "100g tehlička",
            BAR_250G: "250g tehlička",
            BAR_500G: "500g tehlička",
            BAR_1000G: "1000g tehlička",
            COIN_ONE_OUNCE: "1 uncová minca",
            SAVING_COIN_ONE_OUNCE: "1 uncová minca sporenie",
        }
    },

    gold_price_lists: {
        title: "Cenníky zlata",
        title_login: "Cenník pre jednorázový nákup platný od {date}",
        hint_login: "Pre sporenie platí cena za 1 gram -10% ({price} €)",

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet cenníkov"
        },

        table_keys: {
            validFrom: 'Cenník platný od',
            validTo: 'Cenník platný do',
            saving1g: "Sporenie",
            saving1g_program: "1g šetriaci program",
            bar1g: "1g tehlička",
            bar2g: "2g tehlička",
            bar5g: "5g tehlička",
            bar10g: "10g tehlička",
            bar20g: "20g tehlička",
            bar50g: "50g tehlička",
            bar100g: "100g tehlička",
            bar250g: "250g tehlička",
            bar500g: "500g tehlička",
            bar1000g: "1000g tehlička",
            coinOneOunce: "1 uncová minca",
        },

        login_price_list: {
            bar1g: "1g tehlička",
            bar2g: "2g tehlička",
            bar5g: "5g tehlička",
            bar10g: "10g tehlička",
            bar20g: "20g tehlička",
            bar50g: "50g tehlička",
            bar100g: "100g tehlička",
            bar250g: "250g tehlička",
            bar500g: "500g tehlička",
            bar1000g: "1000g tehlička",
            coinOneOunce: "1 uncová minca",

            grams: "Hmotnosť",
            price: "Cena"
        }
    },

    price_lists: {
        title: "Cenníky",

        dialog: {
            detail: "Cenník z {date}",
            gold_price_list: "Cenník zlata",
            margin: "Marža (%)",
            price: "cena"
        },

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet cenníkov"
        },

        action_buttons: {
            add_price_list: "Nový cenník",
            add_price_list_tooltip: "Pridať cenník",
            price_margins: "Cenová marža",
            price_margins_tooltip: "Upraviť cenové marže",
        },

        table_keys: {
            validFrom: 'Cenník platný od',
            validTo: 'Cenník platný do',
            saving1g: "Sporenie",
            saving1g_program: "1g šetriaci program",
            bar1g: "1g tehlička",
            bar2g: "2g tehlička",
            bar5g: "5g tehlička",
            bar10g: "10g tehlička",
            bar20g: "20g tehlička",
            bar50g: "50g tehlička",
            bar100g: "100g tehlička",
            bar250g: "250g tehlička",
            bar500g: "500g tehlička",
            bar1000g: "1000g tehlička",
            coinOneOunce: "1 uncová minca",
        },
    },

    transactions: {
        title: "Bankové transakcie",

        dialog: {
            edit_content: "Transakcia",
            processing: "Prebieha spracovávanie výpisu...",
            import_file: "Vyberte súbor s bankovým výpisom"
        },

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet transakcií"
        },

        action_buttons: {
            load_statement: "Nahrať bankový výpis",
            load_statement_tooltip: "Nahrať bankový výpis",
        },

        table_keys: {
            date: 'Dátum zaúčtovania',
            amount: 'Suma',
            symbol: 'Variabilný symbol',
            account: 'Účet odosielateľaa',
            details: 'Poznámka',
            status: 'Stav',
        },

        edit_symbol: "Zmeniť variabilný symbol",

        type: {
            INCOME: "Platba od klientov",
            OUTCOME: "Výplata obchodníkom",
            INCOME_OTHER: "Ostatné príjmy",
            OUTCOME_OTHER: "Ostatné výdaje",
            INCOME_PACKAGE: "Platba za balíček",
            INCOME_PERSONAL_WEB: "Platba za personálny web"
        },

        process: {
            transactionsSaving: "Ukladanie bankových transakcií...",
            salariesPaymentsProcessing: "Spracovávanie vyplatených provízií...",
            agreementsProcessing: "Spracovávanie platieb za zmluvy...",
            packagesProcessing: "Spracovávanie platieb za balíčky...",
            personalWebsProcessing: "Spracovávanie platieb za personálne weby...",
            salariesGeneration: "Generovanie nových výplat...",
            reportGeneration: "Generovanie reportu nákupu...",
            sellerStatusUpdating: "Aktualizácia statusu obchodníkov...",
            sellerPromotionChecking: "Kontrola povýšenia obchodníkov...",
            coinsAllocation: "Priraďovanie mincí do sporenia...",
            exportsGeneration: "Generovanie exportov...",
        }
    },

    purchase_reports: {
        title: "Reporty nákupu zlata",

        dialog: {
            summary: "Sumár",
            savings: "Sporenie",
            one_time_purchase: "Jednorázový nákup",
            title: "Report nákupu zlata",
            recompute: "Prepočítať",
            unit_ounce_price: "Reálna nákupná cena za 1 uncu",
            pieces: "ks",
            prediction: "Predikcia novej ceny za <span class='key_1'>{pieces} ks</span> bude: <span class='key_2'>{totalPayment}</span>"
        },

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet reportov"
        },

        action_buttons: {
            bonus_purchase: "Nákup bonusových gramov",
            bonus_purchase_tooltip: "Nákup bonusových gramov",
        },

        table_keys: {
            date: 'Dátum',
            savings: 'Sporenie',
            payments: 'Platby',
            purchase_price: 'Nákupná cena',
            advance_payments: 'Preddavky/poplatky',
            remaining: 'Zostatok',
            total_remaining: 'Celkový zostatok',
            historical_remaining: 'Historický zostatok',

            agreement_id: "Číslo zmluvy",
            customer: "Zákazník",
            type: "Hmotnosť",
            goldAmount: "Množstvo",
            payment: "Jednotková cena",
            totalPayment: "Celková cena"
        },

        summary: {
            totalPaymentsSaving: "Celková suma platieb na sporenie:",
            totalPaymentsSavingStandard: "Celková suma platieb na sporenie (zaplatená, prenesená):",
            totalPaymentsSavingBonus: "Celková suma platieb na sporenie (bonusová, istota):",
            totalPaymentsSavingProductT5: "Celková suma platieb na sporenie (minca):",
            totalPaymentsPurchase: "Celková suma platieb na jednorázový nákup:",
            totalPaymentsAll: "Celková suma platieb na nákup:",

            totalPriceSaving: "Celková nákupná cena na sporenie:",
            totalPriceSavingStandard: "Celková nákupná cena na sporenie (zaplatená, prenesená):",
            totalPriceSavingBonus: "Celková nákupná cena na sporenie (bonusová, istota):",
            totalPriceSavingProductT5: "Celková nákupná cena na sporenie (minca):",
            totalPricePurchase: "Celková nákupná cena na jednorázový nákup:",
            totalPriceAll: "Celková nákupná cena:",

            cashbackAmount: "5% klientský bonus",

            partnersAmount: "{amount}% renta",
            partnersRealAmount: "{amount}% pridelená renta",
            partnersPaidAmount: "{amount}% vyplatená renta",
            partnersRemainingAmount: "{amount}% zostávajúca renta",
            partnersChargeAmount: "Zostatok pre partnerov (preddavky, poplatky):",

            totalBalanceStandard:"Zostatok vo firme (zaplatená, prenesená):",
            totalBalanceBonus:"Zostatok vo firme (bonusová, istota):",
            totalBalanceProductT5:"Zostatok vo firme (minca):",
            totalBalancePurchase:"Zostatok vo firme (jednorázový nákup):",
            totalBalanceCharge:"Zostatok vo firme (preddavky, poplatky):",

            companyAmount:"Zostatok vo firme celkovo:"
        },

        savings: {
            paymentsCount:"Celkový počet platieb:",
            totalPayments: "Celková suma platieb:",
            totalBonusPayments: "Celková suma bonusových platieb:",
            totalPaymentsTotal: "Celkova suma všetkých platieb:",
            unitPrice: "Nákupná cena za 1g:",
            totalGoldAmount: "Celkové množstvo zlata:",
            totalTransferredGoldAmount: "Presun zlata do firmy:",
            totalGoldAmountOunce: "Celkové množstvo zlata v unciach:",
            totalGoldAmountWholeOunce: "Celkový počet mincí:",
            unitOuncePrice: "Reálna nákupná cena za 1 uncu:",
            totalWholePrice: "Celková nákupná cena:",
        }
    },

    salary_reports: {
        title: "Reporty výplat",

        count: {toolbar_filter:{counts: "Počet výplat"}},

        dialog: {
            salaries: "Výplaty",
            export: "Export výplat",
            valid_from: "Dátum od",
            valid_to: "Dátum do"
        },

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet reportov"
        },

        action_buttons: {
            generate_partner_salaries: "Vyplatiť partnerov",
            generate_partner_salaries_tooltip: "Vyplatiť partnerov",
            generate_rent_salaries: "Vyplatiť rentu",
            generate_rent_salaries_tooltip: "Vyplatiť rentu",
        },

        table_keys: {
            date: 'Dátum',
            type: 'Typ reportu',
            total_salaries: 'Počet',
            total_amount: 'Celková suma',
            amount: 'Suma na výplatu',
            rent_amount: 'Renta',
            paid: 'Vyplatená',

            vs: "Variabilný symbol",
            bank_account: "Účet prijímateľa",
            seller: "Sprostredkovateľ",

            purchase_limit: "Limit na nákup",
            agreement_limit: "Limit na zmluvy",
            agreement_count: "Počet zmlúv",
            points: "Body",

            target_price: "Cieľova suma",
            payments: "Platby",
            grams: "Nakúpené gramy",
            coins: "Mince",

            target_investment_t3: "Celková investícia"
        },

        seller_select: "Výber obchodníka",
        no_seller_selected: "Vyberte aspoň jedného obchodníka",

        reduction: "Odpočet",

        type: {
            WEEKLY: "Týždenná výplata",
            RENT: "Výplata renty",
            PARTNER: "Výplata podielu"
        }
    },

    content_management: {
        title: 'Správa obsahu',

        dialog: {
            edit_content: "Upraviť obsah",
            delete_confirm_message: "Naozaj chcete odstrániť tento obsah?",
            type_file: "Vybrať súbor",
            type_file_change: "Zmeniť súbor",
            type_image: "Vybrať obrázok",
            type_image_change: "Zmeniť obrázok",
            video_id: "ID YouTube videa",

            edit_file: "Editovať dokument",
            edit_image: "Editovať obrázok",
            edit_video: "Editovať video",
            edit_article: "Editovať článok",

            //Pri vytvarani dokumentu nebol priradeny ziadny subor...
            edit_file_missing: "Chýbajúci súbor!!! Pravdepodobne nastala chyba pri vytváraní obsahu. Zopakujte výber súboru alebo odstránte obsah a vytvorte od znova"
        },

        toolbar_filter: {
            search: "Vyhľadať",
            counts: "Počet",
            category: "Sekcia",
            videos: 'Videá',
            certificates: 'Certifikácia',
            documents: 'Dokumenty',
            homeseller: 'Domov sprostredkovatelia',
            homecustomer: 'Domov zákazníci',
            homeadministrator: 'Domov administrátori',
            homesupporter: 'Domov servisné centrum',
            contest: 'Súťaže',
            career: 'Kariéra'
        },

        action_buttons: {
            content_add_new: "Pridať obsah",
            content_add_new_tooltip: "Pridať nový obsah",
            content_add_file: "Dokument",
            content_add_file_tooltip: "Pridať nový obsah",
            content_add_image: "Obrázok",
            content_add_image_tooltip: "Pridať nový obsah",
            content_add_video: "Video",
            content_add_video_tooltip: "Pridať nový obsah",
            content_add_article: "Článok",
            content_add_article_tooltip: "Pridať nový obsah",
        },

        table_keys: {
            title: 'Názov',
            title_article: 'Názov článku',
            type: 'Typ',
            category: 'Sekcia',
            text: "Text",
            sort: "Poradie",
            file: "Príloha"
        },

        type: {
            File:'Súbor',
            Video:'Video',
            Image: 'Obrázok',
            Article: 'Článok'
        },

        category: {
            Videos: 'Videá',
            Certificates: 'Certifikácia',
            Documents: 'Dokumenty',
            HomeSeller: 'Domovská stránka sprostredkovatelia',
            HomeCustomer: 'Domovská stránka zákazníci',
            HomeAdministrator: 'Domovská stránka administrátori',
            HomeSupporter: 'Domovská stránka servisné centrum',
            Contest: 'Súťaže',
            Career: 'Kariéra'
        },

        home: {
            administrators: "Administrátori",
            service_center: "Servisné centrum",
            sellers: "Sprostredkovatelia",
            customers: "Zákazníci"
        }
    },

    contacts: {
        boss: "Váš nadriadený",
        seller: "Váš sprostredkovateľ",
        company: "Firemné kontakty",
        contact: "Váš kontakt"
    },

    emails: {
        all: "Všetci",
        sellers: "Sprostredkovatelia",
        customers: "Zákazníci",
        email: "Používateľ",
        user: "<<< Vyberte používateľa ktorému chcete odoslať email ...",
        test: "Test",

        subject: "Predmet",
        attachments: "Pridať súbory",
        send: "Odoslať",
        sending: "Odosielam emaily...",
        zet: "z",

        send_disabled: "Minimálne jeden používateľ musí byť pridaný ako adresát, aby ste mohli odoslať email",

        toolbar_filter: {
            customer: "Zákazník",
            seller: "Sprostredkovateľ",
            supporter: "Podpora",
            administrator: "Administrátor"
        }
    },

    toolbar_title_new: {
        /* USERS */
        add_admin: 'Vytvoriť nového administrátora',
        add_seller: 'Zaregistrovať nového sprostredkovateľa',
        export_sellers: 'Export sprostredkovateľov',
        export_customers: 'Export zakazníkov',
        group_email: 'Skupinový email',


        /* PRODUCTS */
        productgoldt1: "Zlatá rybka - Sporenie pre deti",
        productgoldt2: "Zlatý dôchodok - Sporenie pre dospelých",
        productgoldt3: "Zlatý poklad - Jednorázový nákup",
        productgoldt4: "Zlatá istota - Sporenie pre každého",
        productgoldt5: "Zlatá minca - Sporenie pre každého",
        export_agreements: "Export zmlúv",

        packages: "Kúpiť balíček",
        package_detail: "Zoznam zmlúv",
        personal_web_detail: "Web stránka",

        add_price_list: "Vytvoriť nový cenník zlata",
        add_gold: "Pridať zlato na sklad",

        generate_rent_salaries: "Vyplatiť rentu",
        generate_partner_salaries: "Vyplatiť partnerov",

        content_add_new: "Pridať obsah",
        content_add_file: "Pridať dokument",
        content_add_image: "Pridať obrázok",
        content_add_video: "Pridať video",
        content_add_article: "Pridať článok",

        load_statement: "Nahrať bankový výpis",
        bonus_purchase: "Nákup bonusových gramov"
    },

    table_keys: {
        fullname: "Meno a Priezvisko",
        company: "Firma",
        registration: "Registrácia",
    },

    notification: {
        server_error: "Počas spracovávania požiadavky došlo k chybe. Skontrolujte svoje internetové pripojenie a požiadavku zopakujte neskôr. Ak chyba pretrváva, kontaktujte servisné centrum.",
        form_error: "Vyplňte povinné údaje",
        form_error_correct: "Vyplňte správne povinné údaje",
        old_password_error: "Heslo sa nepodarilo zmeniť. Skontrolujte či ste zadali správne staré heslo",
        password_successful_changed: "Heslo bolo úspešne zmenené",
        profile_image_updated: "Profilové foto bola zmenené",
        generate_report: "Práve prebieha generovanie reportov. Čakajte prosim.",

        no_table_data: "Nie sú k dispozícií žiadne záznamy",
        no_table_data_search: "Výsledky vyhľadavania neposkytujú žiadne záznamy",

        provisions_provisioncard: "",
        provisions_provisioncard_search: "",
        provisions_agreementprovisioncard: "Výplata neobsahuje provízie so zmlúv",
        provisions_agreementprovisioncard_search: "",
        provisions_agreementrentcard: "Výplata neobsahuje podiel na rente",
        provisions_agreementrentcard_search: "",
        provisions_packageprovisioncard: "Výplata neobsahuje provízie s balíčkov",
        provisions_packageprovisioncard_search: "",

        emails_duplicate: "Tento používateľ je už pridaný",
        emails_sent: "Posielanie prebehlo úspešne",
        emails_canceled: "Posielanie bolo prerušené",

        price_list_created: "Nový cenník bol úspešne pridaný",
        price_list_updated: "Cenník bol úspešne uložený",

        gold_deposit_created: "Zlato bolo úspešne pridané",
        gold_deposit_updated: "Skladová položka bola uspešne uložena",
        gold_deposit_deleted: "Skladová položka bola zmazaná",
        gold_deposit_error: "Odstránenie skladovej položky bolo neúspešné",

        recomputed: "Report nákupu bol prepočítaný",
        update_unit_ounce_price: "Nová cena 1 unce bola úspešne zmenená",
        update_report_gold_price: "Jednotková cena bola úspešne zmenená",

        generate_partner_salaries: "Výplata partnerov bola úspešne dokončená",
        generate_partner_salaries_error: "Počas vyplácania partnerov došlo k chybe",
        generate_rent_salaries: "Výplata renty bola úspešne dokončená",
        generate_rent_salaries_error: "Počas vyplácania renty došlo k chybe",

        gold_price_error: "Vypľnte správne jednotkovú cenu",
        ounce_price_error: "Vypľnte správne cenu za 1 uncu",

        deposit_create_error: "Vypľnte povynné údaje správne",

        content_created: "Správa obsahu bola vytvorená",
        content_updated: "Správa obsahu bola uložená",
        content_deleted: "Správa obsahu bola zmazaná",

        transaction_process_cancelled: "Proces spracovania bankových výpisov bol zrušený",
        transaction_process_done: "Proces spracovania bankových výpisov bol úspešne dokončený",

        send_monthly_report: "Mesačný výpis bol úspešne odoslaný",
        send_monthly_report_error: "",

        reallocate_grams: "Gramy boli presunuté",
        transfer_gold_purchase: "Nákup zlata bol presunutý",

        updated_symbol: "Variabilný symbol bol zmenený"
    },

    tooltip: {
        report_seller_clear: "Zrušiť sprostredkovateľa z filtra",
        users_emails: "Pridať adresáta do zoznamu",
        sellers_rent: "Pridať obchodníka do zoznamu",
        gold_deposit_agreement_clear: "Zrušiť čislo zmluvy"
    },

    message_box: {
        gold_deposit: "Firemné mince v sporení: <b>{0} + {1} g</b>",
        purchase_reports: "Celkové množstvo zlata v sporení: <b>{0} g</b>, v unciach: <b>{1} oz t</b>",
        products_agreements: "Zostávajúci kredit na zmluvy bez poplatku (v násobkoch 10.000 €): <b>{0} €</b> <br/> Zostávajúci kredit na jednorázový nákup bez poplatku: <b>{1} €</b>",
        products_agreements_t1t2: "Zostávajúci kredit na zmluvy bez poplatku (v násobkoch 10.000 €): <b>{0} €",
        products_agreements_t3: "Zostávajúci kredit na jednorázový nákup bez poplatku: <b>{0} €</b>"
    },

    gdpr: {
        title: "Oznam GDPR",
        text: "Vážený partner, v súvislosti so zmenou právnej úpravy týkajúcej sa ochrany osobných údajov, ktorej základ tvorí nový zákon č. 18/2018 o ochrane osobných údajov, účinný odo dňa 25. mája 2018, si Vás dovoľujeme požiadať o osobitné potvrdenie, že ste sa v postavení partnera RGG oboznámili s novým znením VOP v celom rozsahu, rovnako tak so znením súhlasu so spracúvaním Vašich osobných údajov a s týmito vyslovujete bezvýhradný súhlas. Potvrdenie zo strany klienta predstavuje vážny a slobodne daný, konkrétny, informovaný a jednoznačný prejav vôle klienta resp. dotknutej osoby, ktorým klient ako dotknutá osoba vyjadruje súhlas so spracúvaním svojich osobných údajov v súlade s platnou právnou úpravou. Tento text sa Vám po potvrdení súhlasu už nebude zobrazovať.",
        accept: "Potvrdenie udelenia súhlasu"
    }
};

export default messages_sk