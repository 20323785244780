import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getPersonalWebs(filter_data, cancel_request) {
    return ApiService.cancellableGet("/personal-webs" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function updatePersonalWeb(id, data) {
    return ApiService.put("/personal-webs/" + id, data)
}

export function sendPurchaseMail(id) {
    return ApiService.put("/personal-webs/" + id + "/send-purchase-mail")
}

export function sendActivationMail(id) {
    return ApiService.put("/personal-webs/" + id + "/send-activation-mail")
}

export function deactivatePersonalWeb(id) {
    return ApiService.put("/personal-webs/" + id + "/deactivate")
}