<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="480"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                dense outlined color="gold" dark
                v-bind="attrs"
                v-on="on"
            >
                <v-icon left>{{'mdi-' + type}}</v-icon>
                {{ $t('common.' + type) }}
            </v-btn>
        </template>

        <v-alert type="error" prominent class="my-0 font-weight-medium" border="left">
            <v-row align="center">
                <v-col class="grow">
                    {{ $t(message) }}
                </v-col>
                <v-col class="shrink">
                    <v-btn dense depressed class="white red--text mb-4" @click="confirm">
                        {{ $t('common.yes') }}
                    </v-btn>
                    <v-btn dense depressed outlined @click="dialog = false">
                        {{ $t('common.no') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        confirm: Function,
        message: String,
        type: String
    },
    data: () => ({
        dialog: false
    }),
    mounted() {
        this.$root.$on('close-confirm-dialog', () => {
            this.dialog = false;
        })
    },
    destroyed() {
        this.$root.$off('close-confirm-dialog');
    }
}
</script>