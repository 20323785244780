import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getPackages(filter_data, cancel_request) {
    return ApiService.cancellableGet("/packages" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getAgreementsForPackage(id) {
    return ApiService.get("/packages/" + id + "/agreements")
}

export function downloadPackageInvoice(id) {
    return ApiService.download("/packages/" + id + "/invoice", "balicek_fa.pdf");
}

export function purchasePackage(data) {
    return ApiService.post("/packages", data)
}

export function sendPackagePurchaseMail(packageId) {
    return ApiService.put("/packages/" + packageId + "/send-purchase-email")
}

export function sendPackageActivationMail(packageId) {
    return ApiService.put("/packages/" + packageId + "/send-activation-email")
}

export function deactivatePackage(packageId) {
    return ApiService.put("/packages/" + packageId + "/deactivate")
}

export function reactivatePackage(packageId) {
    return ApiService.put("/packages/" + packageId + "/reactivate")
}

export function deletePackage(packageId) {
    return ApiService.delete("/packages/" + packageId)
}