import {getUser, getUsers} from "@/api/users";
import {CancelRequest} from "@/services/api_service";
import {Utils} from "@/services/utils";
import {getCustomer, getCustomers} from "@/api/customers";
import {getSellerInfo} from "@/api/user_profile";

const state = {
    users_cancel_request: new CancelRequest(),
    users: {total_count: 0, items: []},
    selected_user: {},
    seller_info: {
        hasActivePackage: false,
        canPurchasePackage: false,
        canUsePurchaseDiscount: false,
        canSellPackage: false,
        canSellProductT5: false,
        canSellProductT5ForFree: false
    }
};

const actions = {
    getUsers(context, data) {
        context.state.users_cancel_request.cancelAndCreateToken();
        return getUsers(data, context.state.users_cancel_request)
            .then(response => {
                context.state.users_cancel_request.resetCancelToken();
                context.commit('setUsers', response.data);
            })
    },
    getCustomers(context, data) {
        context.state.users_cancel_request.cancelAndCreateToken();
        return getCustomers(data, context.state.users_cancel_request)
            .then(response => {
                context.state.users_cancel_request.resetCancelToken();
                context.commit('setUsers', response.data);
            })
    },
    getUser(context, id) {
        return getUser(id)
            .then(response => {
                context.commit('setSelectedUser', response.data);
            })
    },
    getCustomer(context, id) {
        return getCustomer(id)
            .then(response => {
                context.commit('setSelectedUser', response.data);
            })
    },
    getSellerInfo(context) {
        return getSellerInfo()
            .then(response => {
                context.commit('setSellerInfo', response.data);
            })
    },

    clearUsers(context) {
        context.commit('clearUsers');
    },
    clearSelectedUser(context) {
        context.commit('clearSelectedUser');
    },
    clearSellerInfo(context) {
        context.commit('clearSellerInfo');
    }
};

const mutations = {
    setUsers(state, users_response) {
        state.users.total_count = users_response.totalCount;
        state.users.items = [];
        users_response.items.forEach(function (user) {
            state.users.items.push({
                id: user.id,
                active: user.active,
                fullname: Utils.getFullName(user),
                registration: user.registrationDate,
                email: user.email,
                phone: user.phone,
                role: Utils.localizeText("users.role." + user.role),
                raw_data: {
                    type: user.role
                }
            });
        });
    },
    setSelectedUser(state, response) {
        state.selected_user = {};
        state.selected_user = response;
    },
    setSellerInfo(state, response) {
        state.seller_info = response;
    },

    clearUsers(state) {
        state.users = {total_count: 0, items: []};
    },
    clearSelectedUser(state) {
        state.selected_user = {};
    },
    clearSellerInfo(state) {
        state.seller_info = {
            hasActivePackage: false,
            canPurchasePackage: false,
            canUsePurchaseDiscount: false,
            canSellPackage: false,
            canSellProductT5: false,
            canSellProductT5ForFree: false
        }
    },
};

const getters = {
    getUsers(state) {
        return state.users;
    },

    getSelectedUser(state) {
        return state.selected_user;
    },

    getSellerInfo(state) {
        return state.seller_info;
    },

    hasNoPackage(state) {
        return state.seller_info.hasPurchasedPackage === false
    },

    hasActivePackage(state) {
        return state.seller_info.hasActivePackage
    },

    getCustomers(state) {
        return state.users;
    }
};

export const users = {
    state,
    actions,
    mutations,
    getters
};