import ApiService from "@/services/api_service";

export function login(credentials) {
    return ApiService.post("/auth/login", credentials)
}

export function refreshToken() {
    return ApiService.get("/auth/refresh")
}

export function checkEmail(email) {
    return ApiService.get("/auth/check-email?email=" + email)
}

export function activation(email) {
    return ApiService.put("/auth/activation?email=" + email)
}

export function resetPassword(email) {
    return ApiService.put("/auth/reset-password?email=" + email);
}

export function changePassword(data) {
    return ApiService.put("/auth/change-password", data);
}