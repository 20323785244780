<template>
    <div>
        <v-row class="">
            <!--TOOLBAR-->
            <v-col cols="12" class="pb-0">
                <v-btn class="mr-1" text small @click="editor.chain().focus().toggleBold().run()" :class="{ 'gold white--text': editor.isActive('bold') }">
                    <v-icon>mdi-format-bold</v-icon>
                </v-btn>
                <v-btn class="mr-1" text small @click="editor.chain().focus().toggleItalic().run()" :class="{ 'gold white--text': editor.isActive('italic') }">
                    <v-icon>mdi-format-italic</v-icon>
                </v-btn>
                <v-btn class="mr-1" text small @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'gold white--text': editor.isActive('underline') }">
                    <v-icon>mdi-format-underline</v-icon>
                </v-btn>

                <v-icon small dark color="grey lighten-1" class="px-4">mdi-drag-vertical</v-icon>

                <v-btn text small @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'gold white--text': editor.isActive('bulletList') }">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn text small @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'gold white--text': editor.isActive('orderedList') }">
                    <v-icon>mdi-format-list-numbered</v-icon>
                </v-btn>

                <v-icon small color="grey lighten-1" class="px-4">mdi-drag-vertical</v-icon>

                <v-btn text small @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'gold white--text': editor.isActive({ textAlign: 'left' }) }">
                    <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn text small @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'gold white--text': editor.isActive({ textAlign: 'center' }) }">
                    <v-icon>mdi-format-align-center</v-icon>
                </v-btn>
                <v-btn text small @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'gold white--text': editor.isActive({ textAlign: 'right' }) }">
                    <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn text small @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'gold white--text': editor.isActive({ textAlign: 'justify' }) }">
                    <v-icon>mdi-format-align-justify</v-icon>
                </v-btn>
                <!--<v-btn text small @click="editor.chain().focus().unsetTextAlign().run()">
                    <v-icon>mdi-format-clear</v-icon>
                </v-btn>-->


            </v-col>

            <!--EDITOR-->
            <v-col cols="12">
                <editor-content :editor="editor"
                                class="rgg-rich-editor rounded"
                                :class="{'grey darken-4' : $vuetify.theme.dark}"
                />
            </v-col>
        </v-row>
    </div>

</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
    },

    data:() => ({
        editor: null
    }),

    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    beforeMount() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                Underline,
                TextAlign.configure({
                    types: ['paragraph']
                })
            ],
            onUpdate: () => {
                // HTML
                this.$emit('input', this.editor.getHTML())

                // JSON
                //this.$emit('input', this.editor.getJSON())
            },
        })
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>

<style>
    .rgg-rich-editor .ProseMirror {
        min-height: 256px;
    }
    .rgg-rich-editor ul li p,
    .rgg-rich-editor ol li p {
        margin-bottom: 0;
    }
    .rgg-rich-editor .ProseMirror {
        box-shadow: inset 0 0 10px 1px rgba(0,0,0,0.2);
        border-radius: 8px;
        padding: 8px;
        border: 1px solid grey;
    }
</style>