import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getAllContent(filter_data, cancel_request) {
    return ApiService.cancellableGet("/content" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getContent(category) {
    return ApiService.get("/content/" + category)
}

export function getContentData(category, content_id, key) {
    return ApiService.getData("/content/" + category + "/" + content_id + "/data?jwt=" + key);
}

export function createContent(data) {
    return ApiService.post("/content", data);
}

export function uploadFile(content_id, form_data) {
    return ApiService.upload('/content/' + content_id + '/file', form_data)
}

export function updateContent(content_id, data) {
    return ApiService.put('/content/' + content_id, data)
}

export function deleteContent(content_id) {
    return ApiService.delete('/content/' + content_id)
}

export function downloadContentData(category, content_id, file_name, key) {
    return ApiService.download("/content/" + category + "/" + content_id + "/data?jwt=" + key, file_name);
}