import Vue from 'vue'
import App from './App.vue'
import {store} from "@/store/store";
import vuetify from './plugins/vuetify';
import generic from './plugins/generic';
import VueI18n from 'vue-i18n';
import VeeValidate from 'vee-validate';
import messages from "@/i18n/messages";
import {router} from './router'
import RGGTableDialog from './components/TableDialogs';
import ConfirmDialog from "@/components/ConfirmDialog";
import ButtonUI from "@/components/ui/ButtonUI";
import ApiService from "@/services/api_service";
import VueCountryCode from "vue-country-code"; /* https://www.npmjs.com/package/vue-country-code */

Vue.component('rgg-table-dialog', RGGTableDialog);
Vue.component('rgg-confirm', ConfirmDialog);
Vue.component('rgg-button', ButtonUI);
Vue.use(VueCountryCode);
Vue.use(generic);

Vue.config.productionTip = false;

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'sk',
    fallbackLocale: 'sk',
    messages
});

Vue.use(VeeValidate, {
    i18nRootKey: 'validations',
    i18n
});

ApiService.init(process.env.VUE_APP_RGG_PORTAL_UX_API_URL)

if (localStorage.ACCESS_TOKEN) {
    store.dispatch('setToken', (localStorage.ACCESS_TOKEN)).then()
}

new Vue({
    i18n,
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')