<template>
    <v-container v-if="isAuthenticated()" fluid>
    </v-container>
</template>

<script>

import views from "@/views/views";
import AuthMixin from "@/services/auth/auth_mixin";

export default {
    name: "MasterView",
    mixins: [AuthMixin],
    data: () => ({
        data_view: null
    }),
    mounted() {
        this.$router.push(this.data_view.default_redirect);
    },
    created() {
        this.data_view = views.getDataView(this.$route.name);
    },
};
</script>