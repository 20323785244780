<template>
    <v-app-bar app clipped-left height="58" style="border-bottom: 1px solid #b78c2b;" dark id="main_menu">
        <div class="d-flex" v-if="this.$vuetify.breakpoint.width > 450">
            <div class="d-flex pt-2">
                <img v-if="this.$vuetify.breakpoint.width > 1200" class="logo" src="@/assets/rgg_logo.png" alt="rgg_logo"/>
                <img v-else class="logo" src="@/assets/rgg_logo_erb.png" alt="rgg_logo"/>
            </div>
            <v-divider
                color="white"
                class="mx-4 mt-0"
                inset
                vertical
            ></v-divider>
            <Buttons :buttons="main_menu_buttons" class="gold--text"/>
        </div>
        <div v-else class="d-flex pa-0 ma-0">

            <div>
                <img class="logo text mt-1" src="@/assets/rgg_logo_erb.png" alt="rgg_logo"/>
            </div>
            <Buttons :buttons="main_menu_buttons" class="gold--text"/>
        </div>

        <v-spacer></v-spacer>
        <UserMenu/>
    </v-app-bar>
</template>

<script>
import Buttons from "@/components/Buttons";
import UserMenu from "@/components/UserMenu";
import Permissions from "@/services/auth/permissions";

export default {
    name: "MainMenu",
    components: {
        Buttons,
        UserMenu
    },
    data: () => ({
        main_menu_buttons: [
            {
                label: 'home', icon: 'mdi-home', route: '/home',
                permissions: [Permissions.SELLER, Permissions.CUSTOMER]
            },
            {
                label: 'content_management', icon: 'mdi-application-cog-outline', route: '/content-management',
                permissions: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]
            },
            {
                label: 'users', icon: 'mdi-account-group', route: '/users',
                permissions: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]
            },
            {
                label: 'sellers', icon: 'mdi-account-group', route: '/sellers',
                permissions: [Permissions.SELLER]
            },
            {
                label: 'products', icon: 'mdi-view-grid-plus-outline', route: '/products',
                permissions: [Permissions.SELLER, Permissions.SUPPORTER, Permissions.ADMINISTRATOR]
            },
            {
                label: 'my_agreements', icon: 'mdi-file-multiple-outline', route: '/my-agreements',
                permissions: [Permissions.CUSTOMER]
            },
            {
                label: 'gold_price_list', icon: 'mdi-chart-box', route: '/gold-price-lists',
                permissions: [Permissions.CUSTOMER]
            },
            {
                label: 'provisions', icon: 'mdi-cash-100', route: '/provisions',
                permissions: [Permissions.SELLER]
            },
            {
                label: 'gold_deposit', icon: 'mdi-gold', route: '/gold-deposit',
                permissions: [Permissions.ADMINISTRATOR, Permissions.SUPPORTER]
            },
            {
                label: 'price_lists', icon: 'mdi-chart-box', route: '/price-lists',
                permissions: [Permissions.ADMINISTRATOR]
            },
            {
                label: 'transactions', icon: 'mdi-bank-transfer', route: '/transactions',
                permissions: [Permissions.ADMINISTRATOR]
            },
            {
                label: 'purchase_reports', icon: 'mdi-chart-bar', route: '/purchase-reports',
                permissions: [Permissions.ADMINISTRATOR]
            },
            {
                label: 'salary_reports', icon: 'mdi-currency-eur', route: '/salary-reports',
                permissions: [Permissions.ADMINISTRATOR]
            },
            {
                label: 'career', icon: 'mdi-finance', route: '/career',
                permissions: [Permissions.SELLER]
            },
            {
                label: 'documents', icon: 'mdi-file-document-multiple', route: '/documents',
                permissions: [Permissions.SELLER]
            },
            {
                label: 'e_shop', icon: 'mdi-cart', route: '/eshop',
                permissions: [Permissions.SELLER]
            },
            {
                label: 'emails', icon: 'mdi-email-fast-outline', route: '/emails',
                permissions: [Permissions.SUPPORTER, Permissions.ADMINISTRATOR]
            },
            {
                label: 'contacts', icon: 'mdi-card-account-details-outline', route: '/contacts',
                permissions: [Permissions.CUSTOMER, Permissions.SELLER]
            }
        ]
    }),

    computed: {
        refreshPath() {
            return -1;
        }
    },

    methods: {}
}
</script>

<style>
    .v-toolbar__content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    img.logo {
        height: 40px;
    }
    img.logo.text {
        height: 46px;
    }
</style>