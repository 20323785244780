import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getGoldDeposit(filter_data, cancel_request) {
    return ApiService.cancellableGet("/gold-deposit" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function createGoldDeposit(data) {
    return ApiService.post("/gold-deposit", data);
}

export function updateGoldDeposit(id, data) {
    return ApiService.put('/gold-deposit/' + id, data)
}

export function deleteGoldDeposit(id) {
    return ApiService.delete('/gold-deposit/' + id)
}

export function allocateCoins() {
    return ApiService.put("/gold-deposit/allocate-coins");
}