const country_codes = [
    {"name": "Austrália", "dial_code": "", "code": ""},
    {"name": "Belgicko", "dial_code": "", "code": ""},
    {"name": "Bulharsko", "dial_code": "", "code": ""},
    {"name": "Cyprus", "dial_code": "", "code": ""},
    {"name": "Česká Republika", "dial_code": "", "code": ""},
    {"name": "Dánsko", "dial_code": "", "code": ""},
    {"name": "Estónsko", "dial_code": "", "code": ""},
    {"name": "Fínsko", "dial_code": "", "code": ""},
    {"name": "Francúzsko", "dial_code": "", "code": ""},
    {"name": "Grécko", "dial_code": "", "code": ""},
    {"name": "Holandsko", "dial_code": "", "code": ""},
    {"name": "Chorvátsko", "dial_code": "", "code": ""},
    {"name": "Írsko", "dial_code": "", "code": ""},
    {"name": "Kanada", "dial_code": "", "code": ""},
    {"name": "Litva", "dial_code": "", "code": ""},
    {"name": "Lotyšsko", "dial_code": "", "code": ""},
    {"name": "Luxembursko", "dial_code": "", "code": ""},
    {"name": "Maďarsko", "dial_code": "", "code": ""},
    {"name": "Malta", "dial_code": "", "code": ""},
    {"name": "Nemecko", "dial_code": "", "code": ""},
    {"name": "Nórsko", "dial_code": "", "code": ""},
    {"name": "Poľsko", "dial_code": "", "code": ""},
    {"name": "Portugalsko", "dial_code": "", "code": ""},
    {"name": "Rakúsko", "dial_code": "", "code": ""},
    {"name": "Rumunsko", "dial_code": "", "code": ""},
    {"name": "Slovensko", "dial_code": "", "code": ""},
    {"name": "Slovinsko", "dial_code": "", "code": ""},
    {"name": "Spojené Štáty Americké", "dial_code": "", "code": ""},
    {"name": "Srbsko", "dial_code": "", "code": ""},
    {"name": "Španielsko", "dial_code": "", "code": ""},
    {"name": "Švajčiarsko", "dial_code": "", "code": ""},
    {"name": "Švédsko", "dial_code": "", "code": ""},
    {"name": "Taliansko", "dial_code": "", "code": ""},
    {"name": "Uganda", "dial_code": "", "code": ""},
    {"name": "Veľká Británia", "dial_code": "", "code": ""},
];

export default {
    country_codes
}