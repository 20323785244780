<template>
    <v-overlay :value="overlay" z-index="50000">

        <v-row align="center">
            <v-col cols="12" align="center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-col>
            <v-col cols="12" align="center" v-if="message !== null">
                <span class="gold white--text rounded px-4 py-3 elevation-6">
                    <v-icon left>mdi-database-cog-outline</v-icon>{{ getMessage }}
                </span>
            </v-col>
        </v-row>
    </v-overlay>
</template>

<script>
export default {
    name: "Overlay",
    data: () => ({
        overlay: false,
        message: null
    }),
    computed: {
        getMessage() {
            return this.$t('notification.' + this.message);
        }
    },
    watch: {
        overlay(_val) {
            this.$root.$emit('isOverlay', _val);
            if(this.overlay === false) {
                this.message = null;
            }
        }
    },

    mounted() {
        this.$root.$on('overlay', (_overlay, _msg) => {
            this.overlay = _overlay;
            this.message = typeof _msg === 'string' ? _msg : null;
        });
    }
}
</script>